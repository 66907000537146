import React, { createRef, useEffect } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";

function SidebarWrapper({ className, headerLinks, links, isMiniActive }) {
  const sidebarWrapper = createRef();
  let ps;

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, []);

  return (
    <div
      className={className}
      ref={sidebarWrapper}
      style={{
        height: isMiniActive ? "calc(100vh - 90px)" : "calc(100vh - 180px)",
      }}
    >
      {headerLinks}
      {links}
    </div>
  );
}

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  isMiniActive: PropTypes.bool,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
};

export default SidebarWrapper;
