import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { validation } from "utils/validate";
import updateProfileRules from "./updateProfileRules";
import { updateAdmin } from "store/actions/user";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import stylesDash from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import { ADMIN_TYPES } from "utils/constants";

const useStyles = makeStyles(stylesDash);

export default function EditAdmin() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { userProfile } = useSelector((state) => state.user);
  const [name, setName] = useState(userProfile.name);
  const [email, setEmail] = useState(userProfile.email);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [errors, setErrors] = useState({});

  function isValid() {
    const result = validation(
      {
        email,
        name,
        newPassword,
        oldPassword,
      },
      {
        email: updateProfileRules.email,
        name: updateProfileRules.name,
        newPassword: newPassword ? updateProfileRules.password : undefined,
        oldPassword: oldPassword ? updateProfileRules.password : undefined,
      }
    );

    setErrors(result ? result : {});
    return result == null;
  }

  async function handleUpdateProfile() {
    if (isValid()) {
      dispatch(
        updateAdmin({
          name,
          email,
          password: newPassword,
          oldPassword,
          adminType: userProfile.adminType,
        })
      );

      setErrors({});
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Editar Perfil</h4>
              <p className={classes.cardCategoryWhite}>
                Edite as informações do seu perfil
              </p>
            </CardHeader>

            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={errors.name}
                    errorText={errors.name}
                    labelText="Nome"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: (e) => setName(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={errors.email}
                    errorText={errors.email}
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "email",
                      value: email,
                      onChange: (e) => setEmail(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} md={4} sm={6}>
                  <CustomInput
                    labelText="Tipo de administrador"
                    id="adminType"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      type: "text",
                      value: ADMIN_TYPES[userProfile.adminType],
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    error={errors.newPassword}
                    errorText={errors.newPassword}
                    labelText="Nova Senha"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "password",
                      value: newPassword,
                      onChange: (e) => setNewPassword(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    error={errors.oldPassword}
                    errorText={errors.oldPassword}
                    labelText="Senha atual"
                    id="old-password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "password",
                      value: oldPassword,
                      onChange: (e) => setOldPassword(e.target.value),
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>

            <CardFooter>
              <Button
                withProgress
                onClick={handleUpdateProfile}
                color="primary"
              >
                Salvar Alterações
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
