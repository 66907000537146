import React, { useState, useEffect } from "react";
import api from "services/api";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import AdminRestriction from "components/AccessWarning/AdminRestriction.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles({
  ...styles,
  actionButton: {
    padding: "5px",
    marginLeft: "40px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px",
    },
  },
  messageBox: {
    backgroundColor: "#eee",
    borderRadius: "12px",
    padding: "12px",
    marginTop: "16px",
  },
  legendText: {
    marginLeft: "16px",
  },
});

import { USER_PLANS } from "utils/constants";

export default function UsersList() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");
  const [isMaster] = useState(localStorage.getItem("@adminType") == 1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [, /*totalPages*/ setTotalPages] = useState(0);

  async function blockUser(currentUser) {
    try {
      setLoading(true);

      const response = await api.put(`/users/${currentUser.id}`, {
        bloqueado: !currentUser.bloqueado,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const actionsButtons = (currentUser) => (
    <Button
      onClick={() => {
        blockUser(currentUser);
      }}
      color={currentUser.bloqueado ? "success" : "danger"}
      className={classes.actionButton}
    >
      {currentUser.bloqueado ? "Desbloquear" : "Bloquear"}
    </Button>
  );

  useEffect(() => getDatas(page, rowsPerPage), []);

  async function getDatas(page, rowsPerPage) {
    try {
      setError(null);
      setLoading(true);

      const usersFromServer = (
        await api.get(`/users?page=${page + 1}&size=${rowsPerPage}`)
      ).data;
      setUsers(
        usersFromServer.data.map((row) => [
          row.nome,
          row.email,
          row.genero,
          USER_PLANS[row.plano],
          actionsButtons(row),
        ])
      );
      setTotalItems(usersFromServer.totalItems);
      setTotalPages(usersFromServer.totalPages);
      if (usersFromServer.length == 0) {
        setError("Nenhum email encontrado");
      }
    } catch (error) {
      setError("Ocorreu um erro enquanto os dados eram carregados...");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function onChangePage(event, page) {
    setPage(page);
    getDatas(page, rowsPerPage);
  }

  function onChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    getDatas(page, event.target.value);
  }

  return isMaster ? (
    <GridItem>
      <p
        style={{
          color: "red",
          fontWeight: "bolder",
          margin: 0,
          padding: 0,
        }}
      >
        {error}
      </p>
      <Table
        loading={loading}
        tablePagination
        tablePaginationInfo={{
          rowsPerPage,
          page,
          total: totalItems,
          onChangePage,
          onChangeRowsPerPage,
        }}
        tableHeaderColor="primary"
        tableHead={["Nome", "Email", "Gênero", "Plano", "Bloquear/Desbloquear"]}
        tableData={users}
      />
      <GridItem>
        <h5>Legenda:</h5>
        <p className={classes.legendText}>
          Gênero: not defined = escolheu não informar | vazio = conta
          empresarial
        </p>
      </GridItem>
    </GridItem>
  ) : (
    <AdminRestriction />
  );
}
