import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadUser } from "store/actions/user";
import api from "services/api";
import { maskMoney } from "utils/masks";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";
import stylesDash from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { dangerColor } from "assets/jss/material-dashboard-react";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
const useModalStyles = makeStyles(modalStyle);
const styles = {
  ...stylesSelect,
  ...stylesDash,
  span: {
    fontWeight: "bold",
  },
};
const useStyles = makeStyles(styles);

export default function MakeSignature() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const modaClasses = useModalStyles();
  const { userProfile } = useSelector((state) => state.user);

  const [modal, setModal] = useState(false);
  const [plan, setPlan] = useState("");
  const [selectedPlan, setSelectedPlan] = useState({});
  const [planValue, setPlanValue] = useState("");
  const [plans, setPlans] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function loadPlans() {
      try {
        setLoading(true);

        const plansFromServer = (await api.get("/plans?page=1&size=600")).data;

        setPlans(plansFromServer.data);
      } catch (error) {
        setErrorMessage(
          "Ocorreu um erro enquanto os planos eram carregados..."
        );
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadPlans();
  }, []);

  // eslint-disable-next-line no-unused-vars
  async function handleMakeSignature() {
    try {
      setLoading(true);
      setModal(false);
      await api.post(`/payment/plans`, {
        id_usuario: userProfile.id,
        id_plano: plan,
      });

      history.push("/admin/my-signature/");
      dispatch(loadUser());
    } catch (error) {
      setErrorMessage(
        error.response.data.error ||
          "Ocorreu um erro. Esse erro pode ter sido causado pelo cadastro de um CPF/CNPJ inválido." +
            " Confira todos os seus dados e tente novamente."
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Fazer Assinatura</h4>
            <p className={classes.cardCategoryWhite}>
              Escolha seu plano e faça a assinatura premium
            </p>
          </CardHeader>
          {errorMessage && (
            <p
              style={{
                color: dangerColor[0],
                fontWeight: "bold",
                marginLeft: 16,
              }}
            >
              {errorMessage}
            </p>
          )}

          <CardBody>
            <GridContainer>
              <GridItem container alignItems="center" xs={12} md={6} sm={12}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="admin-type"
                    className={classes.selectLabel}
                  >
                    Escolha o plano
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={plan}
                    onChange={(e) => {
                      setPlan(e.target.value);

                      const selPlan = plans.find(
                        (p) => p.id === e.target.value
                      );
                      setSelectedPlan(selPlan);

                      const stringValue = `${selPlan.valor}`;
                      const characters = stringValue.split("");
                      const indexOfDot = characters.findIndex(
                        (elem) => elem === "."
                      );
                      const zeros =
                        2 - (characters.slice(indexOfDot).length - 1) > 0
                          ? 2 - (characters.slice(indexOfDot).length - 1) > 1
                            ? "00"
                            : "0"
                          : "";
                      const planValue = maskMoney(
                        `${stringValue.replace(".", "")}${zeros}`
                      );
                      setPlanValue(planValue);
                    }}
                    inputProps={{
                      name: "adminType",
                      id: "admin-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Selecione o plano
                    </MenuItem>
                    {plans.map((plan) => (
                      <MenuItem
                        key={plan.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={plan.id}
                      >
                        {plan.titulo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem xs={12}>
                {plan && (
                  <>
                    <p>
                      <span className={classes.span}>Descrição: </span>
                      {selectedPlan.descricao}
                    </p>

                    <p>
                      <span className={classes.span}>Valor: </span> R${" "}
                      {planValue}
                    </p>

                    <p>
                      <span className={classes.span}>Máximo de parcelas: </span>
                      {selectedPlan.quantidade}
                    </p>
                  </>
                )}
              </GridItem>
            </GridContainer>
          </CardBody>

          <CardFooter>
            <Button
              withProgress
              loading={loading}
              disabled={loading || !plan}
              onClick={() => setModal(true)}
              color="primary"
            >
              Assinar
            </Button>
          </CardFooter>
        </Card>
      </GridItem>

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>
            <strong>Confirmar assinatura</strong>
          </h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          <h5>
            Deseja assinar esse plano? Ao confirmar você concorda com nossos
            <a
              href="/blog/terms"
              target="_blank"
              rel="noreferrer"
              className={classes.externalLink}
            >
              Termos de uso
            </a>{" "}
            referentes a prazos e pagamentos.
          </h5>
        </DialogContent>

        <DialogActions
          className={
            modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
          }
        >
          <Button
            withProgress
            loading={loading}
            disabled={loading}
            onClick={handleMakeSignature}
            color="success"
          >
            Confirmar assinatura
          </Button>

          <Button disabled={loading} onClick={() => setModal(false)}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}
