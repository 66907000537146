import React, { useState } from "react";
import PropTypes from "prop-types";
import api from "services/api";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import Button from "components/CustomButtons/Button.js";
// style components
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
const useModalStyles = makeStyles(modalStyle);
import { primaryColor } from "assets/jss/material-dashboard-react";
const useStyles = makeStyles({
  externalLink: {
    color: primaryColor[0],
    cursor: "pointer",
    "&:hover": {
      fontWeight: "bolder",
    },
  },
});
import { contactEmail } from "assets/jss/Texts/siteLinks.js";

export default function CancelButton({ id }) {
  const classes = useStyles();
  const modaClasses = useModalStyles();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleCancel() {
    try {
      setLoading(true);
      const cancel = await api.delete(`/payment/plans/${id}/cancelation`);
      console.log("Status: " + cancel.status);
      if (cancel.status > 199 && cancel.status < 300) {
        window.location.reload(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setModal(false);
    }
  }

  return (
    <React.Fragment>
      <Button color="primary" onClick={() => setModal(true)}>
        Cancelar meu plano
      </Button>

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>
            <strong>Certeza que deseja cancelar seu plano?</strong>
          </h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          <h5>
            O processo de cancelamento é ireverssível e implica na perda de suas
            vantagens pagas até que um novo plano seja adquirido.
          </h5>
          <h5>
            Este processo só terá validade se for solicitado dentro do prazo
            previsto. Ao solicitá-lo você declara que está ciente dos termos de
            cancelamento presentes nos nossos{" "}
            <a
              href="/blog/terms"
              target="_blank"
              rel="noreferrer"
              className={classes.externalLink}
            >
              Termos de uso
            </a>
            {"."}
          </h5>
          <h5>
            Se algum problema persistir ou se seu caso não se enquadra no prazo
            de cancelamento, entre em contato pelo site ou e-mail:{" "}
            <strong>{contactEmail}</strong>
          </h5>
        </DialogContent>

        <DialogActions
          className={
            modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
          }
        >
          <Button
            withProgress
            loading={loading}
            disabled={loading}
            onClick={() => handleCancel()}
            color="danger"
          >
            Solicitar cancelamento
          </Button>

          <Button disabled={loading} onClick={() => setModal(false)}>
            Voltar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

CancelButton.propTypes = {
  id: PropTypes.number,
};
