import React, { useState, useEffect } from "react";
import api from "services/api";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import CommentIcon from "@material-ui/icons/Comment";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import AdminRestriction from "components/AccessWarning/AdminRestriction.js";
import { blockAccess } from "utils/validate.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import GridItem from "components/Grid/GridItem";
const useModalStyles = makeStyles(modalStyle);
const useStyles = makeStyles({
  ...styles,
  // ...modalStyle,
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px",
    },
  },
  messageBox: {
    backgroundColor: "#eee",
    borderRadius: "12px",
    padding: "12px",
    marginTop: "16px",
  },
});

import { CONTACT_CLIENTS } from "utils/constants";

export default function ListAdministrators() {
  const classes = useStyles();
  const modaClasses = useModalStyles();
  const [modal, setModal] = useState(false);
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [error, setError] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [, /*totalPages*/ setTotalPages] = useState(0);

  const [activeUser, setActiveUser] = useState("");
  const [openPanel, setOpenPanel] = useState(false);

  const actionsButtons = (current) => (
    <React.Fragment>
      <Button
        onClick={() => {
          setActiveUser(current);
          setOpenPanel(!openPanel);
        }}
        color="success"
        className={classes.actionButton}
      >
        <CommentIcon className={classes.icon} />
      </Button>
      <Button
        onClick={() => {
          setActiveUser(current);
          setModal(true);
        }}
        color="danger"
        className={classes.actionButton}
      >
        <DeleteIcon className={classes.icon} />
      </Button>
    </React.Fragment>
  );

  async function getDatas(page, rowsPerPage) {
    try {
      setError(null);
      setLoading(true);

      const dataFromServer = (
        await api.get(`/contacts?page=${page + 1}&size=${rowsPerPage}`)
      ).data;
      setDatas(
        dataFromServer.data.map((row) => [
          row.nome,
          row.email,
          CONTACT_CLIENTS[row.cliente],
          row.horario,
          row.telefone ? row.telefone : "não informado",
          actionsButtons(row),
        ])
      );
      setTotalItems(dataFromServer.totalItems);
      setTotalPages(dataFromServer.totalPages);

      if (dataFromServer.length == 0) {
        setError("Nenhuma entrada em contato");
      }
    } catch (error) {
      setError("Ocorreu um erro enquanto os contatos eram carregados...");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => getDatas(page, rowsPerPage), []);

  async function handleDeleteContact() {
    try {
      setError(null);
      setLoadingDelete(true);

      console.log("Delete: " + activeUser.id);
      await api.delete(`/contacts/${activeUser.id}`);
      await getDatas();
    } catch (error) {
      console.log(error);
      console.log(error.response);
      setError("Ocorreu um erro enquanto tentavamos excluir essa entrada...");
    } finally {
      setLoadingDelete(false);
      setModal(false);
      setOpenPanel(false);
    }
  }

  function onChangePage(event, page) {
    setPage(page);
    getDatas(page, rowsPerPage);
  }

  function onChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    getDatas(page, event.target.value);
  }

  return (
    <React.Fragment>
      {blockAccess(3) ? (
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Contatos Abertos</h4>
            <p className={classes.cardCategoryWhite}>
              Lista com os formularios de contato submetidos
            </p>
          </CardHeader>

          <CardBody>
            <GridItem>
              <p
                style={{
                  color: "red",
                  fontWeight: "bolder",
                  margin: 0,
                  padding: 0,
                }}
              >
                {error}
              </p>
              <Table
                loading={loading}
                tablePagination
                tablePaginationInfo={{
                  rowsPerPage,
                  page,
                  total: totalItems,
                  onChangePage,
                  onChangeRowsPerPage,
                }}
                tableHeaderColor="primary"
                tableHead={[
                  "Nome",
                  "Email",
                  "É cliente?",
                  "Horario para ser respondido",
                  "Celular",
                  "Apagar",
                ]}
                tableData={datas}
              />
            </GridItem>

            {openPanel ? (
              <div className={classes.messageBox}>
                <h3 className={classes.messageLabel}>
                  Mensagem de {activeUser.nome}:
                </h3>
                <p className={classes.messageBox}>{activeUser.conteudo}</p>
              </div>
            ) : null}
          </CardBody>
        </Card>
      ) : (
        <AdminRestriction />
      )}

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>Excluir entrada</h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          <h5>
            Deseja excluir a entrada de {activeUser.nome}? Se ela ainda não
            tiver sido respondida essas informações serão perdidas
          </h5>
        </DialogContent>

        <DialogActions
          className={
            modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
          }
        >
          <Button disabled={loadingDelete} onClick={() => setModal(false)}>
            Não
          </Button>
          <Button
            withProgress
            disabled={loadingDelete}
            loading={loadingDelete}
            onClick={() => handleDeleteContact()}
            color="danger"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
