// Aba: 'Home'
import imagemPrincipal from "assets/img/homepage/homeBanner.jpg";
import imagemDocumento from "assets/img/homepage/homeImagem1.png";
import imagemEsocial from "assets/img/icons/e-social_icon.png";

// Aba: 'Soluções'
import imagemBanner from "assets/img/homepage/solucoesBanner.png";
import imagemDescricao1 from "assets/img/homepage/solucoesDescricao1.png";
import imagemDescricao2 from "assets/img/homepage/solucoesDescricao2.png";
import wideDescricao from "assets/img/homepage/solucoesDescricaoLarga.png";

// Não mexer
export const imageHome1 = imagemPrincipal;
export const imageHome2 = imagemDocumento;
export const imageHome3 = imagemEsocial;
export const imageSolucoes1 = imagemBanner;
export const imageSolucoes2 = imagemDescricao1;
export const imageSolucoes3 = imagemDescricao2;
export const imageSolucoes4 = wideDescricao;
