import { container } from "assets/jss/material-dashboard-react.js";

const authStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    padding: "0px 15px",
  },
  content: {
    padding: "5px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 15px",
    },
  },
  container,
});

export default authStyle;
