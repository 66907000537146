/* eslint-disable no-constant-condition */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "store/actions/user";
import cep from "cep-promise";
import { validation } from "utils/validate";
import updateProfileRules from "./updateProfileRules";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { maskCPF, maskCEP, maskCNPJ } from "utils/masks";

import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";
import stylesDash from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const styles = {
  ...stylesSelect,
  ...stylesDash,
  buttonContainer: {
    margin: "16px 0px",
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { address, ...user } = useSelector((state) => state.user.userProfile);
  const classes = useStyles();
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [gender, setGender] = useState(user.gender);
  const [birthDate, setBirthDate] = useState(
    user.birthDate.format("YYYY-MM-DD")
  );
  const [personIdentifier, setPersonIdentifier] = useState(
    user.personIdentifier
  );
  const [zipCode, setZipCode] = useState(address.zipCode);
  const [state, setState] = useState(address.state);
  const [city, setCity] = useState(address.city);
  const [neighborhood, setNeighborhood] = useState(address.neighborhood);
  const [street, setStreet] = useState(address.street);
  const [number, setNumber] = useState(address.number);
  const [complement, setComplement] = useState(address.complement);
  const [erros, setErros] = useState({});

  async function changeZipCode(newZipCode) {
    setZipCode(maskCEP(newZipCode));

    newZipCode = newZipCode.replace(/\D/g, "");
    if (newZipCode.length >= 5) {
      try {
        const zipCodeInfos = await cep(newZipCode);

        setState((current) => zipCodeInfos.state || current);
        setCity((current) => zipCodeInfos.city || current);
        setStreet((current) => zipCodeInfos.street || current);
        setNeighborhood((current) => zipCodeInfos.neighborhood || current);
      } catch (error) {
        console.log(error);
      }
    }
  }

  function isValid() {
    const result = validation(
      {
        email,
        name,
        personIdentifier,
        password: newPassword,
        oldPassword,
        zipCode,
        state,
        city,
        street,
        neighborhood,
        number,
      },
      {
        email: updateProfileRules.email,
        name: updateProfileRules.name,
        personIdentifier: updateProfileRules.personIdentifier,
        password: newPassword ? updateProfileRules.password : undefined,
        oldPassword: oldPassword ? updateProfileRules.password : undefined,
        zipCode: updateProfileRules.zipCode,
        state: updateProfileRules.state,
        city: updateProfileRules.city,
        street: updateProfileRules.street,
        neighborhood: updateProfileRules.neighborhood,
        number: updateProfileRules.number,
      }
    );

    setErros(result ? result : {});
    return result == null;
  }

  async function handleUpdateProfile() {
    if (isValid()) {
      dispatch(
        updateUser({
          name,
          email,
          password: newPassword,
          oldPassword,
          gender,
          birthDate,
          personIdentifier,
          address: {
            zipCode,
            state,
            city,
            neighborhood,
            street,
            number,
            complement,
          },
        })
      );

      setErros({});
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Editar Perfil</h4>
              <p className={classes.cardCategoryWhite}>
                Edite as informações do seu perfil
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={erros.name}
                    errorText={erros.name}
                    labelText="Nome"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: (e) => setName(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={erros.email}
                    errorText={erros.email}
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "email",
                      value: email,
                      onChange: (e) => setEmail(e.target.value),
                    }}
                  />
                </GridItem>

                {user.personType === "CPF" && (
                  <React.Fragment>
                    <GridItem
                      container
                      alignItems="center"
                      xs={12}
                      sm={6}
                      md={4}
                    >
                      <CustomInput
                        labelText="Data de nascimento"
                        id="birth-date"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "date",
                          value: birthDate,
                          onChange: (e) => setBirthDate(e.target.value),
                        }}
                      />
                    </GridItem>

                    <GridItem
                      container
                      alignItems="center"
                      xs={12}
                      md={4}
                      sm={6}
                    >
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="gender"
                          className={classes.selectLabel}
                        >
                          Gênero
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          inputProps={{
                            name: "gender",
                            id: "gender",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            Selecione o gênero
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="male"
                          >
                            Masculino
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="female"
                          >
                            Feminino
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="not defined"
                          >
                            Prefiro não informar
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </React.Fragment>
                )}

                <GridItem xs={12} md={4} sm={6}>
                  <CustomInput
                    error={erros.personIdentifier}
                    errorText={erros.personIdentifier}
                    labelText={user.personType === "CPF" ? "CPF" : "CNPJ"}
                    id="address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      maxLength: user.personType === "CPF" ? 14 : 18,
                      value: personIdentifier,
                      onChange: (e) => {
                        if (user.personType === "CPF") {
                          setPersonIdentifier(maskCPF(e.target.value));
                        } else {
                          setPersonIdentifier(maskCNPJ(e.target.value));
                        }
                      },
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={erros.zipCode}
                    errorText={erros.zipCode}
                    labelText="CEP"
                    id="zipCode"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      maxLength: 10,
                      type: "text",
                      value: zipCode,
                      onChange: async (e) =>
                        await changeZipCode(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={erros.state}
                    errorText={erros.state}
                    labelText="Estado"
                    id="state"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: state,
                      onChange: (e) => setState(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={erros.city}
                    errorText={erros.city}
                    labelText="Cidade"
                    id="city"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: city,
                      onChange: (e) => setCity(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={erros.neighborhood}
                    errorText={erros.neighborhood}
                    labelText="Bairro"
                    id="neighborhood"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: neighborhood,
                      onChange: (e) => setNeighborhood(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={8} md={8}>
                  <CustomInput
                    error={erros.street}
                    errorText={erros.street}
                    labelText="Rua"
                    id="street"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: street,
                      onChange: (e) => setStreet(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={4} md={4}>
                  <CustomInput
                    error={erros.number}
                    errorText={erros.number}
                    labelText="Número"
                    id="neighborhood"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      value: number,
                      onChange: (e) => setNumber(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Complemento"
                    id="complement"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: complement,
                      onChange: (e) => setComplement(e.target.value),
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={erros.password}
                    errorText={erros.password}
                    labelText="Nova senha"
                    id="new-password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "password",
                      value: newPassword,
                      onChange: (e) => setNewPassword(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={erros.oldPassword}
                    errorText={erros.oldPassword}
                    labelText="Senha atual"
                    id="current-password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "password",
                      value: oldPassword,
                      onChange: (e) => setOldPassword(e.target.value),
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <GridItem>
                <Button onClick={handleUpdateProfile} color="primary">
                  Atualizar perfil
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  color="primary"
                  onClick={() => history.push(`/admin/perfil/subscription`)}
                >
                  Mudar meu plano
                </Button>
              </GridItem>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
