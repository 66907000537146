import React from "react";
import "assets/css/specificPages/EmBreve.css";
import backgroundImage from "assets/img/homepage/emBreve/AZ-safe_site_em-breve_bg.png";
import NewsletterFormDialog from "components/Newsletter/NewsletterFormDialog";

function LandingPage() {
  return (
    <div
      className="landing-page embreve"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="content">
        <p className="em-breve-icon">EM BREVE</p>
        <h1 className="main-title">
          A <b>revolução</b> da área de SST te espera!
        </h1>
        <p className="description"> Quer receber o acesso em primeira mão? </p>
        <p className="description"> Entre para lista de espera. </p>
        <NewsletterFormDialog color="primary" />
      </div>
    </div>
  );
}

export default LandingPage;
