import React, { useState, useEffect } from "react";
import api from "services/api";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
// core components
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
const useModalStyles = makeStyles(modalStyle);
const useStyles = makeStyles({
  ...styles,
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px",
    },
  },
  messageBox: {
    backgroundColor: "#eee",
    borderRadius: "12px",
    padding: "12px",
    marginTop: "16px",
  },
});

export default function TutorialList() {
  const classes = useStyles();
  const modaClasses = useModalStyles();
  const [modal, setModal] = useState(false);
  const [datas, setDatas] = useState([]);
  const [tutorialToRemove, setTutorialToRemove] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const actionsButtons = (currentTutorial) => (
    <React.Fragment>
      <Button
        onClick={() => {
          setTutorialToRemove(currentTutorial);
          setModal(true);
        }}
        color="danger"
        className={classes.actionButton}
      >
        <DeleteIcon className={classes.icon} />
      </Button>
    </React.Fragment>
  );

  async function getDatas() {
    try {
      setError(null);
      setLoading(true);

      const dataFromServer = (await api.get("/tutorials")).data;
      console.log(dataFromServer);
      setDatas(
        dataFromServer.map((row) => [
          row.titulo,
          row.midia,
          actionsButtons(row),
        ])
      );
      if (dataFromServer.length == 0) {
        setError("Nenhum tutorial encontrado");
      }
    } catch (error) {
      setError("Ocorreu um erro enquanto os tutoriais eram carregados...");
      console.log(error);
    } finally {
      setTutorialToRemove("");
      setLoading(false);
    }
  }

  useEffect(() => getDatas(page, rowsPerPage), []);

  function onChangePage(event, page) {
    setPage(page);
    getDatas(page, rowsPerPage);
  }

  function onChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    getDatas(page, event.target.value);
  }

  async function handleDeleteTutorial() {
    try {
      setError(null);
      setLoadingDelete(true);

      console.log("Delete: " + tutorialToRemove.id);
      await api.delete(`/tutorials/${tutorialToRemove.id}`);
      await getDatas();
    } catch (error) {
      console.log(error);
      console.log(error.response);
      setError("Ocorreu um erro enquanto tentavamos excluir essa entrada...");
    } finally {
      setLoadingDelete(false);
      setModal(false);
    }
  }

  return (
    <React.Fragment>
      <GridItem>
        <p
          style={{
            color: "red",
            fontWeight: "bolder",
            margin: 0,
            padding: 0,
          }}
        >
          {error}
        </p>
        <Table
          loading={loading}
          tablePagination
          tablePaginationInfo={{
            rowsPerPage,
            page,
            total: datas.length, // this will change when the api has a pagination
            onChangePage,
            onChangeRowsPerPage,
          }}
          tableHeaderColor="primary"
          tableHead={["Titulo", "Video", "Apagar"]}
          tableData={datas}
        />
      </GridItem>

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>Excluir tutorial</h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          <h5>
            Deseja excluir o tutorial &quot;{tutorialToRemove.titulo}&quot;?
            Usuários não terão mais acesso a ele
          </h5>
        </DialogContent>

        <DialogActions
          className={
            modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
          }
        >
          <Button disabled={loadingDelete} onClick={() => setModal(false)}>
            Não
          </Button>
          <Button
            withProgress
            disabled={loadingDelete}
            loading={loadingDelete}
            onClick={() => handleDeleteTutorial()}
            color="danger"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
