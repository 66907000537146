import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isLoged } from "store/actions/auth";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import NotSignedRoute from "./NotSignedRoute";

import Admin from "layouts/Admin.js";
import AuthLayout from "layouts/Auth";
import Blog from "layouts/Blog";

function Router() {
  const dispatch = useDispatch();

  useEffect(() => dispatch(isLoged()), []);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <PrivateRoute path="/admin" component={Admin} />
          <NotSignedRoute path="/auth" component={AuthLayout} />
          <Route path="/blog" component={Blog} />
          <Redirect from="/" to="/auth" />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default Router;
