/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import api from "services/api";
import { useHistory } from "react-router-dom";
// @material-ui/core
import { makeStyles } from "@material-ui/core";
// @material-ui/icons
import ArrowBack from "@material-ui/icons/ArrowBack";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// StepWizard forms
import StepWizard from "react-step-wizard";
import TokenForm from "./TokenForm.jsx";
import PasswordForm from "./ChangePassword.jsx";
import SuccessForm from "./SuccessForm.jsx";
import ErrorForm from "./ErrorForm.jsx";

import logo from "assets/img/homepage/AZ_LOGO_HEADER.png";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles((theme) => ({
  ...styles,
  textCenter: {
    textAlign: "center",
  },
  logoImage: {
    margin: "24px 0px",
    paddingLeft: "88px",
    maxWidth: "60%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "64px",
    },
  },
}));

export default function ResetPasswordPage() {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [generalError, setGeneralError] = useState(false);

  async function handleSubmit() {
    try {
      setGeneralError(false);
      const response = await api.post(
        `/users/reset_password`,
        { token },
        {
          auth: {
            username: email,
            password: newPassword,
          },
        }
      );
      console.log(response);
      if (response.statusCode == 200) setGeneralError(false);
      else setGeneralError(true);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <GridContainer justify="center">
      <GridItem xs={12}>
        <Button
          color="primary"
          simple
          size="lg"
          onClick={() => history.push(`/auth/home`)}
        >
          <ArrowBack />
          Voltar ao inicio
        </Button>
      </GridItem>
      <GridItem>
        <img src={logo} className={classes.logoImage} />
      </GridItem>
      <GridItem xs={12} />
      <GridItem xs={12} sm={8} md={6}>
        <Card>
          <CardHeader color="primary">
            <h2 className={`${classes.cardTitleWhite} ${classes.textCenter}`}>
              Esqueci minha senha
            </h2>
          </CardHeader>
          <CardBody>
            <GridContainer justify="center">
              <StepWizard>
                <TokenForm
                  token={token}
                  setToken={setToken}
                  email={email}
                  setEmail={setEmail}
                />
                <PasswordForm
                  password={newPassword}
                  setPassword={setNewPassword}
                  submit={handleSubmit}
                />
                {generalError ? <SuccessForm /> : <ErrorForm />}
              </StepWizard>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
