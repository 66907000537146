import moment from "moment";
import {
  SET_FORM,
  SET_LOADING,
  SIGN_UP,
  RESET_SIGN_UP_RESULT,
} from "../actions/signUpForm";

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  name: "",
  email: "",
  password: "",
  gender: "",
  personType: "CPF",
  birthDate: moment(new Date()).format("YYYY-MM-DD"),
  personIdentifier: "",
  address: {
    zipCode: "",
    state: "",
    city: "",
    neighborhood: "",
    street: "",
    number: "",
    complement: "",
  },
  planId: "",
};

const signUpForm = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FORM:
      return {
        ...state,
        ...action.newDatas,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      };

    case SIGN_UP:
    case RESET_SIGN_UP_RESULT:
      return {
        ...state,
        success: action.success,
        error: action.error,
      };

    default:
      return state;
  }
};

export default signUpForm;
