import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import api from "services/api";
import { validation } from "utils/validate";
import adminRules from "views/administrators/adminRules";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Mail from "@material-ui/icons/MailOutline";

import {
  primaryColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react.js";
const useStyles = makeStyles((theme) => ({
  block: {
    margin: "32px 0px",
  },
  card: {
    border: "2px solid",
    borderColor: primaryColor[0],
    borderRadius: "12px",
    boxShadow:
      "0 4px 4px 0 rgba(" +
      hexToRgb(primaryColor[0]) +
      ", 0.14), 0 6px 2px -4px rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.2), 0 2px 10px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.12)",
  },
  header: {
    marginTop: "16px",
  },
  headerTitle: {
    margin: "0px",
  },
  footer: {
    alignItems: "end",
    justifyContent: "space-between",
    marginBottom: "32px",
  },
  button: {
    marginTop: "8px",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  error: {
    color: "red",
    textAlign: "center",
    fontWeight: "bolder",
  },
}));

export default function NewsletterFormDialog(props) {
  const [open, setOpen] = React.useState(false);

  const { color, className } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [notific, setNotific] = useState(false);
  const [loading, setLoading] = useState(false);

  const [emError, setEmError] = useState(false);
  const [emErrorMsg, setEmErrorMsg] = useState("");
  const [nmError, setNmError] = useState(false);
  const [nmErrorMsg, setNmErrorMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  function setProperError(nameError, emailError) {
    if (nameError) {
      console.log(nameError);
      setNmError(true);
      setNmErrorMsg(nameError[0]);
    } else {
      setNmError(false);
      setNmErrorMsg("");
    }
    if (emailError) {
      setEmError(true);
      setEmErrorMsg(emailError[0]);
    } else {
      setEmError(false);
      setEmErrorMsg("");
    }
  }

  function isValid() {
    const result = validation(
      {
        name,
        email,
      },
      {
        name: adminRules.name,
        email: adminRules.email,
      }
    );

    if (result == null) {
      return true;
    } else {
      setProperError(result.name, result.email);
      return false;
    }
  }

  async function handleCreateNewsletter() {
    if (isValid()) {
      try {
        setLoading(true);
        setErrorMsg("");
        setNmError(false);
        setNmErrorMsg("");
        setEmError(false);
        setEmErrorMsg("");

        await api.post(`/newsletter`, {
          nome: name,
          email: email,
        });
      } catch (error) {
        setErrorMsg(error.response.data.error);
        console.log(errorMsg);
      } finally {
        setLoading(false);
        if (errorMsg == "") {
          setNotific(true);
          setTimeout(function () {
            setNotific(false);
          }, 6000);
        }
        handleClose();
      }
    }
  }

  return (
    <div>
      <Button color={color} className={className} onClick={handleClickOpen}>
        Me avise!
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Mail style={{ fontSize: "56px", color: primaryColor[0] }} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3> Seja avisado do lançamento da plataforma </h3>
            <h4> Seja um dos primeiros a conhecer o potencial da AZ </h4>
          </DialogContentText>
        </DialogContent>
        <GridItem container>
          <GridItem xs={12} sm={8}>
            <CustomInput
              error={nmError}
              errorText={nmErrorMsg}
              labelText="Nome"
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: name,
                onChange: (e) => setName(e.target.value),
              }}
            />
          </GridItem>
        </GridItem>
        <GridItem container extraClass={classes.footer}>
          <GridItem xs={12} sm={8}>
            <CustomInput
              error={emError}
              errorText={emErrorMsg}
              labelText="Email"
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "email",
                value: email,
                onChange: (e) => setEmail(e.target.value),
              }}
            />
          </GridItem>
        </GridItem>
        <DialogActions>
          <GridItem xs={12} sm={4} extraClass={classes.button}>
            <Button
              loading={loading}
              disabled={loading}
              color="primary"
              onClick={() => {
                handleCreateNewsletter();
              }}
            >
              Inscreva-se
            </Button>
          </GridItem>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
        <Snackbar
          place="tc"
          color="success"
          message="Obrigado por se cadastrar! Confira seu email para não perder nossas novidades."
          open={notific}
          close
          closeNotification={() => setNotific(false)}
        />
      </Dialog>
    </div>
  );
}

NewsletterFormDialog.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  className: PropTypes.string,
};
