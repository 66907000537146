import React, { useState } from "react";
import api from "services/api";
import PropTypes from "prop-types";
// @material-ui components
import { makeStyles } from "@material-ui/core/";
import TextField from "@material-ui/core/TextField";
// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// style components
const useStyles = makeStyles({
  reminder: {
    color: "green",
    fontWeight: "bolder",
    textAlign: "center",
    marginTop: "0px",
    marginBottom: "32px",
  },
  textfield: {
    margin: "8px 0px",
  },
  errorMsg: {
    color: "red",
    fontWeight: "bolder",
    textAlign: "center",
  },
  button: {
    marginTop: "32px",
    marginBottom: "16px",
  },
});

export default function TokenForm({
  nextStep,
  token,
  setToken,
  email,
  setEmail,
}) {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [send, setSend] = useState(false);

  function setTokenFormat(value) {
    let upercaseValue = value.toUpperCase();
    setToken(upercaseValue);
  }

  function validEmail() {
    if (email !== "") return true;
    else return false;
  }

  async function sendEmail() {
    if (validEmail()) {
      try {
        setLoading(true);
        console.log("sending email to: " + email);
        const response = await api.post(`/users/forgot_password`, {
          email: email,
        });
        console.log(response);
      } catch (error) {
        console.log(error);
      } finally {
        setError("");
        setSend(true);
        setLoading(false);
      }
    } else {
      setError("Informe um e-mail válido");
    }
  }

  function handleNextStep() {
    if (token === "") {
      setError("Informe um código válido");
    } else {
      setError("");
      nextStep();
    }
  }

  return (
    <GridItem container justify="center">
      <GridItem xs={12} md={10}>
        <h4>
          Precisa recuperar sua senha? Não se preocupe. Basta nos informar seu
          email que inviaremos um código para que você crie uma nova senha.
        </h4>
      </GridItem>
      {send ? (
        <GridItem xs={12} md={10}>
          <p className={classes.reminder}>
            Lembre-se de conferir a caixa de spam!
          </p>
        </GridItem>
      ) : null}
      <GridItem extraClass={classes.textfield}>
        {send ? (
          <TextField
            error={error !== ""}
            variant="outlined"
            color="primary"
            label="Seu código"
            value={token}
            onChange={(e) => setTokenFormat(e.target.value)}
          />
        ) : (
          <GridItem container justify="center">
            <GridItem>
              <TextField
                error={error !== ""}
                variant="outlined"
                color="primary"
                label="E-mail"
                value={email}
                type="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </GridItem>
            <GridItem xs={12} />
            <GridItem>
              <Button
                onClick={() => sendEmail()}
                simple
                color="primary"
                disabled={loading}
                loading={loading}
              >
                Receber código
              </Button>
            </GridItem>
          </GridItem>
        )}
      </GridItem>
      <GridItem xs={12}>
        <p className={classes.errorMsg}>{error}</p>
      </GridItem>
      {send ? (
        <GridItem extraClass={classes.button}>
          <Button color="primary" onClick={handleNextStep}>
            Resetar senha
          </Button>
        </GridItem>
      ) : null}
    </GridItem>
  );
}

TokenForm.propTypes = {
  nextStep: PropTypes.func,
  token: PropTypes.string,
  setToken: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
};
