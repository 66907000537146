import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import api from "services/api";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

import {
  grayColor,
  blackColor,
  primaryColor,
} from "assets/jss/material-dashboard-react";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  post: {
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    width: "300px",
    height: 380,
    border: `0.1px solid ${grayColor[7]}`,
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  postMini: {
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    width: 200,
    border: `0.1px solid ${grayColor[7]}`,
    overflow: "hidden",
  },
  postCover: {
    width: "100%",
    borderRadius: "6px 6px 0px 0px",
  },
  title: {
    color: blackColor,
    fontWeight: "700",
    fontSize: 25,
    margin: "10px 12px",
    transition: "color 0.3s",
    "&:hover": {
      color: primaryColor[0],
    },
  },
  titleMini: {
    color: blackColor,
    fontWeight: "700",
    fontSize: 18,
    margin: "10px 12px",
    transition: "color 0.3s",
    "&:hover": {
      color: primaryColor[0],
    },
  },
  line: {
    width: "80%",
  },
  text: {
    margin: "10px 12px",
  },
  seePost: {
    color: blackColor,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  postDate: {
    margin: "0px 0px 4px 16px",
  },
}));

function PostCard({ withoutText, post }) {
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const [urlTitle, setUrlTitle] = useState("");

  useEffect(() => {
    makeTitle();
    async function getDate() {
      try {
        const postFromServer = (await api.get(`/blog/posts/${post.id}`)).data;
        setDate(postFromServer.createdAt);
      } catch (error) {
        console.log(error);
      }
    }

    getDate();
  }, []);

  function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText || "";
  }

  function makeTitle() {
    const title = post.titulo;
    setUrlTitle(title.replaceAll(" ", "-"));
  }

  return (
    <section
      className={`${
        !withoutText ? classes.post : classes.postMini
      } imageAnimationHover`}
    >
      <a
        href={`/blog/posts/${post.id}/${urlTitle}`}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <img
          className={classes.postCover}
          src={
            post.id_capa
              ? `${api.defaults.baseURL}/blog/images/${post.id_capa}`
              : "https://media.istockphoto.com/photos/bloggingblog-concepts-ideas-with-worktable-picture-id922745190?s=612x612"
          }
          alt="capa da postagem"
        />
      </a>

      <a href={`/blog/posts/${post.id}/${urlTitle}`}>
        <h3 className={!withoutText ? classes.title : classes.titleMini}>
          {post.titulo}
        </h3>
      </a>

      {!withoutText && (
        <React.Fragment>
          <p className={classes.postDate}>
            {" "}
            Publicado em: {moment(date).format("DD/MM/yy")}
          </p>
          <hr className={classes.line} />

          <p className={classes.text}>
            {stripHtml(post.texto ? post.texto : "").slice(0, 120)}...{" "}
            <a href={`/blog/posts/${post.id}/${urlTitle}`}>
              <strong className={classes.seePost}>Continuar lendo</strong>
            </a>
          </p>
        </React.Fragment>
      )}
    </section>
  );
}

PostCard.propTypes = {
  withoutText: PropTypes.bool,
  post: PropTypes.object,
};

PostCard.defaultProps = {
  withoutText: false,
};

export default PostCard;
