/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "services/api";
// @material-ui/icons
import FilterList from "@material-ui/icons/FilterList";
import Close from "@material-ui/icons/Close";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import EmptyCard from "components/ActionCard/EmptyCard.js";
import ActionCard from "components/ActionCard/ActionCard.js";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput.js";
import Snackbar from "components/Snackbar/Snackbar.js";

// style components
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";
import moment from "moment";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
const useModalStyles = makeStyles(modalStyle);
const useStyles = makeStyles({
  ...styles,
  ...stylesSelect,
  head: {
    textAlign: "center",
    marginBottom: "12px",
  },
  orderText: {
    marginRight: "8px",
  },
});

const orderToField = {
  Status: "status",
  Prazo: "data",
};

export default function CardView() {
  const classes = useStyles();
  const modaClasses = useModalStyles();
  const history = useHistory();

  const [order, setOrder] = useState("Prazo");
  const [allActions, setAllActions] = useState([]);
  const [actions, setActions] = useState([]);

  // filter
  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState("Nenhum");
  const [filterTerm, setFilterTerm] = useState("");
  const [filterDate, setFilterDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  // deadline notifications
  const [deadlineNotify, setDeadlineNotify] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  useEffect(() => {
    async function load() {
      try {
        let userId = localStorage.getItem("@userId");
        const response = await api.get(
          `users/${userId}/actions?page=1&size=10000`
        );
        setAllActions(response.data.data);
        setActions(response.data.data);
        checkDeadline(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }

    load();
  }, []);

  useEffect(() => {
    let field = orderToField[order];

    actions.sort((actionA, actionB) =>
      actionA[field].localeCompare(actionB[field])
    );

    setActions([...actions]);
  }, [order]);

  function cleanFilter() {
    setFilter("Nenhum");
    setActions(allActions);
    setFilterTerm("");
    setFilterDate(moment(new Date()).format("YYYY-MM-DD"));
    setModal(false);
  }

  function applyFilter() {
    if (filter === "Nenhum") {
      cleanFilter();
      return;
    }

    let filterActions = [];
    allActions.forEach((element) => {
      switch (filter) {
        case "Responsavel":
          if (element != null) {
            if (element.responsavel.toLowerCase() === filterTerm.toLowerCase())
              filterActions.splice(0, 0, element);
          }
          break;
        case "Status":
          if (
            element.status ===
            filterTerm.charAt(0).toUpperCase() + filterTerm.slice(1)
          )
            filterActions.splice(0, 0, element);
          break;
        case "Prazo":
          if (
            moment(element.data).format("DD/MM/YYYY") ===
            moment(filterDate).format("DD/MM/YYYY")
          )
            filterActions.splice(0, 0, element);
          break;
        case "Cliente":
          if (element != null) {
            if (element.cliente.toLowerCase() === filterTerm.toLowerCase())
              filterActions.splice(0, 0, element);
          }
          break;
        default:
          cleanFilter();
          break;
      }
    });
    setActions(filterActions);
    setModal(false);
  }

  function filterModal() {
    return (
      <GridContainer>
        <GridItem container extraClass={classes.filterInput}>
          <GridItem>
            <p>Filtrar por:</p>
          </GridItem>
          <GridItem>
            <FormControl className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
                inputProps={{
                  name: "filter",
                  id: "filter",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Nenhum"
                >
                  Nenhum
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Responsavel"
                >
                  Responsavel
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Cliente"
                >
                  Cliente
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Status"
                >
                  Status
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Prazo"
                >
                  Prazo
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridItem>
        {filter === "Prazo" ? (
          <GridItem
            container
            alignItems="flex-end"
            extraClass={classes.searchInput}
          >
            <GridItem>
              <p>Informe a data:</p>
            </GridItem>
            <GridItem>
              <CustomInput
                id="date"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "date",
                  value: filterDate,
                  onChange: (e) => setFilterDate(e.target.value),
                }}
              />
            </GridItem>
          </GridItem>
        ) : (
          <GridItem
            container
            alignItems="flex-end"
            extraClass={classes.searchInput}
          >
            <GridItem>
              <p>Digite o termo:</p>
            </GridItem>
            <GridItem>
              <CustomInput
                id="term"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  value: filterTerm,
                  onChange: (e) => setFilterTerm(e.target.value),
                }}
              />
            </GridItem>
          </GridItem>
        )}
      </GridContainer>
    );
  }

  function checkDeadline(actions) {
    let today = moment().format("DD-MM-YYYY");
    let actionsForToday = [];
    actions.forEach((action) => {
      let lembrete = moment(action.lembrete).format("DD-MM-YYYY");
      if (lembrete === today) {
        let titulo = ` "` + action.titulo + `"`;
        actionsForToday.splice(0, 0, titulo);
      }
    });
    let size = actionsForToday.length;
    if (size > 0) {
      let message = "Atenção! ";
      if (size == 1) {
        message =
          message +
          `O lembrete da ação ${actionsForToday} está definido para hoje! Não se esqueça de conferir`;
      } else {
        message =
          message +
          `Os lembretes das ações ` +
          `${actionsForToday}` +
          ` estão definidos para hoje! Não se esqueça de conferi-los`;
      }
      setSnackMessage(message);
      setDeadlineNotify(true);
    }
  }

  return (
    <CardBody>
      {allActions.length == 0 ? (
        <EmptyCard />
      ) : (
        <GridContainer>
          <GridItem xs={12}>
            <GridItem extraClass={classes.head}>
              <Button
                round
                color="success"
                onClick={() => {
                  history.push(`/admin/acoes/new`);
                }}
              >
                Criar nova ação
              </Button>
            </GridItem>
            <Divider variant="middle" />

            <GridItem container>
              <GridItem>
                <Button
                  color="primary"
                  onClick={() => setModal(true)}
                  startIcon={<FilterList />}
                >
                  Filtrar
                </Button>
              </GridItem>
              <GridItem>
                {filter !== "Nenhum" ? (
                  <Button round startIcon={<Close />} onClick={cleanFilter}>
                    Limpar filtro
                  </Button>
                ) : null}
              </GridItem>
            </GridItem>

            <GridItem container>
              <GridItem>
                <p className={classes.orderText}>Ordenar por:</p>
              </GridItem>
              <GridItem>
                <FormControl className={classes.selectFormControl}>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    onChange={(e) => setOrder(e.target.value)}
                    value={order}
                    inputProps={{
                      name: "order",
                      id: "order",
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Status"
                    >
                      Status
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Prazo"
                    >
                      Prazo
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridItem>
          </GridItem>
          {actions.map((action) => (
            <GridItem key={action.id} xs={12} md={6}>
              <ActionCard
                id={action.id}
                title={action.titulo}
                document={action.id_documento}
                date={moment(action.data).format("DD/MM/YYYY")}
                status={action.status}
                responsible={action.responsavel}
                client={action.cliente}
                update={action.updatedAt}
              />
            </GridItem>
          ))}
        </GridContainer>
      )}

      {/* filter modal */}
      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modal}
        onClose={cleanFilter}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>Aplicar filtro</h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          {filterModal()}
        </DialogContent>

        <DialogActions
          className={
            modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
          }
        >
          <Button withProgress color="primary" onClick={applyFilter}>
            Aplicar
          </Button>
          <Button onClick={cleanFilter}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      {/* deadline notifications */}
      <Snackbar
        place="tc"
        color="danger"
        message={snackMessage}
        open={deadlineNotify}
        close
        closeNotification={() => setDeadlineNotify(false)}
      />
    </CardBody>
  );
}
