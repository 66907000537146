import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  page: {
    margin: "0px 30px",
  },
});

function Nav({ currentPage, totalPages, onSelectNewPage }) {
  const classes = useStyles();

  if (currentPage > totalPages) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <div className={classes.container}>
      <Button
        justIcon
        disabled={currentPage <= 1}
        onClick={() => onSelectNewPage(currentPage - 1)}
      >
        <ArrowBackIcon />
      </Button>

      <p className={classes.page}>
        página {currentPage} de {totalPages}
      </p>

      <Button
        justIcon
        disabled={currentPage >= totalPages}
        onClick={() => onSelectNewPage(currentPage + 1)}
      >
        <ArrowForwardIcon />
      </Button>
    </div>
  );
}

Nav.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onSelectNewPage: PropTypes.func.isRequired,
};

export default Nav;
