import React, { useState } from "react";
import api from "services/api";
import { validation } from "utils/validate";
import adminRules from "views/administrators/adminRules";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Mail from "@material-ui/icons/MailOutline";
// core components
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import {
  primaryColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react.js";
const useStyles = makeStyles((theme) => ({
  block: {
    margin: "32px 0px",
  },
  card: {
    border: "2px solid",
    borderColor: primaryColor[0],
    borderRadius: "12px",
    boxShadow:
      "0 4px 4px 0 rgba(" +
      hexToRgb(primaryColor[0]) +
      ", 0.14), 0 6px 2px -4px rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.2), 0 2px 10px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.12)",
  },
  header: {
    marginTop: "16px",
  },
  headerTitle: {
    margin: "0px",
  },
  footer: {
    alignItems: "end",
    justifyContent: "space-between",
    marginBottom: "32px",
  },
  button: {
    marginTop: "8px",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  error: {
    color: "red",
    textAlign: "center",
    fontWeight: "bolder",
  },
}));

export default function RegisterCard() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [notific, setNotific] = useState(false);
  const [loading, setLoading] = useState(false);

  const [emError, setEmError] = useState(false);
  const [emErrorMsg, setEmErrorMsg] = useState("");
  const [nmError, setNmError] = useState(false);
  const [nmErrorMsg, setNmErrorMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  function setProperError(nameError, emailError) {
    if (nameError) {
      console.log(nameError);
      setNmError(true);
      setNmErrorMsg(nameError[0]);
    } else {
      setNmError(false);
      setNmErrorMsg("");
    }
    if (emailError) {
      setEmError(true);
      setEmErrorMsg(emailError[0]);
    } else {
      setEmError(false);
      setEmErrorMsg("");
    }
  }

  function isValid() {
    const result = validation(
      {
        name,
        email,
      },
      {
        name: adminRules.name,
        email: adminRules.email,
      }
    );

    if (result == null) {
      return true;
    } else {
      setProperError(result.name, result.email);
      return false;
    }
  }

  async function handleCreateNewsletter() {
    if (isValid()) {
      try {
        setLoading(true);
        setErrorMsg("");
        setNmError(false);
        setNmErrorMsg("");
        setEmError(false);
        setEmErrorMsg("");

        await api.post(`/newsletter`, {
          nome: name,
          email: email,
        });
      } catch (error) {
        setErrorMsg(error.response.data.error);
        console.log(errorMsg);
      } finally {
        setLoading(false);
        if (errorMsg == "") {
          setNotific(true);
          setTimeout(function () {
            setNotific(false);
          }, 6000);
        }
      }
    }
  }

  return (
    <GridItem container justify="center" extraClass={classes.block}>
      <GridItem xs={12} sm={8} md={6} extraClass={classes.card}>
        <GridItem container extraClass={classes.header}>
          <GridItem>
            <Mail style={{ fontSize: "56px", color: primaryColor[0] }} />
          </GridItem>
          <GridItem>
            <h2 className={classes.headerTitle}>
              Seja avisado do lançamento da plataforma
            </h2>
          </GridItem>
        </GridItem>
        <GridItem xs={12}>
          <h4> Seja um dos primeiros a conhecer o potencial da AZ </h4>
        </GridItem>
        <GridItem extraClass={classes.error}>{errorMsg}</GridItem>
        <GridItem container>
          <GridItem xs={12} sm={8}>
            <CustomInput
              error={nmError}
              errorText={nmErrorMsg}
              labelText="Nome"
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: name,
                onChange: (e) => setName(e.target.value),
              }}
            />
          </GridItem>
        </GridItem>
        <GridItem container extraClass={classes.footer}>
          <GridItem xs={12} sm={8}>
            <CustomInput
              error={emError}
              errorText={emErrorMsg}
              labelText="Email"
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "email",
                value: email,
                onChange: (e) => setEmail(e.target.value),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} extraClass={classes.button}>
            <Button
              loading={loading}
              disabled={loading}
              color="primary"
              onClick={() => handleCreateNewsletter()}
            >
              Cadastrar
            </Button>
          </GridItem>
        </GridItem>
      </GridItem>
      <Snackbar
        place="tc"
        color="success"
        message="Obrigado por se cadastrar! Confira seu email para não perder nossas novidades."
        open={notific}
        close
        closeNotification={() => setNotific(false)}
      />
    </GridItem>
  );
}
