import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.js";

import PremiumAccess from "assets/img/servico-premium.png";

import { hasPremiumPermission } from "utils/premium";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// eslint-disable-next-line react/prop-types
function PermissionsGate({ children }) {
  const history = useHistory();
  const { isAdmin, userProfile } = useSelector((state) => state.user);

  const premiumPermission = hasPremiumPermission(userProfile, isAdmin);

  return premiumPermission ? (
    children
  ) : (
    <Card plain>
      <CardHeader>
        Essa é uma funcionalidade premium. Mude o seu plano para ter todas as
        funcionalidades liberadas.
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem
            container
            alignItems="center"
            justifyContent="center"
            xs={12}
          >
            <img
              src={PremiumAccess}
              alt="Imagem ilustrativa"
              style={{
                maxHeight: 400,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </GridItem>

          <GridItem>
            <Button
              color="primary"
              onClick={() => history.push(`/admin/perfil/subscription`)}
            >
              Mudar meu plano
            </Button>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

export default PermissionsGate;
