export function maskCNPJ(cnpj) {
  cnpj = cnpj
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2");

  return cnpj;
}

export function maskCPF(cpf) {
  cpf = cpf
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  return cpf;
}

export function maskCEP(cep) {
  cep = cep
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1-$2");

  return cep;
}

export function maskMoney(value) {
  value = value
    .replace(/\D/g, "")
    .replace(/(\d{1,3})(\d{8})/, "$1.$2")
    .replace(/(\d{1,3})(\d{5})/, "$1.$2")
    .replace(/(\d)(\d{1,2}$)/, "$1,$2");

  return value;
}
