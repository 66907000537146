import React from "react";
// @material-ui icons
import Add from "@material-ui/icons/Add";
import NoteAdd from "@material-ui/icons/NoteAdd";
import Reorder from "@material-ui/icons/Reorder";
import WarningIcon from "@material-ui/icons/Warning";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import DocEditor from "components/DocumentEditor/DocEditor.js";
import DocumentCardContainer from "components/MyDocuments/DocumentCardContainer";
import Categories from "components/Categories";
import ErrorReport from "components/ErrorReport";
import AdminRestriction from "components/AccessWarning/AdminRestriction.js";
import { blockAccess } from "utils/validate.js";

export default function Dashboard() {
  return (
    <div>
      {blockAccess(2) ? (
        <GridContainer>
          <GridItem xs={12}>
            <CustomTabs
              headerColor="primary"
              tabs={[
                {
                  tabName: "Meus documentos",
                  tabIcon: Reorder,
                  tabContent: <DocumentCardContainer />,
                },
                {
                  tabName: "Novo modelo",
                  tabIcon: NoteAdd,
                  tabContent: <Categories />,
                },
                {
                  tabName: "Criar novo",
                  tabIcon: Add,
                  tabContent: <DocEditor />,
                },
                {
                  tabName: "REPORTAR ERRO",
                  tabIcon: WarningIcon,
                  tabContent: <ErrorReport />,
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      ) : (
        <AdminRestriction />
      )}
    </div>
  );
}
