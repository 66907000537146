import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/core icons
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Button from "components/CustomButtons/Button.js";

import style from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { benefitsTableStyle } from "assets/jss/material-dashboard-react/components/homepageStyle.js";
import { hasPremiumPermission } from "utils/premium";

const useStyles = makeStyles({
  ...style,
  ...benefitsTableStyle,
  center: { textAlign: "center" },
});

export default function ChangeSubscription() {
  const classes = useStyles();
  const history = useHistory();
  const { isAdmin, userProfile } = useSelector((state) => state.user);

  const isPro = hasPremiumPermission(userProfile, isAdmin);

  function benefitsTable() {
    return (
      <GridItem xs={12} md={8}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Compare as vantagens de cada plano
            </h4>
          </CardHeader>
          <CardBody>
            <div className={classes.tableUpgradeWrapper}>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th />
                    <th className={classes.center}>Inicial</th>
                    <th className={classes.center}>PRO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Criar documentos</td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                  </tr>
                  <tr>
                    <td>Gestão de documentos criados</td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                  </tr>
                  <tr>
                    <td>Acesso a todos os modelos de documentos</td>
                    <td className={classes.center}>
                      <Danger>
                        <Close />
                      </Danger>
                    </td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Criação e gestão de planos de ação a serem implementados
                    </td>
                    <td className={classes.center}>
                      <Danger>
                        <Close />
                      </Danger>
                    </td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                  </tr>
                  <tr>
                    <td>Suporte em até 48h no uso da plataforma</td>
                    <td className={classes.center}>
                      <Danger>
                        <Close />
                      </Danger>
                    </td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Acesso a comunidade AZSafe no Facebook e no LinkedIn
                    </td>
                    <td className={classes.center}>
                      <Danger>
                        <Close />
                      </Danger>
                    </td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                  </tr>
                  <tr>
                    <td>Acesso a conteúdo de orientação sobre documentos</td>
                    <td className={classes.center}>
                      <Danger>
                        <Close />
                      </Danger>
                    </td>
                    <td className={classes.center}>Em breve</td>
                  </tr>
                  <tr>
                    <td />
                    <td />
                    <td className={classes.center}>
                      {!isPro ? (
                        <Button
                          round
                          color="primary"
                          onClick={() => history.push("/admin/make-signature/")}
                        >
                          Fazer upgrade
                        </Button>
                      ) : (
                        <Button
                          round
                          onClick={() => history.push("/admin/my-signature/")}
                        >
                          Plano atual
                        </Button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    );
  }

  return (
    <GridContainer>
      <Card plain>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Mudar meu plano</h4>
          <p className={classes.cardCategoryWhite}>
            Escolha o plano que melhor se adeque com você
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer justify="center">{benefitsTable()}</GridContainer>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
