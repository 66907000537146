// CNPJ no rodapé
export const cnpj = "50.595.128/0001-13";

// Descrição do plano na aba: 'Cadastro'
export const planoGratuito =
  "Você terá 1 mês de acesso gratuito a todas as funcionalidades do sistema usando o Plano Gratuito." +
  " Após isso, você poderá escolher um dos planos premuims ou permanecer no plano gratuito, mas com as funcionalidades reduzidas.";

// Aba: 'Contato'
export const contatoTitulo =
  "Preencha o fórmulario abaixo para mandar sua dúvida ou opinião e nos informe o melhor horário para retornarmos";
export const contatoSubtitulo =
  "Ou fale conosco direto pelo nosso whatsapp ou redes sociais";

// Aba: 'Planos e Preços'
export const planoTitulo =
  "Quer fazer parte da nossa lista de usuários testers?";
export const planoSubtitulo =
  "Alguma dúvida sobre nossos preços e condições? Entre em contato conosco";
export const planoInicialTexto =
  "Um versão pocket de nossas funcionalidades para que você possa testar o potencial da AZsafe";
export const planoProTexto =
  "Todas as funções disponíveis pelo prazo que você preferir, sem taxa de implementação";
export const precoPro1 = "Aguarde";
export const precoPro2 = "Aguarde";

// Aba: 'Soluções'
export const solucoesTitulo =
  "Organize seus documentos e tarefas para ter mais agilidade, controle e padronização";
export const solucoesSubtitulo =
  "Diretamente do seu computador ou no smartphone enquanto acompanha o chão de fábrica, para que a Gestão Ocupacional dos seus cliente esteja sempre em dia";
export const grupoUmTitulo =
  "Tenha mais tempo para acompanhar a operação e implementar ações corretivas";
export const grupoUmTexto1 =
  "Chega de trabalho repetitivo: envie eventos do eSocial ao governo com apenas alguns cliques e elabore documentos de modo ágil com edições em lote";
export const grupoUmTexto2 = "";
export const grupoDoisTitulo =
  "Programe alertas para garantir que as tarefas sejam implementadas e controle os Planos de Ação em modelo kanban";
export const grupoDoisTexto1 =
  "E receba também notificações toda vez que uma Norma Regulamentadora for alterada e uma notícia relevante chegar ao setor de Saúde e Segurança do Trabalho";
export const grupoDoisTexto2 = "";

// Aba: 'Home'
export const homeTitulo =
  "A ferramenta que todo consultor em Saúde e Segurança do Trabalho precisa";
export const homeSubtitulo =
  "AZsafe absorve o trabalho repetitivo para que você se dedique ao que mais importa na gestão ocupacional";
export const homeTexto =
  "Envie os eventos do eSocial com alguns cliques e elabore seus documentos de SST com agilidade. Sem taxa de implementação e sem taxa abusiva de cancelamento";
export const homeESocial =
  "Com alguns cliques, transmita os eventos S2210, S2220 e S2240 diretamente para o Governo";
export const homeDocumentos =
  "Elabore programas e laudos partindo de modelos inteligentes e inserindo informações em lotes";
export const homeAcoes =
  "Gerencie seus planos de ação em modelo Kanban e configure alertas para nada ser esquecido";
export const homeNotificacoes =
  "Modificações em normas e atualizações no mercado: te deixaremos por dentro de tudo em tempo real";

// Termos de uso
export const termsParag1 =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius suscipit urna, nec convallis leo ultrices ut. " +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "eget lacus rhoncus, vitae maximus eros condimentum. Cras eu porta ipsum. Etiam id risus elit. Aenean dolor et bibendum luctus. " +
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius suscipit urna, nec convallis leo ultrices ut. " +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "eget lacus rhoncus, vitae maximus eros condimentum. Cras eu porta ipsum. Etiam id risus elit. Aenean dolor et bibendum luctus. ";

export const termsParag2 =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius suscipit urna, nec convallis leo ultrices ut. " +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "eget lacus rhoncus, vitae maximus eros condimentum. Cras eu porta ipsum. Etiam id risus elit. Aenean dolor et bibendum luctus. " +
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius suscipit urna, nec convallis leo ultrices ut. " +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "eget lacus rhoncus, vitae maximus eros condimentum. Cras eu porta ipsum. Etiam id risus elit. Aenean dolor et bibendum luctus. ";

export const termsParag3 =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius suscipit urna, nec convallis leo ultrices ut. " +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "eget lacus rhoncus, vitae maximus eros condimentum. Cras eu porta ipsum. Etiam id risus elit. Aenean dolor et bibendum luctus. " +
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius suscipit urna, nec convallis leo ultrices ut. " +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "eget lacus rhoncus, vitae maximus eros condimentum. Cras eu porta ipsum. Etiam id risus elit. Aenean dolor et bibendum luctus. ";

// Politica de privacidade
export const policyParag1 =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius suscipit urna, nec convallis leo ultrices ut. " +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "eget lacus rhoncus, vitae maximus eros condimentum. Cras eu porta ipsum. Etiam id risus elit. Aenean dolor et bibendum luctus. " +
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius suscipit urna, nec convallis leo ultrices ut. " +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "eget lacus rhoncus, vitae maximus eros condimentum. Cras eu porta ipsum. Etiam id risus elit. Aenean dolor et bibendum luctus. ";

export const policyParag2 =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius suscipit urna, nec convallis leo ultrices ut. " +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "eget lacus rhoncus, vitae maximus eros condimentum. Cras eu porta ipsum. Etiam id risus elit. Aenean dolor et bibendum luctus. " +
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius suscipit urna, nec convallis leo ultrices ut. " +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "eget lacus rhoncus, vitae maximus eros condimentum. Cras eu porta ipsum. Etiam id risus elit. Aenean dolor et bibendum luctus. ";

export const policyParag3 =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius suscipit urna, nec convallis leo ultrices ut. " +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "eget lacus rhoncus, vitae maximus eros condimentum. Cras eu porta ipsum. Etiam id risus elit. Aenean dolor et bibendum luctus. " +
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius suscipit urna, nec convallis leo ultrices ut. " +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "Morbi et tortor sed ante tincidunt pharetra sed a dolor. Etiam dignissim elit a nulla pellentesque, quis tristique leo aliquet. " +
  "Nulla malesuada, ipsum eu hendrerit pharetra, urna nunc molestie nisl, vitae elementum enim tellus aliquam risus. Sed tempus quam" +
  "eget lacus rhoncus, vitae maximus eros condimentum. Cras eu porta ipsum. Etiam id risus elit. Aenean dolor et bibendum luctus. ";

// FAQ
export const faqQuest = [
  [
    0,
    "A AZsafe é um sistema?",
    "Não, somos uma plataforma de Elaboração e Gestão de documentos e planos de ação, juntamente com um emissor de notificações" +
      " de urgência, que integra tecnologia e pessoas.",
  ],
  [
    1,
    "Quais as referências usadas para compor os modelos-inteligentes?",
    "Todos os documentos são construídos seguindo as definições das Normas Regulamentadoras e legislação complementar, além de" +
      " pesquisas sobre conceitos do mercado e termos técnicos; com prioridade máxima para fontes oficiais. No trecho final de" +
      " cada modelo, é possível ver listadas as referências usadas. Os modelos passam por processo de revisão rigorosa de consultores" +
      " experientes na área antes de irem ao ar.",
  ],
  [
    2,
    "A AZsafe me notificará quando uma ação estiver próxima a ser executada?",
    "Sim. Você pode configurar na plataforma a antecedência com a qual gostaria de receber o alerta: 1 dia ou 1 hora antes.",
  ],
  [
    3,
    "Com os modelos-inteligente de documentos a análise em campo no empreendimento é desnecessária?",
    "Não! A atuação em campo sempre continuará sendo primordial para a execução das atividades do profissional em Segurança do Trabalho." +
      " A missão da AZsafe é reduzir o esforço gasto com ações repetitivas e facilitar a gestão da rotina tão cheia de informações e demandas.",
  ],
  [
    4,
    "Sobre o que são as notificações que a plataforma me envia?",
    "Todas as alterações na legislação: assim que são divulgadas e também quando sua entrada em vigor aproxima-se. E notícias de forte" +
      " impacto no setor: alterações nas configurações dos órgãos competentes,  novas tecnologias, oportunidades, entre outros temas.",
  ],
  [
    5,
    "O que é a Comunidade AZsafe?",
    "É nossa comunidade fechada no Facebook em que nos mantemos plenamente informados e nos apoiamos para construir a nova cultura de" +
      " Segurança do Trabalho no Brasil.",
  ],
  [
    6,
    "Ao final do mês de experimentação gratuita, passarei a ser cobrado?",
    "Não haverá cobrança! Você experimenta por um mês gratuitamente sem firmar qualquer compromisso de contratação do plano.",
  ],
  [
    7,
    "Preciso ter o valor total dos 6 ou 12 meses de assinatura em limite no meu cartão para realizar a compra?",
    "Não. Caso você não tenha disponível o valor total em limite de crédito, a dedução será feita a cada mês seguindo o número contratado" +
      " de parcelas",
  ],
  [
    8,
    "Como posso cancelar meu plano?",
    "Para cancelar seu plano, basta fazer login, ir até Meu Perfil no menu e clicar no botão Cancelar Assinatura. Após isso, é só responder" +
      " as perguntas que o sistema exibe e a confirmação de cancelamento chegará ao seu e-mail.",
  ],
  [
    9,
    "Como a AZsafe garante a privacidade e a segurança dos meus dados?",
    "Conforme descrito na nossa Política de Privacidade, seus dados sensíveis trafegam e permanecem criptografados dentro da nossa plataforma." +
      " Também está descrito neste documento quais informações precisamos ter acesso para funcionamento básico da nossa solução.",
  ],
];
