import { combineReducers } from "redux";
import signUpForm from "./signUpForm";
import auth from "./auth";
import user from "./user";
import notifications from "./notifications";

export default combineReducers({
  signUpForm, //TODO: change to use context api?
  auth,
  user,
  notifications,
});
