import {
  SET_USER,
  LOAD_USER,
  SET_ERROR,
  SET_LOADING,
  LOAD_ADMIN,
} from "../actions/user";

const INITIAL_STATE = {
  loading: false,
  error: null,
  userProfile: {},
};

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          ...action.user,
        },
      };

    case LOAD_USER:
      return {
        ...state,
        userProfile: {
          ...action.user,
          address: action.address,
          wallet: action.wallet,
        },
        isAdmin: false,
      };

    case LOAD_ADMIN:
      return {
        ...state,
        userProfile: {
          ...action.admin,
        },
        isAdmin: true,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      };

    default:
      return state;
  }
};

export default user;
