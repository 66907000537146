import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import FeedbackCard from "components/FeedbackCard/FeedbackCard.js";
// style components
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);

export default function FeedbackPage() {
  const classes = useStyles();

  return (
    <Card plain>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Feedback</h4>
        <p className={classes.cardCategoryWhite}>Queremos ouvir sua opinião</p>
      </CardHeader>
      <FeedbackCard />
    </Card>
  );
}
