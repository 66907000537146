export default {
  email: {
    presence: {
      message: "Informe seu email",
      allowEmpty: false,
    },
    email: {
      message: "Informe um email válido",
    },
  },
  password: {
    length: {
      minimum: 6,
      message: "Sua senha precisa ter pelo menos 6 dígitos",
    },
  },
  confirmPassword: {
    equality: {
      attribute: "password",
      message: "As senhas não conferem",
    },
  },
  name: {
    presence: {
      message: "Informe seu nome",
      allowEmpty: false,
    },
    length: {
      minimum: 4,
      message: "Nome muito curto",
    },
  },
};
