import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/PersonOutline";
import Menu from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Home from "@material-ui/icons/Home";
import Work from "@material-ui/icons/Work";
import PostAdd from "@material-ui/icons/PostAdd";
import CreditCard from "@material-ui/icons/CreditCard";
import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import {
  headerStyle,
  homepageStyle,
} from "assets/jss/material-dashboard-react/components/homepageStyle.js";
const useStyles = makeStyles({ ...headerStyle, ...homepageStyle });

import logoImg from "assets/img/homepage/AZ_LOGO_HEADER.png";

export default function Header({ page }) {
  const history = useHistory();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  function tabSelect() {
    return (
      <GridItem xs={12} container extraClass={classes.tabs}>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <a
              className={page === "home" ? classes.tabSelect : classes.tabText}
              onClick={() => {
                history.push(`/auth/home`);
              }}
            >
              Home
            </a>
          </GridItem>
          {page === "home" ? (
            <GridItem xs={1} extraClass={classes.tabIndicator} />
          ) : (
            <GridItem xs={1} extraClass={classes.tabEmpty} />
          )}
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <a
              className={
                page === "solucoes" ? classes.tabSelect : classes.tabText
              }
              onClick={() => {
                history.push(`/auth/solucoes`);
              }}
            >
              Soluções
            </a>
          </GridItem>
          {page === "solucoes" ? (
            <GridItem xs={1} extraClass={classes.tabIndicator} />
          ) : (
            <GridItem xs={1} extraClass={classes.tabEmpty} />
          )}
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <a
              className={
                page === "planos" ? classes.tabSelect : classes.tabText
              }
              onClick={() => {
                history.push(`/auth/planos`);
              }}
            >
              Planos e Preços
            </a>
          </GridItem>
          {page === "planos" ? (
            <GridItem xs={1} extraClass={classes.tabIndicator} />
          ) : (
            <GridItem xs={1} extraClass={classes.tabEmpty} />
          )}
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <a
              className={page === "blog" ? classes.tabSelect : classes.tabText}
              onClick={() => {
                history.push(`/blog`);
              }}
            >
              Blog
            </a>
          </GridItem>
          {page === "blog" ? (
            <GridItem xs={1} extraClass={classes.tabIndicator} />
          ) : (
            <GridItem xs={1} extraClass={classes.tabEmpty} />
          )}
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <a
              className={
                page === "contato" ? classes.tabSelect : classes.tabText
              }
              onClick={() => {
                history.push(`/auth/contato`);
              }}
            >
              Contato
            </a>
          </GridItem>
          {page === "contato" ? (
            <GridItem xs={1} extraClass={classes.tabIndicator} />
          ) : (
            <GridItem xs={1} extraClass={classes.tabEmpty} />
          )}
        </GridContainer>
      </GridItem>
    );
  }

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  function drawerItem(item, icon, link) {
    return (
      <GridItem xs={12} extraClass={classes.drawerItem}>
        <Button
          simple
          size="lg"
          onClick={() => {
            history.push(link);
          }}
          startIcon={icon}
        >
          {item}
        </Button>
      </GridItem>
    );
  }

  return (
    <React.Fragment>
      <GridContainer extraClass={classes.container}>
        <GridItem xs={2}>
          <a href="/">
            <img src={logoImg} alt="logo da azsafe" className={classes.logo} />
          </a>
        </GridItem>
        <Hidden only={["xs", "sm"]}>
          <GridItem xs={8} container extraClass={classes.tabs}>
            {tabSelect()}
          </GridItem>
          <GridItem xs={2} extraClass={classes.right}>
            <Button
              color="primary"
              startIcon={<Person />}
              onClick={() => {
                history.push(`/auth/em-breve`);
                // history.push(`/auth/login`);
              }}
            >
              ENTRAR
            </Button>
          </GridItem>
        </Hidden>
        <Hidden only={["md", "lg", "xl"]}>
          <GridItem xs={10} extraClass={classes.right}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              size="small"
            >
              <Menu className={classes.drawerIcon} />
            </IconButton>
          </GridItem>
        </Hidden>
      </GridContainer>
      <SwipeableDrawer
        anchor="top"
        open={mobileOpen}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <GridContainer xs={12} justify="center">
          <GridItem container extraClass={classes.drawerHeader}>
            <GridItem xs={12}>
              <Button
                simple
                onClick={() => handleDrawerClose()}
                startIcon={<Close />}
              >
                Fechar menu
              </Button>
            </GridItem>
          </GridItem>
          <GridItem xs={12}>
            <Divider variant="middle" />
          </GridItem>
          {drawerItem("home", <Home />, "/auth/home")}
          {drawerItem("solucoes", <Work />, "/auth/solucoes")}
          {drawerItem("blog", <PostAdd />, "/blog")}
          {drawerItem("planos", <CreditCard />, "/auth/planos")}
          {drawerItem("contato", <Email />, "/auth/contato")}
          <GridItem xs={12}>
            <Divider variant="middle" />
          </GridItem>
          <GridItem extraClass={classes.drawerButton}>
            <Button
              color="primary"
              startIcon={<Person />}
              onClick={() => {
                history.push(`/auth/em-breve`);
                // history.push(`/auth/login`);
              }}
            >
              ENTRAR
            </Button>
          </GridItem>
        </GridContainer>
      </SwipeableDrawer>
    </React.Fragment>
  );
}
// types
// xs < sm < md < lg < xl

Header.propTypes = {
  page: PropTypes.string,
};
