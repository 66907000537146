import React from "react";
// @material-ui/icons
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import PaymentIcon from "@material-ui/icons/Payment";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import Person from "@material-ui/icons/Person";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Notifications from "@material-ui/icons/Notifications";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListIcon from "@material-ui/icons/List";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import DescriptionIcon from "@material-ui/icons/Description";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import BookIcon from "@material-ui/icons/Book";
import PostAddIcon from "@material-ui/icons/PostAdd";
import MovieIcon from "@material-ui/icons/Movie";
import FeedbackIcon from "@material-ui/icons/Feedback";
// core components/views for Admin layout
import Documentos from "views/Documentos/Documentos.js";
import EditDocument from "views/Documentos/EditDocument.js";
import Perfil from "views/Perfil";
import Acoes from "views/Acoes/Acoes.js";
import Suporte from "views/Suporte/Suporte.js";
import Users from "views/administrators/UsersInfo";
import ListAdministrators from "views/administrators";
import NewAdmin from "views/administrators/NewAdmin";
import EditAdmin from "views/administrators/EditAdmin";
import NewAcao from "views/Acoes/NewAcao";
import EditAction from "views/Acoes/EditAction";
import ListNotifications from "views/Notificacoes";
import NewNotification from "views/Notificacoes/NewNotification.js";
import EditNotification from "views/Notificacoes/EditNotification";
import ChangeSubscription from "views/Subscription/ChangeSubscription.js";
import MenageTutorial from "views/Tutoriais/ManageTutorials";
import FeedbackPage from "views/Feedback/Feedback.js";
//auth layout
import SignUp from "views/auth/SignUp";
import EmBreve from "views/auth/EmBreve/EmBreve.js";
import LoginCard from "views/auth/Login/LoginCard.js";
import Homepage from "views/auth/Homepages/Homepage.js";
import NotFound from "views/auth/Homepages/NotFound.js";
import Solucoes from "views/auth/Homepages/Solucoes.js";
import Planos from "views/auth/Homepages/Planos.js";
import Contato from "views/auth/Homepages/Contato.js";
import ConfirmEmailPage from "views/auth/Emails/ConfirmEmail.js";
import ResetPasswordPage from "views/auth/Emails/ResetPassword";
// blog layout
import ListPosts from "views/blogAdminArea/index";
import NewPost from "views/blogAdminArea/NewPost";
import EditPost from "views/blogAdminArea/EditPost";
import Posts from "views/blog/Posts";
import Post from "views/blog/Post";
import Policy from "views/auth/Homepages/Policy.js";
import Terms from "views/auth/Homepages/Terms.js";
import Tutoriais from "views/Tutoriais/Tutoriais.js";
//plans and signatures
// import ListCharges from "views/ManageSignatures/ListCharges";
import ListPlans from "views/ManagePlans/index";
import NewPlan from "views/ManagePlans/NewPlan";
import EditPlan from "views/ManagePlans/EditPlan";
import MakeSignature from "views/Signature/MakeSignature";
import MySignature from "views/Signature/MySignature";

const dashboardRoutes = [
  // Assinaturas
  // {
  //   collapse: true,
  //   name: "Assinaturas",
  //   icon: SettingsIcon,
  //   state: "signatures",
  //   adminOnly: true,
  //   views: [
  //     {
  //       path: "/manage-charges/",
  //       icon: ListIcon,
  //       component: ListCharges,
  //       name: "Ver cobranças",
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // Planos
  {
    collapse: true,
    name: "Planos",
    icon: SettingsIcon,
    state: "plans",
    adminOnly: true,
    views: [
      {
        path: "/manage-plans/",
        icon: ListIcon,
        component: ListPlans,
        name: "Ver planos",
        layout: "/admin",
      },
      {
        path: "/manage-plans/new",
        icon: AddCircleIcon,
        component: NewPlan,
        name: "Novo plano",
        layout: "/admin",
      },
      {
        hidden: true,
        path: "/manage-plans/:id/edit",
        component: EditPlan,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Premium",
    icon: PaymentIcon,
    state: "premium",
    views: [
      {
        path: "/make-signature/",
        icon: ShoppingCartIcon,
        component: MakeSignature,
        name: "Nova assinatura",
        layout: "/admin",
      },
      {
        path: "/my-signature/",
        icon: AssignmentTurnedInIcon,
        component: MySignature,
        name: "Minha assinatura",
        layout: "/admin",
      },
    ],
  },
  // Documentos
  {
    path: "/documentos",
    name: "Documentos",
    icon: DescriptionIcon,
    component: Documentos,
    layout: "/admin",
  },
  {
    hidden: true,
    path: "/documentos/:id/edit",
    component: EditDocument,
    layout: "/admin",
  },
  // Acoes
  {
    path: "/acoes",
    name: "Ações",
    icon: PlaylistAddCheckIcon,
    component: Acoes,
    layout: "/admin",
  },
  {
    hidden: true,
    path: "/acoes/new",
    component: NewAcao,
    layout: "/admin",
  },
  {
    hidden: true,
    path: "/acoes/:id/edit",
    component: EditAction,
    layout: "/admin",
  },
  // Notificações
  {
    path: "/notifications",
    icon: Notifications,
    component: ListNotifications,
    name: "Ver notificações",
    layout: "/admin",
  },
  {
    path: "/notifications/new",
    adminOnly: true,
    icon: AddAlertIcon,
    component: NewNotification,
    name: "Nova notificação",
    layout: "/admin",
  },
  {
    hidden: true,
    path: "/notifications/:id/edit",
    adminOnly: true,
    component: EditNotification,
    layout: "/admin",
  },
  // Perfil
  {
    path: "/perfil",
    name: "Meu perfil",
    icon: Person,
    component: Perfil,
    layout: "/admin",
  },
  {
    hidden: true,
    path: "/perfil/subscription",
    component: ChangeSubscription,
    layout: "/admin",
  },
  // Admin
  {
    collapse: true,
    name: "Administração",
    adminOnly: true,
    icon: SettingsApplicationsIcon,
    state: "admin",
    views: [
      {
        path: "/users",
        icon: PeopleAltIcon,
        component: Users,
        name: "Usuarios",
        layout: "/admin",
      },
      {
        path: "/administrators",
        icon: SupervisorAccountIcon,
        component: ListAdministrators,
        name: "Administradores",
        layout: "/admin",
      },
      {
        path: "/administrators/new",
        icon: PersonAddIcon,
        component: NewAdmin,
        name: "Novo Administrador",
        layout: "/admin",
      },
    ],
  },
  {
    hidden: true,
    path: "/administrators/:id/edit",
    adminOnly: true,
    component: EditAdmin,
    layout: "/admin",
  },
  // Blog
  {
    collapse: true,
    name: "Blog",
    adminOnly: true,
    icon: BookIcon,
    state: "blog",
    views: [
      {
        path: "/blog",
        icon: ListIcon,
        component: ListPosts,
        name: "Postagens",
        layout: "/admin",
      },
      {
        path: "/blog/new",
        icon: PostAddIcon,
        component: NewPost,
        name: "Nova postagem",
        layout: "/admin",
      },
    ],
  },
  {
    hidden: true,
    path: "/blog/:id/edit",
    adminOnly: true,
    component: EditPost,
    layout: "/admin",
  },
  // Tutoriais
  {
    path: "/tutorial",
    adminOnly: true,
    name: "Tutoriais",
    component: MenageTutorial,
    icon: MovieIcon,
    layout: "/admin",
  },
  // Suporte
  {
    path: "/suporte",
    name: "Suporte",
    icon: LiveHelpIcon,
    component: Suporte,
    layout: "/admin",
  },
  // Feedback
  {
    path: "/feedback",
    name: "Feedback",
    icon: FeedbackIcon,
    component: FeedbackPage,
    layout: "/admin",
  },
  // Logout
  {
    isAction: true,
    action: "logout",
    name: "Sair do sistema",
    icon: ExitToAppIcon,
    layout: "/admin",
  },
];

export const authRoutes = [
  {
    path: "/sign-up",
    name: "Cadastro",
    component: SignUp,
    layout: "/auth",
  },
  {
    path: "/em-breve",
    name: "EmBreve",
    component: EmBreve,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Login",
    component: LoginCard,
    layout: "/auth",
  },
  {
    path: "/admin-login",
    name: "Login",
    // eslint-disable-next-line react/display-name
    component: () => <LoginCard isAdminLogin />,
    layout: "/auth",
  },
  {
    path: "/home",
    name: "Página principal",
    component: Homepage,
    layout: "/auth",
  },
  {
    path: "/error-404",
    name: "Erro 404",
    component: NotFound,
    layout: "/auth",
  },
  {
    path: "/solucoes",
    name: "Soluções",
    component: Solucoes,
    layout: "/auth",
  },
  {
    path: "/planos",
    name: "Planos e preços",
    component: Planos,
    layout: "/auth",
  },
  {
    path: "/contato",
    name: "Contato",
    component: Contato,
    layout: "/auth",
  },
  {
    path: "/confirm",
    name: "Confirmar email",
    component: ConfirmEmailPage,
    layout: "/auth",
  },
  {
    path: "/reset-password",
    name: "Resetar senha",
    component: ResetPasswordPage,
    layout: "/auth",
  },
];

export const blogRoutes = [
  {
    path: "/posts",
    name: "Postagens",
    component: Posts,
    layout: "/blog",
  },
  {
    path: "/posts/:id/:title",
    name: "Postagem",
    component: Post,
    layout: "/blog",
  },
  {
    path: "/policy",
    name: "Politica de privacidade",
    component: Policy,
    layout: "/blog",
  },
  {
    path: "/terms",
    name: "Termos de uso",
    component: Terms,
    layout: "/blog",
  },
  {
    path: "/tutoriais",
    name: "Tutoriais",
    component: Tutoriais,
    layout: "/blog",
  },
];

export default dashboardRoutes;
