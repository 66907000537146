import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import WarningOutlined from "@material-ui/icons/WarningOutlined";
// core components
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { warningColor } from "assets/jss/material-dashboard-react.js";
const useStyles = makeStyles({
  cardPadding: {
    padding: "32px",
  },
  icon: {
    fontSize: "64px",
    color: warningColor[0],
  },
});

export default function AdminRestriction() {
  const classes = useStyles();

  return (
    <GridContainer extraClass={classes.cardPadding}>
      <Card className={classes.cardPadding}>
        <GridItem>
          <WarningOutlined className={classes.icon} />
        </GridItem>
        <GridItem>
          <h3>
            Essa funcionalidade não está disponível para o seu tipo de acesso
          </h3>
        </GridItem>
        <GridItem>
          <h4>Confira com o administrador se suas permissões estão corretas</h4>
        </GridItem>
      </Card>
    </GridContainer>
  );
}
