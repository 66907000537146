import React, { useEffect, useState, createRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import className from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { loadUser, loadAdmin, setError } from "store/actions/user";
import { logout, toggleModal } from "store/actions/auth";
import { loadNotifications } from "store/actions/notifications";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Button from "components/CustomButtons/Button.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes/routes.jsx";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";

import logo from "assets/img/AZ_LOGO_FINAL.png";
// eslint-disable-next-line no-unused-vars
let ps;

function getRoutes(routes, isAdmin) {
  return routes.map((prop, key) => {
    if (prop.adminOnly && !isAdmin) {
      return;
    }

    if (prop.collapse) {
      return getRoutes(prop.views);
    }

    if (prop.layout === "/admin" && !prop.isAction) {
      return (
        <Route
          exact
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    }
    return null;
  });
}

const SwitchRoutes = () => {
  const { isAdmin } = useSelector((state) => state.user);

  return (
    <Switch>
      {getRoutes(routes, isAdmin)}
      <Redirect from="/admin" to="/admin/documentos" />
    </Switch>
  );
};

const useStyles = makeStyles(styles);
const useModalStyles = makeStyles(modalStyle);

export default function Admin({ ...rest }) {
  const dispatch = useDispatch();
  const modaClasses = useModalStyles();
  const { modalLogout } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.user);
  const [miniActive, setMiniActive] = useState(false);
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = useState(false);

  const mainPanelClasses =
    classes.mainPanel +
    " " +
    className({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }

    window.addEventListener("resize", resizeFunction);

    // // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  useEffect(() => {
    if (!localStorage.getItem("@isAdmin")) {
      dispatch(loadUser());
    } else {
      dispatch(loadAdmin());
    }

    dispatch(loadNotifications());
  }, []);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logo={logo}
          image={"black"}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={"purple"}
          bgColor="black"
          miniActive={miniActive}
          {...rest}
        />

        <div className={mainPanelClasses} ref={mainPanel}>
          <Navbar
            routes={routes}
            handleDrawerToggle={handleDrawerToggle}
            miniActive={miniActive}
            sidebarMinimize={() => setMiniActive(!miniActive)}
            {...rest}
          />

          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <SwitchRoutes />
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <SwitchRoutes />
            </div>
          )}

          {getRoute() ? <Footer /> : null}
        </div>
      </div>

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modalLogout}
        onClose={() => dispatch(toggleModal())}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => dispatch(toggleModal())}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>Sair do sistema</h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          <h5>Você realmente quer sair do sistema?</h5>
        </DialogContent>

        <DialogActions
          className={
            modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
          }
        >
          <Button onClick={() => dispatch(toggleModal())}>Não</Button>

          <Button
            onClick={() => {
              dispatch(logout());
              dispatch(toggleModal());
            }}
            color="danger"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={loading || error}
        onClose={() => {}}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        {error && (
          <DialogTitle
            id="modal-slide-title"
            disableTypography
            className={modaClasses.modalHeader}
          >
            <h4 className={modaClasses.modalTitle}>Ocorreu um erro</h4>
          </DialogTitle>
        )}

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          {!error ? <CircularProgress /> : <p>{error}</p>}
        </DialogContent>

        {error && (
          <DialogActions
            className={
              modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
            }
          >
            <Button onClick={() => dispatch(setError(null))} color="danger">
              Ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
}
