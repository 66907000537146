export default {
  title: {
    presence: {
      message: "Informe o titulo",
      allowEmpty: false,
    },
    length: {
      minimum: 4,
      message: "Titulo muito curto",
    },
  },
  description: {
    presence: {
      message: "Informe a descrição",
      allowEmpty: false,
    },
    length: {
      minimum: 4,
      message: "Descrição muito curta",
    },
  },
};
