import React, { createRef } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { connect } from "react-redux";
import { toggleModal } from "store/actions/auth";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
// core components
import SidebarWrapper from "./SidebarWrapper";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes),
    };
  }
  mainPanel = createRef();

  navActions = {
    logout: () => this.props.dispatch(toggleModal()),
  };

  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }

      return null;
    });

    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }

    return false;
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    const pos = window.location.href.indexOf(routeName);
    if (pos !== -1 && pos === window.location.href.length - routeName.length) {
      return "active";
    }

    return "";
  };

  openCollapse(collapse) {
    let st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    const { classes, color, rtlActive } = this.props;

    return routes.map((prop, key) => {
      if (
        prop.redirect ||
        prop.hidden ||
        (prop.adminOnly && !this.props.user.isAdmin)
      ) {
        return null;
      }

      const itemIcon =
        classes.itemIcon +
        " " +
        classNames({
          [classes.itemIconRTL]: rtlActive,
        });

      const itemText =
        classes.itemText +
        " " +
        classNames({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive,
        });

      const collapseItemText =
        classes.collapseItemText +
        " " +
        classNames({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive,
        });

      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        classNames({
          [classes.collapseItemMiniRTL]: rtlActive,
        });

      if (prop.collapse) {
        let st = {};
        st[prop["state"]] = !this.state[prop.state];

        const navLinkClasses =
          classes.itemLink +
          " " +
          classNames({
            [" " + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            ),
          });

        const caret =
          classes.caret +
          " " +
          classNames({
            [classes.caretRTL]: rtlActive,
          });

        return (
          <ListItem
            key={key}
            className={classNames(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (this.state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={classNames(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>

            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }

      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        classNames({
          [" " + classes[color]]: this.activeRoute(prop.path),
        });

      const navLinkClasses =
        classes.itemLink +
        " " +
        classNames({
          [" " + classes[color]]: this.activeRoute(prop.path),
        });

      return (
        <ListItem
          key={key}
          className={classNames(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          {!prop.isAction ? (
            <NavLink
              to={prop.layout + prop.path}
              className={classNames(
                { [navLinkClasses]: prop.icon !== undefined },
                { [innerNavLinkClasses]: prop.icon === undefined }
              )}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                disableTypography={true}
                className={classNames(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
          ) : (
            <Button
              startIcon={
                prop.icon !== undefined ? (
                  typeof prop.icon === "string" ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={itemIcon} />
                  )
                ) : (
                  <span className={collapseItemMini}>
                    {rtlActive ? prop.rtlMini : prop.mini}
                  </span>
                )
              }
              simple
              onClick={this.navActions[prop.action]}
            >
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                disableTypography={true}
                className={classNames(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </Button>
          )}
        </ListItem>
      );
    });
  };

  render() {
    const {
      classes,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
    } = this.props;

    let links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      classNames({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive,
      });

    const logoMini =
      classes.logoMini +
      " " +
      classNames({
        [classes.logoMiniRTL]: rtlActive,
      });

    const logoClasses =
      classes.logo +
      " " +
      classNames({
        [classes.whiteAfter]: bgColor === "white",
      });

    let brand = (
      <div className={logoClasses}>
        <a
          // href="/"
          // target="_blank"
          // rel="noreferrer"
          className={logoMini}
          style={{
            height: !(this.props.miniActive && this.state.miniActive)
              ? "120px"
              : "30px",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ height: "100%", width: "auto" }}
          />
        </a>

        <a
          href="https://www.creative-tim.com?ref=mdpr-sidebar"
          target="_blank"
          className={logoNormal}
          rel="noreferrer"
        >
          {logoText}
        </a>
      </div>
    );

    const drawerPaper =
      classes.drawerPaper +
      " " +
      classNames({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      });

    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      classNames({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });

    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              isMiniActive={this.props.miniActive && this.state.miniActive}
              className={sidebarWrapper}
              headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>

        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}
            <SidebarWrapper
              isMiniActive={this.props.miniActive && this.state.miniActive}
              className={sidebarWrapper}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  dispatch: PropTypes.func,
  user: PropTypes.objectOf({
    isAdmin: PropTypes.bool.isRequired,
  }),
};

const mapStateToProps = (state) => ({ user: state.user });

export default withStyles(sidebarStyle)(connect(mapStateToProps)(Sidebar));
