import React, { useState, useEffect } from "react";
import api from "services/api";
import { useHistory } from "react-router-dom";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CollapseCategory from "./CollapseCategory";
import CustomInput from "components/CustomInput/CustomInput.js";

import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
const useModalStyles = makeStyles(modalStyle);
import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";
const useStyles = makeStyles(stylesSelect);

export default function Categories() {
  const history = useHistory();
  const classes = useStyles();
  const modaClasses = useModalStyles();
  const [isOpen, setIsOpen] = useState("");
  const [documents, setDocuments] = useState([]);
  const [groups, setGroups] = useState([]);
  // create
  const [modalDelete, setModalDelete] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [error, setError] = useState("");
  // delete
  const [categories, setCategories] = useState([]);
  const [deleteCategory, setDeleteCategory] = useState("");

  useEffect(() => {
    async function loadCategories() {
      try {
        const response = await api.get("/docs/categories");

        setCategories(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    loadCategories();
  }, []);

  useEffect(() => {
    async function loadDocuments() {
      try {
        const response = await api.get("/docs/models?page=1&size=10000");

        setDocuments(response.data);

        let reducedGroups = {};
        for (const document of response.data) {
          if (document.id_categoria && reducedGroups[document.id_categoria]) {
            reducedGroups[document.id_categoria].data.push(document);
          } else if (document.id_categoria) {
            reducedGroups[document.id_categoria] = {};
            reducedGroups[document.id_categoria].data = [document];
            reducedGroups[document.id_categoria].id = document.id_categoria;

            const categoryInfo = await api.get(
              `/docs/categories/${document.id_categoria}`
            );
            reducedGroups[document.id_categoria].title = categoryInfo.data.nome;
          }
        }

        const array = Object.keys(reducedGroups).map(
          (key) => reducedGroups[key]
        );

        setGroups(array);
      } catch (error) {
        console.log(error);
      }
    }

    loadDocuments();
  }, []);

  async function handleCreateCategory() {
    if (categoryName === "") {
      setError("Informe um nome para a categoria");
    } else {
      try {
        setError("");
        const response = await api.post("/docs/categories", {
          nome: categoryName,
        });

        console.log(response);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        if (error === "") setModalCreate(false);
      }
    }
  }

  async function handleDeleteCategory() {
    try {
      const response = await api.delete(
        `/docs/categories/${deleteCategory.id}`
      );
      console.log(response);
      history.push("/admin");
    } catch (error) {
      console.log(error);
    } finally {
      setModalDelete(false);
    }
  }

  function modalClose() {
    console.log(deleteCategory);
    setError("");
    setDeleteCategory("");
    setCategoryName("");
    setModalCreate(false);
    setModalDelete(false);
  }

  return (
    <React.Fragment>
      <GridContainer>
        {localStorage.getItem("@isAdmin") ? (
          <GridItem container>
            <GridItem>
              <Button color="success" onClick={() => setModalCreate(true)}>
                Nova categoria
              </Button>
            </GridItem>
            <GridItem>
              <Button color="danger" onClick={() => setModalDelete(true)}>
                Deletar categoria
              </Button>
            </GridItem>
          </GridItem>
        ) : null}

        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12}>
              {documents.length > 0 && (
                <List aria-labelledby="nested-list-subheader">
                  {[
                    {
                      data: documents,
                      title: "Ver todos",
                      id: "Ver todos",
                    },
                    ...groups,
                  ].map((category) => (
                    <React.Fragment key={category.id}>
                      <ListItem
                        button
                        onClick={() => {
                          if (isOpen === category.id) {
                            setIsOpen("");
                          } else {
                            setIsOpen(category.id);
                          }
                        }}
                      >
                        <ListItemText primary={category.title} />
                        {isOpen === category.id ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <CollapseCategory
                        categoryId={category.id}
                        isOpen={isOpen}
                        documents={category.data}
                      />
                    </React.Fragment>
                  ))}
                </List>
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>

      {/* Modal de criação de categoria */}
      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modalCreate}
        onClose={modalClose}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={modalClose}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>Criar nova categoria</h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          <GridItem xs={12}>
            <h4 style={{ color: "red" }}>{error}</h4>
          </GridItem>
          <GridItem xs={12}>
            <h4>
              Informe o nome da categoria que deseja criar (a categoria só irá
              aparecer nessa lista quando tiver algum documento associado a ela)
            </h4>
          </GridItem>
          <CustomInput
            labelText="Nome da categoria"
            id="name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: categoryName,
              onChange: (e) => setCategoryName(e.target.value),
            }}
          />
        </DialogContent>

        <DialogActions>
          <GridItem container justify="space-between">
            <GridItem>
              <Button
                round
                withProgress
                onClick={handleCreateCategory}
                color="success"
              >
                Criar
              </Button>
            </GridItem>
            <GridItem>
              <Button round onClick={modalClose} color="danger">
                Cancelar
              </Button>
            </GridItem>
          </GridItem>
        </DialogActions>
      </Dialog>

      {/* Modal de exclusão de categoria */}
      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modalDelete}
        onClose={modalClose}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={modalClose}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>
            Selecione a categoria que deseja excluir
          </h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          <h5>
            Para excluir uma categoria não pode haver nenhum documento associado
            a ela. Certifique-se de mover ou apagar os documentos da categoria
            desejada para que a operação seja bem sucedida.
          </h5>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="category" className={classes.selectLabel}>
              Categoria
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={deleteCategory}
              onChange={(e) => setDeleteCategory(e.target.value)}
              inputProps={{
                name: "category",
                id: "category",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Selecione a categoria do documento
              </MenuItem>
              {categories.map((c) => (
                <MenuItem
                  key={c.id}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={c}
                >
                  {c.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions
          className={
            modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
          }
        >
          <GridItem container justify="space-between">
            <GridItem>
              <Button
                round
                withProgress
                onClick={handleDeleteCategory}
                color="danger"
              >
                Deletar
              </Button>
            </GridItem>
            <GridItem>
              <Button round onClick={modalClose}>
                Cancelar
              </Button>
            </GridItem>
          </GridItem>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
