import React from "react";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import Item from "./Item";

// eslint-disable-next-line react/prop-types
function CollapseCategory({ categoryId, isOpen, documents }) {
  return (
    <Collapse in={isOpen === categoryId} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {/* eslint-disable-next-line react/prop-types */}
        {documents.map((document) => (
          <Item key={document.id} document={document} />
        ))}
      </List>
    </Collapse>
  );
}

export default CollapseCategory;
