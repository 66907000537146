export default {
  email: {
    presence: {
      message: "Informe seu email. ",
      allowEmpty: false,
    },
    email: {
      message: "Informe um email válido. ",
    },
  },
  password: {
    presence: {
      message: "Informe sua senha. ",
      allowEmpty: false,
    },
    length: {
      minimum: 6,
      message: "Sua senha precisa ter pelo menos 6 dígitos. ",
    },
  },
  confirmPassword: {
    equality: {
      attribute: "password",
      message: "As senhas não conferem. ",
    },
  },
  name: {
    presence: {
      message: "Informe seu nome. ",
      allowEmpty: false,
    },
    length: {
      minimum: 4,
      message: "Nome muito curto. ",
    },
  },
  personIdentifier: {
    presence: {
      message: "Esse campo é obrigatório. ",
      allowEmpty: false,
    },
    format: {
      pattern: /\d{3}\.\d{3}\.\d{3}-\d{2}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/,
      message: "Digite todos os números. ",
    },
  },
  zipCode: {
    presence: {
      message: "Informe o cep. ",
      allowEmpty: false,
    },
  },
  state: {
    presence: {
      message: "Informe o estado. ",
      allowEmpty: false,
    },
  },
  city: {
    presence: {
      message: "Informe a cidade. ",
      allowEmpty: false,
    },
  },
  neighborhood: {
    presence: {
      message: "Informe o bairro. ",
      allowEmpty: false,
    },
  },
  street: {
    presence: {
      message: "Informe a rua. ",
      allowEmpty: false,
    },
  },
  number: {
    presence: {
      message: "Informe o número. ",
      allowEmpty: false,
    },
  },
};
