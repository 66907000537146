export const ADMIN_TYPES = {
  1: "Master",
  2: "Suporte",
  3: "Técnico",
};

export const USER_PLANS = {
  0: "Gratuito",
  1: "Pago",
  2: "Pago",
};

export const CONTACT_CLIENTS = {
  true: "sim",
  false: "não",
};
