import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { primaryColor } from "assets/jss/material-dashboard-react";
const useStyles = makeStyles({
  title: {
    color: primaryColor[0],
    fontWeight: "bolder",
    marginLeft: "16px",
  },
  text: {
    textAlign: "justify",
  },
});

import {
  termsParag1,
  termsParag2,
  termsParag3,
} from "assets/jss/Texts/siteTexts.js";

export default function Terms() {
  const classes = useStyles();

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12}>
          <h3 className={classes.title}>Termos de uso</h3>
        </GridItem>
        <GridItem xs={12} sm={11}>
          <p className={classes.text}>{termsParag1}</p>
          <p className={classes.text}>{termsParag2}</p>
          <p className={classes.text}>{termsParag3}</p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
