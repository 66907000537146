import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import api from "services/api";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";
import stylesDash from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
const useStyles = makeStyles({
  ...styles,
  ...stylesSelect,
  ...stylesDash,
  buttonDiv: {
    margin: 8,
    display: "flex",
    justifyContent: "space-between",
  },
  fieldsMargin: {
    marginTop: "-20px",
  },
  displayContainer: {
    display: "flex",
    flexDirection: "row",
  },
  displayItem: {
    marginRight: "12px",
  },
  statusField: {
    marginTop: "12px",
  },
  adminManage: {
    paddingBottom: "12px",
  },
});
const useModalStyles = makeStyles(modalStyle);

DocEditor.propTypes = {
  initialContent: PropTypes.string,
};

function DocEditor({ initialContent = "" }) {
  const classes = useStyles();
  const modaClasses = useModalStyles();
  const editor = useRef();
  const history = useHistory();

  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [title, setTitle] = useState("Novo documento");
  const [status, setStatus] = useState("rascunho");
  const [company, setCompany] = useState("");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [deadline, setDeadline] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [availableFor, setAvailableFor] = useState("PUBLIC");
  const [nr, setNr] = useState("");
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAdmin] = useState(localStorage.getItem("@isAdmin"));

  useEffect(() => {
    async function loadCategories() {
      try {
        const response = await api.get("/docs/categories");

        setCategories(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    loadCategories();
  }, []);

  function descartDocument() {
    setTitle("Novo documento");
    setStatus("rascunho");
    setCompany("");
    setDate(moment(new Date()).format("YYYY-MM-DD"));
    setDeadline(moment(new Date()).format("YYYY-MM-DD"));
    setAvailableFor("PUBLIC");
    setNr("");
    setCategory("");
    editor.current.setContents("");
  }

  async function saveDocument() {
    try {
      setLoading(true);
      console.log("START");
      await api.post("/docs", {
        titulo: title,
        base: isAdmin ? true : false,
        nr: isAdmin ? undefined : nr,
        empresa: isAdmin ? undefined : company,
        tipo: isAdmin ? availableFor : undefined,
        status: status,
        prazo: isAdmin
          ? moment(new Date()).format("YYYY-MM-DD")
          : moment(deadline),
        id_categoria: isAdmin && category !== "" ? category : undefined,
        id_usuario: isAdmin ? undefined : localStorage.getItem("@userId"),
        data: isAdmin ? moment(new Date()).format("YYYY-MM-DD") : moment(date),
        texto: editor.current.getContents(true),
      });
      console.log("FINISH");
      history.push("/admin/");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} md={isAdmin ? 12 : 10}>
          <CustomInput
            labelText="Titulo do documento"
            id="title"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              value: title,
              onChange: (e) => setTitle(e.target.value),
            }}
          />
        </GridItem>
        {!isAdmin ? (
          <GridItem xs={12} md={2} extraClass={classes.statusField}>
            <FormControl className={classes.selectFormControl}>
              <InputLabel htmlFor="status" className={classes.selectLabel}>
                Status
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                inputProps={{
                  name: "status",
                  id: "status",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Selecione o status do documento
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="rascunho"
                >
                  Rascunho
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="finalizado"
                >
                  Finalizado
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="assinado"
                >
                  Assinado
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="vigente"
                >
                  Vigente
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="expirado"
                >
                  Expirado
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        ) : null}
        {!isAdmin ? (
          <GridItem xs={12}>
            <CustomInput
              labelText="Empresa"
              id="company"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: company,
                onChange: (e) => setCompany(e.target.value),
              }}
            />
          </GridItem>
        ) : null}
      </GridContainer>

      <GridContainer extraClass={classes.adminManage}>
        {!isAdmin ? (
          <React.Fragment>
            <GridItem xs={8} sm={6} md={4}>
              <div className={classes.displayContainer}>
                <h4 className={classes.displayItem}>Data:</h4>
                <div className={classes.fieldsMargin}>
                  <CustomInput
                    id="date"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "date",
                      value: date,
                      onChange: (e) => setDate(e.target.value),
                    }}
                  />
                </div>
              </div>
            </GridItem>
            <GridItem xs={8} sm={6} md={4}>
              <div className={classes.displayContainer}>
                <h4 className={classes.displayItem}>Prazo:</h4>
                <div className={classes.fieldsMargin}>
                  <CustomInput
                    id="date"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "date",
                      value: deadline,
                      onChange: (e) => setDeadline(e.target.value),
                    }}
                  />
                </div>
              </div>
            </GridItem>
            <GridItem xs={7} md={4}>
              <div className={classes.displayContainer}>
                <h4 className={classes.displayItem}>NR:</h4>
                <div className={classes.fieldsMargin}>
                  <CustomInput
                    id="nr"
                    formControlProps={{
                      fullWidth: false,
                    }}
                    inputProps={{
                      type: "text",
                      value: nr,
                      onChange: (e) => setNr(e.target.value),
                    }}
                  />
                </div>
              </div>
            </GridItem>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <GridItem container alignItems="center" xs={12} md={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="availableFor"
                  className={classes.selectLabel}
                >
                  Disponível para:
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={availableFor}
                  onChange={(e) => setAvailableFor(e.target.value)}
                  inputProps={{
                    name: "availableFor",
                    id: "availableFor",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Selecione a disponibilidade do documento
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="PUBLIC"
                  >
                    Plano gratuito
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="PRIVATE"
                  >
                    Plano premium
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem container alignItems="center" xs={12} md={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="category" className={classes.selectLabel}>
                  Categoria
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  inputProps={{
                    name: "category",
                    id: "category",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Selecione a categoria do documento
                  </MenuItem>
                  {categories.map((c) => (
                    <MenuItem
                      key={c.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={c.id}
                    >
                      {c.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </React.Fragment>
        )}
      </GridContainer>

      <SunEditor
        defaultValue={initialContent}
        lang="pt_br"
        name="document"
        getSunEditorInstance={(sunEditor) => (editor.current = sunEditor)}
        setOptions={{
          minHeight: "400px",
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize"],
            ["paragraphStyle", "blockquote"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["fontColor", "hiliteColor", "textStyle"],
            ["removeFormat"],
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link", "image", "video"],
            ["fullScreen"],
            ["preview", "print"],
          ],
        }}
      />

      <div className={classes.buttonDiv}>
        <Button color="success" round onClick={() => setPreviewModal(true)}>
          Salvar modelo
        </Button>
        <Button color="danger" round onClick={() => setModal(true)}>
          Descartar alterações
        </Button>
      </div>

      {/* Modal de exclusão */}
      <React.Fragment>
        <Dialog
          classes={{
            root: modaClasses.center,
            paper: modaClasses.modal,
          }}
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="modal-slide-title"
            disableTypography
            className={modaClasses.modalHeader}
          >
            <Button
              justIcon
              className={modaClasses.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setModal(false)}
            >
              <Close className={modaClasses.modalClose} />
            </Button>
            <h4 className={modaClasses.modalTitle}>Descartar alterações</h4>
          </DialogTitle>

          <DialogContent
            id="modal-slide-description"
            className={modaClasses.modalBody}
          >
            <h5>
              Deseja realmente descartar todas as alterações desse documento?
            </h5>
          </DialogContent>

          <DialogActions
            className={
              modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
            }
          >
            <Button onClick={() => setModal(false)} color="danger">
              Não
            </Button>

            <Button
              onClick={() => {
                setModal(false);
                descartDocument();
              }}
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>

      {/* modal de salvar */}
      <React.Fragment>
        <Dialog
          classes={{
            root: modaClasses.center,
            paper: modaClasses.modal,
          }}
          open={previewModal}
          onClose={() => setPreviewModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="modal-slide-title"
            disableTypography
            className={modaClasses.modalHeader}
          >
            <Button
              justIcon
              className={modaClasses.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setPreviewModal(false)}
            >
              <Close className={modaClasses.modalClose} />
            </Button>
            <h4 className={modaClasses.modalTitle}>Documento</h4>
          </DialogTitle>

          <DialogContent
            id="modal-slide-description"
            className={modaClasses.modalBody}
          >
            <h4>Deseja manter as alterações e salvar?</h4>
          </DialogContent>

          <DialogActions
            className={
              modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
            }
          >
            <Button
              loading={loading}
              withProgress
              color="success"
              onClick={saveDocument}
            >
              SALVAR EM MINHA BIBLIOTECA
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
}

export default DocEditor;
