import api from "services/api";

export const LOAD_NOTIFICATIONS = "NOTIFICATIONS_LOAD_NOTIFICATIONS";
export const SELECT_NOTIFICATION = "NOTIFICATIONS_SELECT_NOTIFICATION";
export const REMOVE_SELECTED_NOTIFICATION =
  "NOTIFICATIONS_REMOVE_SELECTED_NOTIFICATION";
export const REMOVE_NOTIFICATION = "NOTIFICATIONS_REMOVE_NOTIFICATION";
export const SET_ERROR = "NOTIFICATIONS_SET_ERROR";
export const SET_LOADING = "NOTIFICATIONS_SET_LOADING";

export function removeSelectedNotification() {
  return {
    type: REMOVE_SELECTED_NOTIFICATION,
  };
}

export function setError(error) {
  return {
    type: SET_ERROR,
    error,
  };
}

export function setLoading(loading) {
  return {
    type: SET_LOADING,
    loading,
  };
}

export function loadNotifications() {
  return async (dispatch) => {
    try {
      dispatch(setError(null));
      dispatch(setLoading(true));

      let userId = localStorage.getItem("@userId");
      const notificationsFromServer = (
        await api.get(`/users/${userId}/notifications`)
      ).data;

      dispatch({
        type: LOAD_NOTIFICATIONS,
        notifications: notificationsFromServer,
      });
    } catch (error) {
      dispatch(
        setError("Ocorreu um erro enquanto as notificações eram carregadas...")
      );
      console.log(error);
      console.log(error?.response?.data);
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function removeNotification(id) {
  return async (dispatch) => {
    try {
      dispatch(setError(null));
      dispatch(setLoading(true));

      await api.delete(`/notifications/${id}`);

      dispatch({
        type: REMOVE_NOTIFICATION,
        id,
      });
    } catch (error) {
      dispatch(
        setError("Ocorreu um erro enquanto tentavamos excluir a notificação...")
      );
      console.log(error);
      console.log(error?.response?.data);
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function selectNotification(id) {
  return async (dispatch) => {
    try {
      dispatch(setError(null));
      dispatch(setLoading(true));

      const notification = (await api.get(`/notifications/${id}`)).data;

      dispatch({
        type: SELECT_NOTIFICATION,
        notification,
      });
    } catch (error) {
      console.log(error);
      dispatch(
        setError(
          "Ocorreu um erro enquanto os dados da notificação eram carregados..."
        )
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
}
