import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import api from "services/api";
import { validation } from "utils/validate";
import adminRules from "./adminRules";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";
import stylesDash from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { dangerColor } from "assets/jss/material-dashboard-react";

const styles = {
  ...stylesSelect,
  ...stylesDash,
};

const useStyles = makeStyles(styles);

import { ADMIN_TYPES } from "utils/constants";

export default function EditAdmin() {
  const history = useHistory();
  const classes = useStyles();
  let { id: adminId } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [adminType, setAdminType] = useState(""); // verify existent types
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function loadAdmin() {
      try {
        setLoading(true);

        const adminDatas = (await api.get(`/admins/${adminId}`)).data;
        console.log(adminDatas);

        setName(adminDatas.nome);
        setEmail(adminDatas.email);
        setAdminType(adminDatas.tipo);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadAdmin();
  }, []);

  function isValid() {
    const result = validation(
      {
        email,
        name,
        password,
        confirmPassword,
      },
      {
        email: adminRules.email,
        name: adminRules.name,
        password: password ? adminRules.password : undefined,
        confirmPassword: adminRules.confirmPassword,
      }
    );

    setErrors(result ? result : {});
    return result == null;
  }

  async function handleUpdateAdmin() {
    if (isValid()) {
      try {
        setLoading(true);
        setErrors({});

        await api.put(`/admins/${adminId}`, {
          nome: name,
          email: email,
          senha: password ? password : undefined,
          tipo: adminType,
        });

        history.push("/admin/administrators");
      } catch (error) {
        console.log(error.response.data);
        setErrorMessage(error.response.data.error);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Editar Administrador</h4>
              <p className={classes.cardCategoryWhite}>
                Edição das informações do administrador {name}
              </p>
            </CardHeader>
            {errorMessage && (
              <p
                style={{
                  color: dangerColor[0],
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
              >
                {errorMessage}
              </p>
            )}

            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={errors.name}
                    errorText={errors.name}
                    labelText="Nome"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: name,
                      onChange: (e) => setName(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={errors.email}
                    errorText={errors.email}
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "email",
                      value: email,
                      onChange: (e) => setEmail(e.target.value),
                    }}
                  />
                </GridItem>

                {adminType !== 1 ? (
                  <GridItem container alignItems="center" xs={12} md={4} sm={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="admin-type"
                        className={classes.selectLabel}
                      >
                        Tipo de administrador
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={adminType}
                        onChange={(e) => setAdminType(e.target.value)}
                        inputProps={{
                          name: "adminType",
                          id: "admin-type",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                        >
                          Selecione o tipo de administrador
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="2"
                        >
                          Suporte
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="3"
                        >
                          Técnico
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                ) : (
                  <GridItem xs={12} md={4} sm={6}>
                    <CustomInput
                      labelText="Tipo de administrador"
                      id="adminType"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: true,
                        type: "text",
                        value: ADMIN_TYPES[adminType],
                      }}
                    />
                  </GridItem>
                )}

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    error={errors.password}
                    errorText={errors.password}
                    labelText="Nova Senha"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "password",
                      value: password,
                      onChange: (e) => setPassword(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    error={errors.confirmPassword}
                    errorText={errors.confirmPassword}
                    labelText="Confirmar Nova Senha"
                    id="confirm-password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "password",
                      value: confirmPassword,
                      onChange: (e) => setConfirmPassword(e.target.value),
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>

            <CardFooter>
              <Button
                withProgress
                loading={loading}
                disabled={loading}
                onClick={handleUpdateAdmin}
                color="primary"
              >
                Salvar Alterações
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
