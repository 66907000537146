import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Homepage/Header.js";
import Footer from "components/Homepage/Footer.js";

import styles from "assets/jss/material-dashboard-react/layouts/blogStyle";

import { blogRoutes } from "routes/routes.jsx";

const switchRoutes = (
  <Switch>
    {blogRoutes.map((prop, key) => {
      return (
        <Route
          exact
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    })}
    <Redirect from="/blog" to="/blog/posts" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function AuthLayout() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Header page="blog" />
      <div className={classes.content}>
        <div className={classes.container}>{switchRoutes}</div>
      </div>
      <Footer />
    </div>
  );
}
