import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import api from "services/api";
// libs
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import UploadImage from "components/CustomUpload/ImageUpload";
import AdminRestriction from "components/AccessWarning/AdminRestriction.js";
import { blockAccess } from "utils/validate.js";

import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";
import stylesDash from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { warningColor, dangerColor } from "assets/jss/material-dashboard-react";

const styles = {
  ...stylesSelect,
  ...stylesDash,
};

const useStyles = makeStyles(styles);

export default function NewPost() {
  const history = useHistory();
  const classes = useStyles();
  const fileRef = useRef();
  const editor = useRef();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  function isValid() {
    return fileRef.current?.value && title;
  }

  async function savePost() {
    if (isValid()) {
      try {
        setError("");
        setLoading(true);
        const formData = new FormData();
        formData.append("img", fileRef.current?.files[0]);

        const response = await api.post("/blog/images", formData);

        await api.post("/blog/posts", {
          titulo: title,
          id_capa: response.data.id,
          texto: editor.current.getContents(true),
        });

        history.push("/admin/blog");
      } catch (error) {
        console.log(error);
        console.log(error?.data);
        setError("Ocorreu um erro enquanto a postagem estava sendo salva...");
      } finally {
        setLoading(false);
      }
    } else {
      setError("Escolha uma capa e um titulo para a postagem...");
    }
  }

  return blockAccess(3) ? (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Nova postagem</h4>
            <p className={classes.cardCategoryWhite}>
              Crie uma nova postagem para o blog
            </p>
          </CardHeader>

          <CardBody>
            {error && (
              <p
                style={{
                  color: dangerColor[0],
                  fontWeight: "bold",
                  margin: 0,
                  padding: 0,
                }}
              >
                {error}
              </p>
            )}

            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  labelText="Título da postagem"
                  id="title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: title,
                    onChange: (e) => setTitle(e.target.value),
                  }}
                />
              </GridItem>

              <GridItem style={{ zIndex: 1 }} xs={12}>
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: -6,
                    padding: 0,
                    fontWeight: "bold",
                    color: warningColor[1],
                  }}
                >
                  Imagem para a capa
                </p>
              </GridItem>
              <UploadImage ref={fileRef} />

              <GridItem xs={12}>
                <SunEditor
                  lang="pt_br"
                  name="document"
                  getSunEditorInstance={(sunEditor) =>
                    (editor.current = sunEditor)
                  }
                  setOptions={{
                    minHeight: "400px",
                    buttonList: [
                      ["undo", "redo"],
                      ["font", "fontSize"],
                      ["paragraphStyle", "blockquote"],
                      [
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                      ],
                      ["fontColor", "hiliteColor", "textStyle"],
                      ["removeFormat"],
                      ["outdent", "indent"],
                      ["align", "horizontalRule", "list", "lineHeight"],
                      ["table", "link", "image", "video"],
                      ["fullScreen"],
                      ["preview", "print"],
                    ],
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>

          <CardFooter>
            <Button
              disabled={loading}
              loading={loading}
              onClick={savePost}
              withProgress
              color="primary"
            >
              Criar postagem
            </Button>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  ) : (
    <AdminRestriction />
  );
}
