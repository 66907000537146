import {
  LOAD_NOTIFICATIONS,
  SELECT_NOTIFICATION,
  SET_ERROR,
  SET_LOADING,
  REMOVE_NOTIFICATION,
  REMOVE_SELECTED_NOTIFICATION,
} from "../actions/notifications";

const INITIAL_STATE = {
  error: null,
  loading: false,
  selected: {},
  all: [],
};

const notifications = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_NOTIFICATIONS:
      return {
        ...state,
        all: action.notifications,
      };

    case SELECT_NOTIFICATION:
      return {
        ...state,
        selected: action.notification,
      };

    case REMOVE_SELECTED_NOTIFICATION:
      return {
        ...state,
        selected: {},
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        all: state.all.filter((notification) => notification.id !== action.id),
      };

    default:
      return state;
  }
};

export default notifications;
