import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setForm } from "store/actions/signUpForm";
import PropTypes from "prop-types";
import cep from "cep-promise";
import { validation } from "utils/validate";
import signUpRules from "../signUpRules";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import { maskCEP } from "utils/masks";

export default function AddressForm({ previousStep, nextStep }) {
  const dispatch = useDispatch();
  const { address } = useSelector((state) => state.signUpForm);
  const [zipCode, setZipCode] = useState(address.zipCode);
  const [state, setState] = useState(address.state);
  const [city, setCity] = useState(address.city);
  const [neighborhood, setNeighborhood] = useState(address.neighborhood);
  const [street, setStreet] = useState(address.street);
  const [number, setNumber] = useState(address.number);
  const [complement, setComplement] = useState(address.complement);
  const [erros, setErros] = useState({});

  function isValid() {
    const result = validation(
      {
        zipCode,
        state,
        city,
        street,
        neighborhood,
        number,
      },
      {
        zipCode: signUpRules.zipCode,
        state: signUpRules.state,
        city: signUpRules.city,
        street: signUpRules.street,
        neighborhood: signUpRules.neighborhood,
        number: signUpRules.number,
      }
    );

    setErros(result ? result : {});
    return result == null;
  }

  function handleNextStep() {
    if (isValid()) {
      dispatch(
        setForm({
          address: {
            zipCode,
            state,
            city,
            neighborhood,
            street,
            number,
            complement,
          },
        })
      );

      nextStep();
    }
  }

  async function changeZipCode(newZipCode) {
    setZipCode(maskCEP(newZipCode));

    newZipCode = newZipCode.replace(/\D/g, "");
    if (newZipCode.length >= 5) {
      try {
        const zipCodeInfos = await cep(newZipCode);

        setState((current) => zipCodeInfos.state || current);
        setCity((current) => zipCodeInfos.city || current);
        setStreet((current) => zipCodeInfos.street || current);
        setNeighborhood((current) => zipCodeInfos.neighborhood || current);
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            error={erros.zipCode}
            errorText={erros.zipCode}
            labelText="CEP"
            id="zipCode"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              maxLength: 10,
              type: "text",
              value: zipCode,
              onChange: async (e) => await changeZipCode(e.target.value),
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            error={erros.state}
            errorText={erros.state}
            labelText="Estado"
            id="state"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              value: state,
              onChange: (e) => setState(e.target.value),
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            error={erros.city}
            errorText={erros.city}
            labelText="Cidade"
            id="city"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              value: city,
              onChange: (e) => setCity(e.target.value),
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            error={erros.neighborhood}
            errorText={erros.neighborhood}
            labelText="Bairro"
            id="neighborhood"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              value: neighborhood,
              onChange: (e) => setNeighborhood(e.target.value),
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={8} md={8}>
          <CustomInput
            error={erros.street}
            errorText={erros.street}
            labelText="Rua"
            id="street"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              value: street,
              onChange: (e) => setStreet(e.target.value),
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={4} md={4}>
          <CustomInput
            error={erros.number}
            errorText={erros.number}
            labelText="Número"
            id="neighborhood"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              value: number,
              onChange: (e) => setNumber(e.target.value),
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Complemento"
            id="complement"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              value: complement,
              onChange: (e) => setComplement(e.target.value),
            }}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem container xs={6} justify="center">
          <Button round color="primary" onClick={previousStep}>
            Voltar
          </Button>
        </GridItem>

        <GridItem container xs={6} justify="center">
          <Button round color="primary" onClick={handleNextStep}>
            Continuar
          </Button>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}

AddressForm.propTypes = {
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
};
