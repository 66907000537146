import React, { useState, useEffect } from "react";
import api from "services/api";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { blockAccess } from "utils/validate.js";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import GridItem from "components/Grid/GridItem.js";
import NotificationCard from "components/Notification/NotificationCard.js";
import AdminRestriction from "components/AccessWarning/AdminRestriction.js";
import PermissionsGate from "components/PermissionsGate";

import { primaryColor } from "assets/jss/material-dashboard-react";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles({
  ...styles,
  justifyCenter: {
    alignItems: "center",
  },
});

export default function ListNotifications() {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [datas, setDatas] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  async function getDatas() {
    try {
      setError(null);
      setLoading(true);

      let userId = localStorage.getItem("@userId");
      const response = await api.get(`/users/${userId}/notifications`);
      setDatas(response.data);
      if (response.data.length == 0) {
        setError("Sem notificações");
      }
    } catch (error) {
      setError("Ocorreu um erro enquanto as notificações eram carregadas...");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => getDatas(), []);

  function notificationList() {
    return (
      <GridItem xs={12}>
        <Table aria-label="tabela de notificações">
          <TableBody>
            {datas.map((prop, key) => {
              return (
                <TableRow key={key}>
                  <TableCell component="th" scope="row" key={key}>
                    <NotificationCard
                      id={prop.id}
                      title={prop.titulo}
                      category={prop.categoria}
                      description={prop.descricao}
                      link={prop.link}
                      date={prop.data}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </GridItem>
    );
  }

  return (
    <PermissionsGate>
      {blockAccess(2) ? (
        <Card plain>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Minhas Notificações</h4>
            <p className={classes.cardCategoryWhite}>
              Lista com todas as notificações
            </p>
          </CardHeader>

          <CardBody>
            {!loading ? (
              error ? (
                <h3>{error}</h3>
              ) : (
                <React.Fragment>{notificationList()}</React.Fragment>
              )
            ) : (
              <GridItem>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 100,
                    color: primaryColor[0],
                  }}
                >
                  <CircularProgress />
                </div>
              </GridItem>
            )}
          </CardBody>
        </Card>
      ) : (
        <AdminRestriction />
      )}
    </PermissionsGate>
  );
}
