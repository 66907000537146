import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import image from "assets/img/homepage/bannerPlanosEPrecos.jpg";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
// @material-ui/core icons
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
// core components
import Header from "components/Homepage/Header.js";
import Footer from "components/Homepage/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import NewsletterFormDialog from "components/Newsletter/NewsletterFormDialog";

import { primaryColor } from "assets/jss/material-dashboard-react";
import {
  benefitsTableStyle,
  homepageStyle,
  planStyle,
} from "assets/jss/material-dashboard-react/components/homepageStyle.js";
const useStyles = makeStyles((theme) => ({
  ...benefitsTableStyle,
  ...homepageStyle,
  ...planStyle(theme, image),
  switchDiv: {
    alignItems: "center",
    paddingTop: "8px",
  },
  switchLabel: {
    margin: "0px",
    padding: "0px",
    fontSize: "13px",
  },
  switchLabelSelect: {
    margin: "0px",
    padding: "0px",
    fontSize: "14px",
    fontWeight: "bold",
  },
}));

import {
  planoSubtitulo,
  planoInicialTexto,
  planoProTexto,
  precoPro1,
  precoPro2,
} from "assets/jss/Texts/siteTexts";

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: primaryColor[4],
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: primaryColor[4],
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function Planos() {
  const history = useHistory();
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [anualPlan, setAnualPlan] = useState(true);

  function benefitsTable() {
    return (
      <GridItem xs={12} md={8}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Compare as vantagens de cada plano
            </h4>
          </CardHeader>
          <CardBody>
            <div className={classes.tableUpgradeWrapper}>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th />
                    <th className={classes.center}>Inicial</th>
                    <th className={classes.center}>PRO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Envio de eventos ao eSocial</td>
                    <td className={classes.center}>S2210</td>
                    <td className={classes.center}>S2210, S2220, S2240</td>
                  </tr>
                  <tr>
                    <td>Criar documentos</td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                  </tr>
                  <tr>
                    <td>Gestão de documentos criados</td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                  </tr>
                  <tr>
                    <td>Acesso a todos os modelos de documentos</td>
                    <td className={classes.center}>
                      <Danger>
                        <Close />
                      </Danger>
                    </td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                  </tr>
                  <tr>
                    <td>Gestão de planos de ação em kanban</td>
                    <td className={classes.center}>
                      <Danger>
                        <Close />
                      </Danger>
                    </td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Notificações de atualizações em normas e notícias
                      relevantes
                    </td>
                    <td className={classes.center}>
                      <Danger>
                        <Close />
                      </Danger>
                    </td>
                    <td className={classes.center}>Em breve</td>
                  </tr>
                  <tr>
                    <td>Suporte em até 24h no uso da plataforma</td>
                    <td className={classes.center}>
                      <Danger>
                        <Close />
                      </Danger>
                    </td>
                    <td className={classes.center}>
                      <Success>
                        <Check />
                      </Success>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td className={classes.center}>Grátis</td>
                    <td className={classes.center}>
                      {/* R$ {anualPlan ? precoPro1 : precoPro2} */}
                      {anualPlan ? precoPro1 : precoPro2}
                    </td>
                  </tr>
                </tbody>
              </table>
              <GridItem xs={12} extraClass={classes.center}>
                <Button
                  color="primary"
                  onClick={() => {
                    // history.push(`/auth/sign-up`);
                    history.push(`/auth/em-breve`);
                  }}
                >
                  Faça seu cadastro
                </Button>
              </GridItem>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    );
  }

  return (
    <div>
      <Header page="planos" />
      <GridContainer>
        <GridItem container extraClass={classes.banner}>
          <GridItem xs={12} sm={6} extraClass={classes.bannerTitle}></GridItem>
          <GridItem container xs={12} sm={6}>
            <GridItem xs={12} extraClass={classes.bannerSubtitle}>
              {planoSubtitulo}
            </GridItem>
            <GridItem xs={12}>
              <Button
                simple
                className={`${classes.bannerButton} ${classes.transperentButton}`}
                onClick={() => history.push(`/auth/contato`)}
              >
                Entrar em contato
              </Button>
            </GridItem>
          </GridItem>
        </GridItem>
        <GridItem container justify="center">
          <GridItem container justify="center" extraClass={classes.cardInicial}>
            <GridItem xs={12}>
              <h2 className={classes.inicialTitle}>Inicial</h2>
            </GridItem>
            <GridItem xs={12}>
              <h3>Gratuito</h3>
            </GridItem>
            <GridItem xs={12}>{planoInicialTexto}</GridItem>
            <GridItem>
              <NewsletterFormDialog color="primary" />
            </GridItem>
          </GridItem>
          <GridItem container justify="center" extraClass={classes.cardPro}>
            <GridContainer justify="center" extraClass={classes.switchDiv}>
              <GridItem>
                <p
                  className={
                    anualPlan ? classes.switchLabel : classes.switchLabelSelect
                  }
                >
                  Plano semestral
                </p>
              </GridItem>
              <GridItem>
                <CustomSwitch
                  checked={anualPlan}
                  onChange={() => setAnualPlan(!anualPlan)}
                  name="planChange"
                />
              </GridItem>
              <GridItem>
                <p
                  className={
                    anualPlan ? classes.switchLabelSelect : classes.switchLabel
                  }
                >
                  Plano anual
                </p>
              </GridItem>
            </GridContainer>
            <GridItem className={classes.proTitle} xs={12}>
              <h2 className={classes.inicialPro}>Plano Pro</h2>
            </GridItem>
            <GridItem xs={12}>
              <h3>
                {/* R$ {anualPlan ? precoPro1 : precoPro2} */}
                {anualPlan ? precoPro1 : precoPro2}
              </h3>
              {/* /{anualPlan ? "ano" : "semestre"} */}
            </GridItem>
            <GridItem xs={12}>{planoProTexto}</GridItem>
            <GridItem>
              <NewsletterFormDialog
                color="white"
                className={classes.proButton}
              />
            </GridItem>
          </GridItem>
        </GridItem>
        <GridItem container justify="center">
          {benefitsTable()}
        </GridItem>
      </GridContainer>
      <Footer />
    </div>
  );
}
