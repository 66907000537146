import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import api from "services/api";
import { useSelector } from "react-redux";

import Comment from "../Comment";

import { blackColor, grayColor } from "assets/jss/material-dashboard-react";

const useStyles = makeStyles({
  title: {
    color: blackColor,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  newComment: {
    maxWidth: "100%",
    minWidth: "100%",
  },
  commentLabel: {
    color: grayColor[1],
  },
  commentButton: {
    width: 150,
    padding: "4px 0px",
    margin: "10px 0px",
  },
  commentList: {
    padding: 0,
  },
  commentItem: {
    listStyle: "none",
  },
});

function Comments() {
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const classes = useStyles();
  const { id: postId } = useParams();
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  async function loadComments() {
    try {
      setLoading(true);
      const commentsFromServer = await api.get(
        `/blog/posts/${postId}/comments`
      );

      setComments(commentsFromServer.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadComments();
  }, [postId]);

  async function saveComment(e) {
    e.preventDefault();

    if (comment) {
      setLoading(true);
      try {
        await api.post(`/blog/posts/${postId}/comments`, {
          comentario: comment,
          id_usuario: user.userProfile?.id,
        });

        await loadComments();
      } catch (error) {
        console.log(error);
        console.log(error.response);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <section>
      <h3 className={classes.title}>Comentários</h3>

      {auth.signed && (
        <form action="" className={classes.form}>
          <label className={classes.commentLabel} htmlFor="comment">
            Comentar
          </label>
          <textarea
            onChange={(e) => setComment(e.target.value)}
            id="comment"
            className={classes.newComment}
            rows="2"
          >
            {comment}
          </textarea>

          <button
            disabled={loading}
            className={classes.commentButton}
            onClick={saveComment}
          >
            Postar comentário
          </button>
        </form>
      )}

      <ul className={classes.commentList}>
        {comments.map((commentary) => (
          <li className={classes.commentItem} key={commentary.id}>
            <Comment commentary={commentary} />
          </li>
        ))}
      </ul>
    </section>
  );
}

export default Comments;
