import React, { useState, Fragment } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { toggleModal } from "store/actions/auth";
// import moment from "moment";
import { useHistory } from "react-router-dom";
import { selectNotification } from "store/actions/notifications";
import { useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Notifications from "@material-ui/icons/Notifications";
// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { all: notifications } = useSelector((state) => state.notifications);
  const [openNotification, setOpenNotification] = useState(null);
  const [openProfile, setOpenProfile] = useState(null);

  const handleClickNotificationIcon = () => {
    history.push(`/admin/notifications`);
  };

  const handleOpenNotification = (id) => {
    dispatch(selectNotification(id));
    setOpenNotification(null);
    history.push(`/admin/notifications`);
  };

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleLogout = () => {
    dispatch(toggleModal());
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  return (
    <Fragment>
      <div>
        {notifications.length > 0 && (
          <div className={classes.manager}>
            <Button
              color={window.innerWidth > 959 ? "transparent" : "white"}
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-owns={
                openNotification ? "notification-menu-list-grow" : null
              }
              aria-haspopup="true"
              onClick={handleClickNotificationIcon}
              className={classes.buttonLink}
            >
              <Notifications className={classes.icons} />
              <span className={classes.notifications}>
                {notifications.length}
              </span>
              <Hidden mdUp implementation="css">
                <p
                  onClick={() => setOpenNotification(null)}
                  className={classes.linkText}
                >
                  notificações
                </p>
              </Hidden>
            </Button>
            <Poppers
              open={Boolean(openNotification)}
              anchorEl={openNotification}
              transition
              disablePortal
              className={
                classNames({ [classes.popperClose]: !openNotification }) +
                " " +
                classes.popperNav
              }
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="notification-menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={() => setOpenNotification(null)}
                    >
                      <MenuList role="menu">
                        {notifications.map((notification) => (
                          <MenuItem
                            key={toString(notification.id)}
                            onClick={() =>
                              handleOpenNotification(notification.id)
                            }
                            className={classes.dropdownItem}
                          >
                            {notification.titulo}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Poppers>
          </div>
        )}
        <div className={classes.manager}>
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openProfile ? "profile-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={handleClickProfile}
            className={classes.buttonLink}
          >
            <ExitToAppIcon className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Logout</p>
            </Hidden>
          </Button>
          <Poppers
            open={Boolean(openProfile)}
            anchorEl={openProfile}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openProfile }) +
              " " +
              classes.popperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseProfile}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={handleLogout}
                        className={classes.dropdownItem}
                      >
                        Sair da conta
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
      </div>
    </Fragment>
  );
}
