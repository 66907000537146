import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
// @material-ui/core
import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import { Visibility, VisibilityOff } from "@material-ui/icons";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import BlockWarning from "components/LoginDialogs/BlockWarning.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles({
  ...styles,
  textCenter: {
    textAlign: "center",
  },
  error: {
    color: "red",
    fontSize: 16,
    fontWeight: "bolder",
  },
  inputLabelEmail: {
    fontSize: 17,
    marginLeft: "-48px",
  },
  inputLabelPassword: {
    fontSize: 17,
  },
  forgetPassword: {
    paddingTop: "8px",
    marginBottom: "40px",
    fontWeight: "bolder",
  },
});

function LoginForm({ callLogin, empty }) {
  const classes = useStyles();
  const history = useHistory();
  const { loading, error } = useSelector((state) => state.auth);
  const [details, setDetails] = useState({ email: "", password: "" });
  const [passwordShow, setPasswordShow] = useState(false);

  const [isBlocked] = useState(false);
  const [modalBlock, setModalBlock] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    if (!isBlocked) {
      callLogin(details);
    } else {
      setModalBlock(true);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordShow(!passwordShow);
  };

  return (
    <React.Fragment>
      <GridContainer justify="center" extraClass={classes.textCenter}>
        <form onSubmit={submitHandler}>
          {empty || error ? (
            <GridItem extraClass={classes.error}>{empty || error}</GridItem>
          ) : (
            ""
          )}
          <GridItem container justify="center" extraClass={classes.inputField}>
            <GridItem xs={12}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                }}
                id="email"
                inputProps={{
                  type: "email",
                  onChange: (e) =>
                    setDetails({ ...details, email: e.target.value }),
                  startAdornment: (
                    <InputAdornment position="start">E-mail:</InputAdornment>
                  ),
                }}
              />
            </GridItem>
          </GridItem>
          <GridItem container justify="center" extraClass={classes.inputField}>
            <GridItem xs={12}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                }}
                id="password"
                inputProps={{
                  type: passwordShow ? "text" : "password",
                  onChange: (e) =>
                    setDetails({ ...details, password: e.target.value }),
                  startAdornment: (
                    <InputAdornment position="start">Senha:</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility}>
                        {passwordShow ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </GridItem>
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.forgetPassword}>
              <Button
                onClick={() => history.push("/auth/reset-password")}
                simple
                color="primary"
              >
                Esqueci minha senha!
              </Button>
            </div>
          </GridItem>
          <GridItem xs={12}>
            <Button disabled={loading} type="submit" color="primary" round>
              Entrar
            </Button>
          </GridItem>
        </form>
      </GridContainer>
      <BlockWarning modalOpen={modalBlock} setModalOpen={setModalBlock} />
    </React.Fragment>
  );
}

LoginForm.propTypes = {
  callLogin: PropTypes.func,
  empty: PropTypes.string,
};

export default LoginForm;
