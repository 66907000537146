import React, { useState, useEffect } from "react";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import api from "services/api";
import { validation } from "utils/validate";
import notificationRules from "./notificationRules";
import { useSelector, useDispatch } from "react-redux";
import {
  removeSelectedNotification,
  selectNotification,
  setError,
  setLoading,
} from "store/actions/notifications";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import stylesDash from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { dangerColor } from "assets/jss/material-dashboard-react";

const useStyles = makeStyles(stylesDash);

export default function EditNotification() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { error, loading, selected } = useSelector(
    (state) => state.notifications
  );
  const { id: notificationId } = useParams();

  const [title, setTitle] = useState(selected.titulo);
  const [description, setDescription] = useState(selected.descricao);
  const [category, setCategory] = useState(selected.nr);
  const [date, setDate] = useState(moment(selected.data).format("YYYY-MM-DD"));
  const [link, setLink] = useState(selected.link);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(selectNotification(notificationId));

    return () => dispatch(removeSelectedNotification());
  }, []);

  useEffect(() => {
    setTitle(selected.titulo);
    setDescription(selected.descricao);
    setCategory(selected.categoria);
    setDate(moment(selected.data).format("YYYY-MM-DD"));
    setLink(selected.link);
  }, [selected]);

  function isValid() {
    const result = validation(
      {
        description,
        title,
      },
      {
        description: notificationRules.description,
        title: notificationRules.title,
      }
    );

    setErrors(result ? result : {});
    return result == null;
  }

  async function handleUpdateNotification() {
    if (isValid()) {
      try {
        dispatch(setLoading(true));
        setErrors({});

        await api.put(`/notifications/${notificationId}`, {
          titulo: title,
          categoria: category,
          descricao: description,
          link,
          data: moment(date),
        });

        history.push("/admin/notifications");
      } catch (error) {
        console.log(error.response?.data);
        dispatch(setError(error.response?.data?.error));
      } finally {
        dispatch(setLoading(false));
      }
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Editar Notificação</h4>
              <p className={classes.cardCategoryWhite}>
                Edição das informações da notificação {title}
              </p>
            </CardHeader>
            {error && (
              <p
                style={{
                  color: dangerColor[0],
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
              >
                {error}
              </p>
            )}

            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <CustomInput
                    error={errors.title}
                    errorText={errors.title}
                    labelText="Titulo"
                    id="title"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: title,
                      onChange: (e) => setTitle(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12}>
                  <CustomInput
                    error={errors.description}
                    errorText={errors.description}
                    labelText="Descrição"
                    id="description"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: description,
                      onChange: (e) => setDescription(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={8} sm={10}>
                  <CustomInput
                    labelText="Link"
                    id="link"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: link,
                      onChange: (e) => setLink(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={4} sm={2}>
                  <CustomInput
                    labelText="Categoria"
                    id="category"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: category,
                      onChange: (e) => setCategory(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem container alignItems="center" xs={12} md={6}>
                  <CustomInput
                    labelText="Data de nascimento"
                    id="birth-date"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "date",
                      value: date,
                      onChange: (e) => setDate(e.target.value),
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>

            <CardFooter>
              <Button
                withProgress
                loading={loading}
                disabled={loading}
                onClick={handleUpdateNotification}
                color="primary"
              >
                Salvar Alterações
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
