/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import api from "services/api";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import PostCard from "components/PostCard";

import { blackColor, primaryColor } from "assets/jss/material-dashboard-react";

const useStyles = makeStyles((theme) => ({
  title: {
    color: blackColor,
    fontSize: 20,
    marginTop: 40,
  },
  posts: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: 0,
  },
  postItem: {
    margin: 10,
    listStyle: "none",
    [theme.breakpoints.down("xs")]: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
    },
  },
  transperentButton: {
    margin: "32px 0px",
    border: "2px solid",
    color: primaryColor[0],
    "&:hover": {
      color: primaryColor[1],
      fontWeight: "bolder",
    },
  },
}));

function OtherPosts() {
  const classes = useStyles();
  const history = useHistory();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function loadPosts() {
      try {
        const response = await api.get("/blog/posts?page=1&size=4");

        setPosts(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }

    loadPosts();
  }, []);

  return (
    <GridContainer justify="center">
      <GridItem xs={12}>
        <h3 className={classes.title}>Veja outras postagens...</h3>
      </GridItem>
      <ul className={classes.posts}>
        {posts.map((post) => (
          <li key={post.id} className={classes.postItem}>
            <PostCard withoutText post={post} />
          </li>
        ))}
      </ul>

      <GridItem container justify="center">
        <GridItem>
          <Button
            simple
            className={classes.transperentButton}
            onClick={() => {
              history.push(`/blog/posts`);
            }}
          >
            Ver todas as postagens
          </Button>
        </GridItem>
      </GridItem>
    </GridContainer>
  );
}

export default OtherPosts;
