import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import api from "services/api";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CancelButton from "components/CancelSignature/CancelSignature.js";

import stylesDash from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {
  dangerColor,
  warningColor,
  footerColor,
} from "assets/jss/material-dashboard-react";
import { maskMoney } from "utils/masks";
import moment from "moment";
import WarningOutlined from "@material-ui/icons/WarningOutlined";

const styles = {
  ...stylesDash,
  span: {
    fontWeight: "bold",
  },
  chargesContainer: {
    maxHeight: 300,
  },
  charge: {
    border: "1px solid #888888",
    padding: 8,
    marginBottom: 10,
  },
  cardPadding: {
    padding: "32px",
  },
  icon: {
    fontSize: "64px",
    color: warningColor[0],
  },
};

const useStyles = makeStyles(styles);

export default function MySignature() {
  const history = useHistory();
  const classes = useStyles();
  const { userProfile } = useSelector((state) => state.user);

  const [hasCharge, setHasCharge] = useState(false);
  const [charge, setCharge] = useState([]);
  const [plan, setPlan] = useState(null);
  const [planValue, setPlanValue] = useState("");

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function loadPlan() {
      try {
        const planFromServer = (await api.get(`/plans/${userProfile.planId}`))
          .data;

        const stringValue = `${planFromServer.valor}`;
        const characters = stringValue.split("");
        const indexOfDot = characters.findIndex((elem) => elem === ".");
        const zeros =
          2 - (characters.slice(indexOfDot).length - 1) > 0
            ? 2 - (characters.slice(indexOfDot).length - 1) > 1
              ? "00"
              : "0"
            : "";
        const planValue = maskMoney(`${stringValue.replace(".", "")}${zeros}`);
        setPlanValue(planValue);

        setPlan(planFromServer);
      } catch (error) {
        setErrorMessage("Ocorreu um erro enquanto o plano era carregado...");
        console.log(error);
      }
    }

    async function loadCharges() {
      const chargesFromServer = [];
      setLoading(true);

      for (const chargeId of userProfile.wallet?.charges) {
        try {
          const charge = (
            await api.get(`/payment/plans/${chargeId}?noExtraDatas=true`)
          ).data;

          chargesFromServer.push(charge);
        } catch (error) {
          console.log(error);
        }
      }

      setCharge(chargesFromServer[0]);
      setLoading(false);
    }

    if (userProfile.wallet?.charges) {
      setHasCharge(true);
      loadCharges();
    } else {
      setHasCharge(false);
    }

    if (userProfile.planId !== 0) {
      loadPlan();
    }
  }, [userProfile]);

  if (!hasCharge) {
    return (
      <GridContainer extraClass={classes.cardPadding}>
        <Card className={classes.cardPadding}>
          <CardBody>
            <GridItem>
              <WarningOutlined className={classes.icon} />
            </GridItem>
            <GridItem>
              <h3>Nenhum plano aderido</h3>
            </GridItem>
            <GridItem>
              <h4>
                Você ainda não possui uma conta premium. Primeiro você deve
                escolher o seu plano e realizar o pagamento para liberar as
                funcionalidades premium.
              </h4>
            </GridItem>
          </CardBody>

          <CardFooter>
            <Button
              withProgress
              onClick={() => history.push("/admin/make-signature/")}
              color="primary"
            >
              Escolher um plano
            </Button>
          </CardFooter>
        </Card>
      </GridContainer>
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Minha Assinatura</h4>
            <p className={classes.cardCategoryWhite}>
              Veja todas as informações da sua assinatura atual
            </p>
          </CardHeader>
          {errorMessage && (
            <p
              style={{
                color: dangerColor[0],
                fontWeight: "bold",
                marginLeft: 10,
              }}
            >
              {errorMessage}
            </p>
          )}

          <CardBody>
            <GridContainer>
              {userProfile.paymentStatus !== "PAID" && (
                <GridItem xs={12}>
                  <p
                    style={{
                      color: dangerColor[0],
                      fontWeight: "bold",
                    }}
                  >
                    O seu pagamento ainda não foi confirmado. O acesso premium
                    será liberado após a confirmação do pagamento. Você pode
                    recarregar a página para atualizar os dados caso já tenha
                    realizado o pagamento.
                  </p>
                </GridItem>
              )}

              {userProfile.paymentStatus === "PAID" && (
                <GridItem xs={12}>
                  <p
                    style={{
                      color: footerColor,
                      fontWeight: "bold",
                    }}
                  >
                    {userProfile.monthsLeft >= 1
                      ? `O seu plano já está ativo e você ainda pode aproveitar todos os benefícios dele por ${userProfile.monthsLeft} meses.`
                      : "A validade do seu plano acabou. Renove a sua assinatura no mesmo plano ou em um plano diferente para continuar usando todas as nossas funcionalidades."}
                  </p>
                </GridItem>
              )}

              <GridItem xs={12} md={6}>
                <h2>Plano</h2>
                {plan && (
                  <>
                    <p>
                      <span className={classes.span}>Descrição: </span>
                      {plan.descricao}
                    </p>

                    <p>
                      <span className={classes.span}>Valor: </span> R${" "}
                      {planValue}
                    </p>

                    <p>
                      <span className={classes.span}>Máximo de parcelas: </span>
                      {plan.quantidade}
                    </p>

                    <p>
                      <span className={classes.span}>
                        Meses de acesso liberados:{" "}
                      </span>
                      {plan.months != undefined ? plan.months : 0}
                    </p>
                  </>
                )}

                {userProfile.planId === 0 && (
                  <p>
                    <span className={classes.span}>
                      Atualmente você possui o plano gratuito.
                    </span>
                  </p>
                )}
              </GridItem>

              <GridItem xs={12} md={6}>
                <h2>Cobrança</h2>
                <div className={classes.chargesContainer}>
                  {loading && <CircularProgress size={16} />}
                  <div className={classes.charge} key={charge.id}>
                    <p>
                      <span className={classes.span}>Código: </span>
                      {charge.code}
                    </p>

                    <p>
                      <span className={classes.span}>Link da cobrança: </span>
                      <a
                        href={charge.checkoutUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {charge.checkoutUrl}
                      </a>
                    </p>

                    <p>
                      <span className={classes.span}>Status: </span>
                      {charge.status === "PAID" ? "Pago" : "Pendente"}
                    </p>

                    <p>
                      <span className={classes.span}>Data de vencimento: </span>
                      {moment(charge.dueDate).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              </GridItem>

              {charge.status === "PAID" && userProfile.planId !== 0 ? (
                <GridItem>
                  <CancelButton id={userProfile.id} />
                </GridItem>
              ) : null}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
