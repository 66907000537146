import React from "react";
// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import UsersList from "./UsersList.js";
import NewsletterList from "./NewsletterList.js";

export default function Users() {
  return (
    <CustomTabs
      headerColor="primary"
      tabs={[
        {
          tabName: "Usuários cadastrados",
          tabContent: <UsersList />,
        },
        {
          tabName: "Newsletter",
          tabContent: <NewsletterList />,
        },
      ]}
    />
  );
}
