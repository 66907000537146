import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";

// styles
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const {
    tableHead,
    tableData,
    tableHeaderColor,
    tablePagination,
    tablePaginationInfo,
    loading,
  } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {!loading ? (
          <React.Fragment>
            {tableHead !== undefined ? (
              <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                <TableRow className={classes.tableHeadRow}>
                  {tableHead.map((prop, key) => {
                    return (
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                        key={key}
                      >
                        {prop}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {tableData.map((prop, key) => {
                return (
                  <TableRow key={key} className={classes.tableBodyRow}>
                    {prop.map((prop, key) => {
                      return (
                        <TableCell className={classes.tableCell} key={key}>
                          {prop}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            {tablePagination ? (
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={
                    tablePaginationInfo.rowsPerPageOptions || [5, 10, 25]
                  }
                  count={tablePaginationInfo.total}
                  labelDisplayedRows={({ from, to, count }) =>
                    !tablePaginationInfo.paginationControlled
                      ? `${from}-${to} de ${count}`
                      : ""
                  }
                  labelRowsPerPage="Linhas por página"
                  rowsPerPage={tablePaginationInfo.rowsPerPage}
                  page={tablePaginationInfo.page}
                  onChangePage={tablePaginationInfo.onChangePage}
                  onChangeRowsPerPage={tablePaginationInfo.onChangeRowsPerPage}
                />
              </TableFooter>
            ) : null}
          </React.Fragment>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 100,
            }}
          >
            <CircularProgress />
          </div>
        )}
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  tablePagination: false,
};

CustomTable.propTypes = {
  loading: PropTypes.bool,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tablePagination: PropTypes.bool,
  tablePaginationInfo: PropTypes.shape({
    paginationControlled: PropTypes.bool,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    rowsPerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired,
  }),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
