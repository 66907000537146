import moment from "moment";
import api from "services/api";

export const SET_FORM = "SIGN_UP_FORM_SET_FORM";
export const SIGN_UP = "SIGN_UP_FORM_SIGN_UP";
export const SET_LOADING = "SIGN_UP_FORM_SET_LOADING";
export const RESET_SIGN_UP_RESULT = "SIGN_UP_FORM_RESET_SIGN_UP_RESULT";

export function resetSignUp() {
  return {
    type: RESET_SIGN_UP_RESULT,
    success: false,
    error: null,
  };
}

export function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    isLoading,
  };
}

export function setForm(newDatas) {
  return {
    type: SET_FORM,
    newDatas,
  };
}

export function signUp(userInfo) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const address = userInfo.address;
      await api.post("/users", {
        nome: userInfo.name,
        email: userInfo.email,
        senha: userInfo.password,
        genero: userInfo.gender,
        data_nascimento: moment(userInfo.birthDate),
        tipo: userInfo.personType === "CPF" ? 1 : 2,
        plano: userInfo.planId,
        cpf:
          userInfo.personType === "CPF" ? userInfo.personIdentifier : undefined,
        cnpj:
          userInfo.personType !== "CPF" ? userInfo.personIdentifier : undefined,
        cep: address.zipCode,
        estado: address.state,
        cidade: address.city,
        bairro: address.neighborhood,
        rua: address.street,
        numero: address.number,
        complemento: address.complement,
      });

      dispatch({
        type: SIGN_UP,
        success: true,
      });
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);

      dispatch({
        type: SIGN_UP,
        success: false,
        error: error?.response?.data || "Ocorreu um erro inesperado...",
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
}
