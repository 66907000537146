import React, { useState, useEffect } from "react";
import api from "services/api";
import moment from "moment";
// @material-ui/icons
import FilterList from "@material-ui/icons/FilterList";
import Close from "@material-ui/icons/Close";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import DocumentCard from "./DocumentCard.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
const useModalStyles = makeStyles(modalStyle);
const useStyles = makeStyles({
  ...styles,
  ...stylesSelect,
  filterInput: {
    marginTop: "16px",
  },
  searchInput: {
    marginBottom: "32px",
  },
  noDocsText: {
    marginLeft: "16px",
  },
});

const orderToField = {
  Nome: "titulo",
  Status: "status",
  Prazo: "prazo",
  Data: "data",
};

export default function DocumentCardContainer() {
  const classes = useStyles();
  const modaClasses = useModalStyles();

  const [modal, setModal] = useState(false);

  const [order, setOrder] = useState("Nome");
  const [filter, setFilter] = useState("Nenhum");
  const [filterTerm, setFilterTerm] = useState("");
  const [filterDate, setFilterDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [allDocuments, setAllDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);

  function adminDocs(docs) {
    let selected = [];
    docs.forEach((element) => {
      if (element.id_usuario == null) selected.splice(0, 0, element);
    });
    return selected;
  }

  useEffect(() => {
    async function loadDocuments() {
      try {
        // doc instanciados
        if (localStorage.getItem("@isAdmin")) {
          const response = await api.get(`/docs?page=1&size=10000`);
          let field = orderToField[order];
          response.data.sort((docA, docB) =>
            docA[field].localeCompare(docB[field])
          );
          setAllDocuments(adminDocs(response.data));
          setDocuments(adminDocs(response.data));
        } else {
          let userId = localStorage.getItem("@userId");
          const response = await api.get(
            `users/${userId}/docs?page=1&size=10000`
          );

          let field = orderToField[order];
          response.data.data.sort((docA, docB) =>
            docA[field].localeCompare(docB[field])
          );
          setAllDocuments(response.data.data);
          setDocuments(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    loadDocuments();
  }, []);

  useEffect(() => {
    let field = orderToField[order];

    documents.sort((docA, docB) => docA[field].localeCompare(docB[field]));

    setDocuments([...documents]);
  }, [order]);

  function cleanFilter() {
    setFilter("Nenhum");
    setDocuments(allDocuments);
    setFilterTerm("");
    setFilterDate(moment(new Date()).format("YYYY-MM-DD"));
    setModal(false);
  }

  function applyFilter() {
    if (filter === "Nenhum") {
      cleanFilter();
      return;
    }

    let filterDocuments = [];
    allDocuments.forEach((element) => {
      switch (filter) {
        case "Empresa":
          if (element.empresa === filterTerm)
            filterDocuments.splice(0, 0, element);
          break;
        case "NR":
          if (element.nr === filterTerm) filterDocuments.splice(0, 0, element);
          break;
        case "Status":
          if (element.status === filterTerm.toLowerCase())
            filterDocuments.splice(0, 0, element);
          break;
        case "Data":
          if (element.data === filterDate)
            filterDocuments.splice(0, 0, element);
          break;
        case "Prazo":
          if (
            moment(element.prazo).format("DD/MM/YYYY") ===
            moment(filterDate).format("DD/MM/YYYY")
          )
            filterDocuments.splice(0, 0, element);
          break;
        default:
          cleanFilter();
          break;
      }
    });
    setDocuments(filterDocuments);
    setModal(false);
  }

  function filterModal() {
    return (
      <GridContainer>
        <GridItem container extraClass={classes.filterInput}>
          <GridItem>
            <p>Filtrar por:</p>
          </GridItem>
          <GridItem>
            <FormControl className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
                inputProps={{
                  name: "filter",
                  id: "filter",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Nenhum"
                >
                  Nenhum
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Empresa"
                >
                  Empresa
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Status"
                >
                  Status
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="NR"
                >
                  NR
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Data"
                >
                  Data
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Prazo"
                >
                  Prazo
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridItem>
        {filter === "Data" || filter === "Prazo" ? (
          <GridItem
            container
            alignItems="flex-end"
            extraClass={classes.searchInput}
          >
            <GridItem>
              <p>Informe a data:</p>
            </GridItem>
            <GridItem>
              <CustomInput
                id="date"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "date",
                  value: filterDate,
                  onChange: (e) => setFilterDate(e.target.value),
                }}
              />
            </GridItem>
          </GridItem>
        ) : (
          <GridItem
            container
            alignItems="flex-end"
            extraClass={classes.searchInput}
          >
            <GridItem>
              <p>Digite o termo:</p>
            </GridItem>
            <GridItem>
              <CustomInput
                id="term"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  value: filterTerm,
                  onChange: (e) => setFilterTerm(e.target.value),
                }}
              />
            </GridItem>
          </GridItem>
        )}
      </GridContainer>
    );
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem container>
          <GridItem>
            <Button
              color="primary"
              onClick={() => setModal(true)}
              startIcon={<FilterList />}
            >
              Filtrar
            </Button>
          </GridItem>
          <GridItem>
            {filter !== "Nenhum" ? (
              <Button round startIcon={<Close />} onClick={cleanFilter}>
                Limpar filtro
              </Button>
            ) : null}
          </GridItem>
        </GridItem>
        <GridItem container>
          <GridItem>
            <p>Ordenar por:</p>
          </GridItem>
          <GridItem>
            <FormControl className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                onChange={(e) => setOrder(e.target.value)}
                value={order}
                inputProps={{
                  name: "order",
                  id: "order",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Nome"
                >
                  Nome
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Status"
                >
                  Status
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Data"
                >
                  Data
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Prazo"
                >
                  Prazo
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridItem>
      </GridContainer>

      {documents.length == 0 ? (
        <h2 className={classes.noDocsText}>Sem documentos</h2>
      ) : (
        documents.map((document) => (
          <DocumentCard key={document.id} id={document.id} />
        ))
      )}

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modal}
        onClose={cleanFilter}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>Aplicar filtro</h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          {filterModal()}
        </DialogContent>

        <DialogActions
          className={
            modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
          }
        >
          <Button withProgress color="primary" onClick={applyFilter}>
            Aplicar
          </Button>
          <Button onClick={cleanFilter}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
