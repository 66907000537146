import React from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Header from "components/Homepage/Header.js";
import Footer from "components/Homepage/Footer.js";

import { notFoundStyle } from "assets/jss/material-dashboard-react/components/homepageStyle.js";
const useStyles = makeStyles(notFoundStyle);

import errorImg from "assets/img/homepage/not_found.png";

const title = "OPS!";
const subtitle =
  "Não conseguimos achar a página que você está procurando. Mas encontramos todas as novidades de SST para você.";

export default function NotFounded() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div>
      <Header />
      <GridContainer extraClass={classes.content}>
        <GridItem sm={3} md={1} />
        <GridItem xs={12} sm={6} md={5}>
          <img
            src={errorImg}
            className={classes.image}
            alt={"imagem de erro 404, página não encontrada"}
          />
        </GridItem>
        <GridItem sm={12} md={6} extraClass={classes.center}>
          <GridItem xs={12}>
            <h4 className={classes.title}>{title}</h4>
          </GridItem>
          <GridItem xs={12}>
            <p className={classes.subtitle}>{subtitle}</p>
          </GridItem>
          <GridItem xs={12}>
            <Button color="primary" onClick={() => history.push(`/auth/home`)}>
              Voltar para o ínicio
            </Button>
          </GridItem>
        </GridItem>
      </GridContainer>
      <Footer />
    </div>
  );
}
