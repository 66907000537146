import React, { useState } from "react";
import api from "services/api";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";

const useStyles = makeStyles({
  ...styles,
  ...stylesSelect,
  errorMsg: {
    textAlign: "center",
    color: "red",
  },
  checkbox: {
    marginTop: "32px",
  },
});

export default function Suporte() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [numberCheck, setNumberCheck] = useState(false);
  const [number, setNumber] = useState("");
  const [isClient, setIsClient] = useState("yes");
  const [message, setMessage] = useState("");
  const [contactTime, setContactTime] = useState("manha");
  const [notific, setNotific] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [nameError, setNameError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  function checkField(checked, func) {
    if (checked === "") {
      func(true);
      return false;
    } else {
      func(false);
      return true;
    }
  }

  function formValidation() {
    return (
      checkField(name, setNameError) &&
      checkField(message, setMessageError) &&
      checkField(email, setEmailError)
    );
  }

  async function handleCreateContact() {
    if (formValidation()) {
      try {
        setLoading(true);
        setError(false);
        setErrorMsg("");

        await api.post(`/contacts`, {
          nome: name,
          email: email,
          telefone: number,
          conteudo: message,
          cliente: isClient,
          horario: contactTime,
        });
      } catch (error) {
        setError(true);
        setErrorMsg(
          "Ocorreu um erro na hora de enviar o contato. Tente novamente mais tarde"
        );
        console.log(error.response.data.error);
      } finally {
        setLoading(false);
        if (!error) {
          setNotific(true);
          setTimeout(function () {
            setNotific(false);
          }, 6000);
          setName("");
          setMessage("");
          setEmail("");
          setNumberCheck(false);
        }
      }
    } else {
      setError(true);
      setErrorMsg(
        "Alguns campos não foram preenchidos. Preencha todos para enviar o formulário."
      );
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Contato</h4>
          <h4 className={classes.cardCategoryWhite}>
            Para dúvidas ou reclamações, entre em contato conosco atráves do
            formulário abaixo
          </h4>
        </CardHeader>

        <CardBody>
          <div className={classes.errorMsg}>
            <h4>{errorMsg}</h4>
          </div>
          <GridContainer>
            <GridItem xs={12} md={8} sm={6}>
              <CustomInput
                error={nameError}
                labelText="Nome"
                id="title"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  value: name,
                  onChange: (e) => setName(e.target.value),
                }}
              />
            </GridItem>
            <GridItem container alignItems="center" xs={12} md={4} sm={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="status" className={classes.selectLabel}>
                  É cliente?
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={isClient}
                  onChange={(e) => setIsClient(e.target.value)}
                  inputProps={{
                    name: "client",
                    id: "client",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Já é cliente da AZ Safe?
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={"yes"}
                  >
                    Sim
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={"no"}
                  >
                    Ainda não
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={8} sm={6}>
              <CustomInput
                error={emailError}
                labelText="Digite seu email"
                id="email"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "email",
                  value: email,
                  onChange: (e) => setEmail(e.target.value),
                }}
              />
            </GridItem>

            <GridItem container alignItems="center" xs={4} sm={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="status" className={classes.selectLabel}>
                  Horario:
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={contactTime}
                  onChange={(e) => setContactTime(e.target.value)}
                  inputProps={{
                    name: "contactTime",
                    id: "contactTime",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Em qual horario prefere ser contatado?
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="manha"
                  >
                    Manhã
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="tarde"
                  >
                    Tarde
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="noite"
                  >
                    Noite
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12}>
              <CustomInput
                error={messageError}
                labelText="Digite aqui sua mensagem"
                id="message"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  rows: 4,
                  value: message,
                  onChange: (e) => setMessage(e.target.value),
                }}
              />
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} md={4} extraClass={classes.checkbox}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={numberCheck}
                    onChange={() => {
                      setNumberCheck(!numberCheck);
                      setNumber("");
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    color="primary"
                  />
                }
                label="Desejo ser contatado por WhatsApp"
              />
            </GridItem>
            <GridItem xs={12} md={8}>
              {numberCheck ? (
                <CustomInput
                  labelText="Digite seu número de whatsapp"
                  id="number"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: number,
                    onChange: (e) => setNumber(e.target.value),
                  }}
                />
              ) : null}
            </GridItem>
          </GridContainer>
        </CardBody>

        <CardFooter>
          <Button
            loading={loading}
            disabled={loading}
            color="primary"
            onClick={() => handleCreateContact()}
          >
            Enviar mensagem
          </Button>
          <Snackbar
            place="bc"
            color="success"
            message="Agradeçemos sua mensagem! Iremos analisar e assim que possível entraremos em contato."
            open={notific}
            close
            closeNotification={() => setNotific(false)}
          />
        </CardFooter>
      </Card>
    </React.Fragment>
  );
}
