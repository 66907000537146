import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import api from "services/api";
import moment from "moment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import Tag from "components/Tags/Tag.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import actionCardStyle from "assets/jss/material-dashboard-react/components/actionCardStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
const useStyles = makeStyles(actionCardStyle);
const useModalStyles = makeStyles(modalStyle);

export default function ActionCard(props) {
  const classes = useStyles();
  const modaClasses = useModalStyles();
  const history = useHistory();
  const {
    id,
    title,
    document,
    date,
    status,
    responsible,
    update,
    client,
  } = props;

  const [tags, setTags] = useState([]);
  const [docName, setDocName] = useState("");
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function load() {
      try {
        const response = await api.get(`actions/${id}/tags`);
        let selectedTags = [];
        response.data.forEach((element) => {
          if (element.id_acao == id) selectedTags.splice(0, 0, element);
        });
        setTags(selectedTags);

        if (document) {
          const doc = await api.get(`/docs/${document}`);

          setDocName(doc.data.titulo);
        }
      } catch (error) {
        console.log(error);
      }
    }

    load();
  }, []);

  async function remove() {
    try {
      setLoading(true);
      tags.forEach((tag) => {
        api.delete(`actions/${id}/tags/${tag.id}`);
      });
      await api.delete(`/actions/${id}`);

      history.push("/admin");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <GridItem xs={12}>
        <div className={classes.card}>
          <div>
            <p className={classes.lastUpdateText}>
              Última alteração: {moment(update).format("lll")}
            </p>
          </div>
          <div className={classes.tagContainer}>
            <h4>Etiquetas:</h4>
            <div className={classes.tags}>
              {tags.map((tag) => (
                <Tag key={tag.id} title={tag.titulo} color={tag.cor} />
              ))}
            </div>
          </div>
          <div>
            <h2 className={classes.title}>{title}</h2>
          </div>
          <div className={classes.infos}>
            <h4 className={classes.infoText}>
              Documento associado: {document ? docName : "Documento sem título"}
            </h4>
          </div>
          {responsible ? (
            <div className={classes.infos}>
              <h4 className={classes.infoText}>Responsavel: {responsible}</h4>
            </div>
          ) : null}
          {client ? (
            <div className={classes.infos}>
              <h4 className={classes.infoText}>Cliente: {client}</h4>
            </div>
          ) : null}
          <div className={classes.infos}>
            <h4 className={classes.infoText}>Prazo: {date}</h4>
            <h4 className={classes.infoText}>Status: {status}</h4>
          </div>
          <div className={classes.buttons}>
            <Button
              onClick={() => history.push(`/admin/acoes/${id}/edit`)}
              color="success"
              startIcon={<Edit />}
            >
              Editar
            </Button>
            <Button
              onClick={() => setModal(true)}
              color="danger"
              startIcon={<DeleteIcon />}
            >
              Apagar
            </Button>
          </div>
        </div>
      </GridItem>

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>Excluir ação</h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          <h5>Você realmente quer excluir essa ação?</h5>
        </DialogContent>

        <DialogActions
          className={
            modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
          }
        >
          <Button disabled={loading} onClick={() => setModal(false)}>
            Não
          </Button>

          <Button
            withProgress
            loading={loading}
            disabled={loading}
            onClick={remove}
            color="danger"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

ActionCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  document: PropTypes.number,
  date: PropTypes.string,
  status: PropTypes.string,
  responsible: PropTypes.string,
  client: PropTypes.string,
  update: PropTypes.string,
};
