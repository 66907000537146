export function hasPremiumPermission(user, isAdmin) {
  if (isAdmin) {
    return true;
  }

  if (
    (user.planId !== undefined &&
      user.planId !== 0 &&
      user.paymentStatus === "PAID" &&
      user.monthsLeft >= 1) ||
    user.freeTest
  ) {
    return true;
  }

  return false;
}
