import React, { useEffect, useState } from "react";
import api from "services/api";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
// @material-ui/icons
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import RegisterCard from "components/Newsletter/RegisterCard.js";
import PostCard from "components/PostCard";
import Nav from "./Nav";

import { primaryColor } from "assets/jss/material-dashboard-react";
const useStyles = makeStyles({
  title: {
    textAlign: "center",
    color: primaryColor[0],
  },
  mainContent: {
    display: "block",
  },
  posts: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: 0,
  },
  postItem: {
    listStyle: "none",
    margin: 10,
  },
  findForm: {
    marginBottom: 5,
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    "&:hover": {
      cursor: "pointer",
      color: primaryColor[0],
    },
    color: primaryColor[1],
    marginLeft: 8,
  },
  closeIcon: {
    "&:hover": {
      cursor: "pointer",
      color: "red",
    },
  },
  emptyTitle: {
    fontWeight: "bolder",
    textAlign: "center",
  },
  emptySubtitle: {
    textAlign: "center",
    marginBottom: "88px",
  },
  loadingProgress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 100,
  },
});

function Posts() {
  const classes = useStyles();
  const [allPosts, setAllPosts] = useState([]);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadPosts() {
      try {
        setLoading(true);
        const response = await api.get(`/blog/posts?page=${page}&size=20`);
        setTotalPages(response.data.totalPages);

        // let field = "id";
        // response.data.data.sort((postA, postB) => postA[field] < postB[field]);
        setAllPosts(response.data.data);
        setPosts(response.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadPosts();
  }, []);

  function cleanSearch() {
    setPosts(allPosts);
    setSearchTerm("");
    setIsEmpty(false);
  }

  // search by frontend
  // function handleSearch(event) {
  //   event.preventDefault();

  //   if (searchTerm === "") return;
  //   let filterPosts = [];
  //   let term = searchTerm.toLowerCase();
  //   allPosts.forEach((element) => {
  //     let titulo = element.titulo.toLowerCase();
  //     if (titulo.includes(term)) {
  //       filterPosts.splice(0, 0, element);
  //     }
  //   });
  //   if (filterPosts.length > 0) {
  //     setIsEmpty(false);
  //     setPosts(filterPosts);
  //   } else {
  //     setIsEmpty(true);
  //     setPosts(allPosts);
  //   }
  // }

  // search by backend
  async function handleSearch(event) {
    event.preventDefault();

    if (searchTerm === "") {
      setIsEmpty(false);
      setPosts(allPosts);
    } else {
      try {
        setLoading(true);
        const response = await api.get(
          `/blog/posts/search?titulo=${searchTerm}`
        );

        setPosts(response.data);
        setTotalPages(1);

        if (response.data.length == 0) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <React.Fragment>
      <h2 className={classes.title}>Postagens</h2>

      <GridContainer justify="center">
        <GridItem>
          <form className={classes.findForm} onSubmit={handleSearch}>
            {searchTerm !== "" ? (
              <CloseIcon onClick={cleanSearch} className={classes.closeIcon} />
            ) : null}
            <input
              value={searchTerm}
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
              type="text"
              placeholder="Busque por uma postagem..."
            />
            <SearchIcon onClick={handleSearch} className={classes.searchIcon} />
          </form>
        </GridItem>
      </GridContainer>

      {loading ? (
        <GridItem xs={12}>
          <div className={classes.loadingProgress}>
            <CircularProgress style={{ color: primaryColor[0] }} />
          </div>
        </GridItem>
      ) : (
        <main className={classes.mainContent}>
          {isEmpty ? (
            <GridContainer justify="center">
              <GridItem xs={12}>
                <h4 className={classes.emptyTitle}>
                  Não encontramos postagens com esse título
                </h4>
              </GridItem>
              <GridItem xs={12}>
                <p className={classes.emptySubtitle}>
                  Por favor, verifique sua pesquisa e tente novamente para obter
                  obter mais resultados
                </p>
              </GridItem>
            </GridContainer>
          ) : (
            <div>
              <ul className={classes.posts}>
                {posts.map((post) => (
                  <li key={post.id} className={classes.postItem}>
                    <PostCard post={post} />
                  </li>
                ))}
              </ul>

              <Nav
                currentPage={page}
                totalPages={totalPages}
                onSelectNewPage={(page) => setPage(page)}
              />
            </div>
          )}
        </main>
      )}

      <RegisterCard />
    </React.Fragment>
  );
}

export default Posts;
