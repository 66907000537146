import React, { useState, useEffect } from "react";
import api from "services/api";
// library for add drag and drop to the page
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// core components
import CardBody from "components/Card/CardBody.js";
import KanbanContainer from "components/Kanban/KanbanContainer.js";
import SimpleCard from "components/Kanban/KanbanCard.js";
import NewCard from "components/Kanban/KanbanNew.js";
import { kanbanColors } from "assets/jss/material-dashboard-react.js";

export default function KanbanView() {
  const [loading, setLoading] = useState(true);

  const [row1] = useState([]);
  const [row2] = useState([]);
  const [row3] = useState([]);
  const [row4] = useState([]);

  useEffect(() => {
    async function load() {
      try {
        let userId = localStorage.getItem("@userId");
        const response = await api.get(
          `users/${userId}/actions?page=1&size=10000`
        );

        for (let data of response.data.data) {
          switch (data.status) {
            default:
              row1.splice(0, 0, data);
              break;
            case "Não iniciada":
              row2.splice(0, 0, data);
              break;
            case "Em execução":
              row3.splice(0, 0, data);
              break;
            case "Concluída":
              row4.splice(0, 0, data);
              break;
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    load();
  }, []);

  return (
    <CardBody>
      {loading ? (
        <h4>Carregando...</h4>
      ) : (
        <div>
          <KanbanContainer title={"Rascunho"} color={kanbanColors[0]}>
            {row1.map(
              ({ id, titulo, id_documento, data, status, responsavel }) => {
                return (
                  <SimpleCard
                    key={id}
                    id={id}
                    title={titulo}
                    document={id_documento}
                    date={data}
                    status={status}
                    responsible={responsavel}
                  />
                );
              }
            )}
            <NewCard />
          </KanbanContainer>
          <KanbanContainer title={"Não iniciada"} color={kanbanColors[1]}>
            {row2.map(
              ({ id, titulo, id_documento, data, status, responsavel }) => {
                return (
                  <SimpleCard
                    key={id}
                    id={id}
                    title={titulo}
                    document={id_documento}
                    date={data}
                    status={status}
                    responsible={responsavel}
                  />
                );
              }
            )}
            <NewCard />
          </KanbanContainer>
          <KanbanContainer title={"Em execução"} color={kanbanColors[2]}>
            {row3.map(
              ({ id, titulo, id_documento, data, status, responsavel }) => {
                return (
                  <SimpleCard
                    key={id}
                    id={id}
                    title={titulo}
                    document={id_documento}
                    date={data}
                    status={status}
                    responsible={responsavel}
                  />
                );
              }
            )}
            <NewCard />
          </KanbanContainer>
          <KanbanContainer title={"Concluída"} color={kanbanColors[3]}>
            {row4.map(
              ({ id, titulo, id_documento, data, status, responsavel }) => {
                return (
                  <SimpleCard
                    key={id}
                    id={id}
                    title={titulo}
                    document={id_documento}
                    date={data}
                    status={status}
                    responsible={responsavel}
                  />
                );
              }
            )}
            <NewCard />
          </KanbanContainer>
        </div>
      )}
    </CardBody>
  );
}
