/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// style componenst
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import stylesCheckbox from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
const useStyles = makeStyles({
  ...styles,
  ...stylesCheckbox,
  containerBordered: {
    border: "#00000020 0.2px solid",
    borderRadius: "6px",
    marginTop: "14px !important",
    padding: "16px 0px",
  },
  selectDiv: {
    padding: "24px 8px",
  },
});

export default function MeetForm({ previousStep, nextStep }) {
  const classes = useStyles();
  const [type, setType] = useState("");
  const [otherText, setOtherText] = useState("");
  const [error, setError] = useState(false);
  const [otherError, setOtherError] = useState(false);

  function submittForm() {
    // send to backend
    if (type === "") {
      setError(true);
      return;
    }
    setError(false);
    if (type === "Outro") {
      if (otherText === "") setOtherError(true);
      else {
        setOtherError(false);
        console.log(otherText);
        nextStep();
      }
    } else {
      console.log(type);
      nextStep();
    }
  }

  return (
    <React.Fragment>
      <GridContainer justify="center" extraClass={classes.containerBordered}>
        <GridItem>
          <h3 className={`${classes.cardTitle} ${classes.subTitle}`}>
            Como nos conheceu?
          </h3>
        </GridItem>
      </GridContainer>

      <GridContainer extraClass={classes.selectDiv}>
        {error ? (
          <GridItem xs={12} sm={10}>
            <h5 style={{ color: "red", fontWeight: "bolder" }}>
              Por favor, informe um dos campos para prosseguir
            </h5>
          </GridItem>
        ) : null}
        <GridItem xs={12} sm={5}>
          <FormControlLabel
            control={
              <Radio
                checked={type === "Instagram"}
                onChange={() => {
                  setType("Instagram");
                }}
                value="Instagram"
                name="Instagram"
                aria-label="Instagram"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Instagram"
          />
        </GridItem>
        <GridItem xs={12} sm={5}>
          <FormControlLabel
            control={
              <Radio
                checked={type === "Facebook"}
                onChange={() => {
                  setType("Facebook");
                }}
                value="Facebook"
                name="Facebook"
                aria-label="Facebook"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Facebook"
          />
        </GridItem>
        <GridItem xs={12} sm={5}>
          <FormControlLabel
            control={
              <Radio
                checked={type === "Google"}
                onChange={() => {
                  setType("Google");
                }}
                value="Google"
                name="Google"
                aria-label="Google"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Google"
          />
        </GridItem>
        <GridItem xs={12} sm={5}>
          <FormControlLabel
            control={
              <Radio
                checked={type === "Indicação de amigos"}
                onChange={() => {
                  setType("Indicação de amigos");
                }}
                value="Friends"
                name="Friends"
                aria-label="Friends"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Indicação de amigos"
          />
        </GridItem>
        <GridItem xs={12} sm={5}>
          <FormControlLabel
            control={
              <Radio
                checked={type === "Indicação de professores"}
                onChange={() => {
                  setType("Indicação de professores");
                }}
                value="Teachers"
                name="Teachers"
                aria-label="Teachers"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Indicação de professores"
          />
        </GridItem>
        <GridItem xs={12} sm={5}>
          <FormControlLabel
            control={
              <Radio
                checked={type === "Universidade/Curso"}
                onChange={() => {
                  setType("Universidade/Curso");
                }}
                value="University"
                name="University"
                aria-label="University"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Universidade/Curso"
          />
        </GridItem>
        <GridItem xs={12} sm={5}>
          <FormControlLabel
            control={
              <Radio
                checked={type === "Indicação de embaixador"}
                onChange={() => {
                  setType("Indicação de embaixador");
                }}
                value="Ambassador"
                name="Ambassador"
                aria-label="Ambassador"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Indicação de embaixador"
          />
        </GridItem>
        <GridItem xs={12} sm={5}>
          <FormControlLabel
            control={
              <Radio
                checked={type === "Outro"}
                onChange={() => {
                  setOtherText("");
                  setOtherError(false);
                  setType("Outro");
                }}
                value="Other"
                name="Other"
                aria-label="Other"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Outro"
          />
        </GridItem>
        {type === "Outro" ? (
          <GridItem xs={12} sm={10}>
            <CustomInput
              error={otherError}
              labelText="Infome por onde nos conheceu"
              id="info"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: otherText,
                onChange: (e) => setOtherText(e.target.value),
              }}
            />
          </GridItem>
        ) : null}
      </GridContainer>

      <GridContainer>
        <GridItem container xs={6} justify="center">
          <Button round color="primary" onClick={previousStep}>
            Voltar
          </Button>
        </GridItem>

        <GridItem container xs={6} justify="center">
          <Button round color="primary" onClick={submittForm}>
            Continuar
          </Button>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}

MeetForm.propTypes = {
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
};
