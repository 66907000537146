const whatsappNumber = "557599512579";
const whatsppMessage = "Olá. Tenho uma dúvida quanto a plataforma do AZsafe";
const contactEmail = "contato@azsafe.com.br";
const instagramLink = "https://www.instagram.com/azsafeapp/";
const facebookLink =
  "https://www.facebook.com/people/AZsafe-Solu%C3%A7%C3%B5es/100092625705681/";
// emailJS configuration
const mailServiceId = "service_sq4l5ov";
const mailUserId = "i64ssevyoGc55WpUI";
const feedbackTemplateId = "template_3wsfw95";
const errorReportTemplateId = "template_ay1vkve";
// dont change
const emailLink = "mailto:" + contactEmail;

export {
  whatsappNumber,
  whatsppMessage,
  contactEmail,
  instagramLink,
  facebookLink,
  emailLink,
  mailServiceId,
  mailUserId,
  feedbackTemplateId,
  errorReportTemplateId,
};
