import React, { useState } from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PermissionsGate from "components/PermissionsGate";
import KanbanView from "./KanbanView.js";
import CardView from "./CardView.js";

// style components
import { primaryColor } from "assets/jss/material-dashboard-react";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles({
  ...styles,
  changeView: {
    display: "flex",
    alignItems: "center",
  },
});

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: primaryColor[4],
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: primaryColor[4],
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function Acoes() {
  const classes = useStyles();
  const [kanban, setKanban] = useState(false);

  return (
    <Card plain>
      <CardHeader plain color="primary">
        <h4 className={classes.cardTitleWhite}>Minhas ações</h4>
        <p className={classes.cardCategoryWhite}>Crie e gerencie suas ações</p>
      </CardHeader>

      <CardBody>
        <div className={classes.changeView}>
          <p>Tela clássica</p>
          <CustomSwitch
            checked={kanban}
            onChange={() => setKanban(!kanban)}
            name="viewChange"
          />
          <p>Tela em Kanban</p>
        </div>
        {kanban ? <KanbanView /> : <CardView />}
      </CardBody>
    </Card>
  );
}

export default function Actions() {
  return (
    <PermissionsGate>
      <Acoes />
    </PermissionsGate>
  );
}
