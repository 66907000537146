import React, { useState } from "react";
import PropTypes from "prop-types";
import api from "services/api";
import moment from "moment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";

import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
const useModalStyles = makeStyles(modalStyle);
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles({
  ...styles,
  pink: { backgroundColor: "pink" },
  red: { backgroundColor: "red" },
  green: { backgroundColor: "green" },
  blue: { backgroundColor: "blue" },
  header: {
    alignItems: "center",
  },
  content: {
    paddingLeft: "32px",
  },
  link: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  deleteButton: {
    marginBottom: "12px",
  },
});

export default function ListNotifications(props) {
  const classes = useStyles();
  const modaClasses = useModalStyles();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [date] = useState(props.date);

  async function handleDeleteNotification() {
    setLoadingDelete(true);
    console.log("Delete notification: " + props.id);
    try {
      setError(null);
      setLoadingDelete(true);

      let userId = localStorage.getItem("@userId");
      await api.delete(`/users//${userId}/notifications/${props.id}`);
    } catch (error) {
      setError(error.response);
    } finally {
      if (error === "") {
        window.location.reload();
      }
      setLoadingDelete(false);
      setModal(false);
    }
  }

  return (
    <React.Fragment>
      <GridContainer justify="center">
        <GridItem xs={12} md={10}>
          <Card>
            <GridItem
              container
              justify="space-between"
              extraClass={classes.header}
            >
              <GridItem>
                <h3>{props.title}</h3>
              </GridItem>
              <GridItem>
                <p style={{ fontWeight: "bolder" }}>{moment(date).fromNow()}</p>
              </GridItem>
            </GridItem>
            <GridItem xs={12} md={11}>
              <p className={classes.content}>{props.description}</p>
            </GridItem>
            <GridItem container justify="space-between">
              {props.category ? (
                <GridItem>
                  <p>Categoria: {props.category}</p>
                </GridItem>
              ) : null}
              {props.link ? (
                <GridItem>
                  <p>
                    Link:{" "}
                    <a
                      onClick={() => window.open(props.link)}
                      className={classes.link}
                    >
                      {props.link}
                    </a>
                  </p>
                </GridItem>
              ) : null}
              <GridItem>
                <Button
                  color="danger"
                  justIcon
                  className={classes.deleteButton}
                  onClick={() => setModal(true)}
                >
                  <DeleteIcon />
                </Button>
              </GridItem>
            </GridItem>
          </Card>
        </GridItem>
      </GridContainer>

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>Excluir tutorial</h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          <h5>Deseja apagar essa notificação?</h5>
        </DialogContent>

        <DialogActions
          className={
            modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
          }
        >
          <Button disabled={loadingDelete} onClick={() => setModal(false)}>
            Não
          </Button>
          <Button
            withProgress
            disabled={loadingDelete}
            loading={loadingDelete}
            onClick={() => handleDeleteNotification()}
            color="danger"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

ListNotifications.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.date,
};
