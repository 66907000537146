/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "services/api";
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import Button from "components/CustomButtons/Button.js";
import EditorViewer from "components/EditorViewer";
import GridItem from "components/Grid/GridItem.js";

import {
  dangerColor,
  successColor,
} from "assets/jss/material-dashboard-react.js";

import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
const useModalStyles = makeStyles(modalStyle);
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles({
  ...styles,
  container: {
    display: "flex",
    justifyContent: "space-between",
    borderStyle: "none none solid",
    border: "2px",
    borderColor: "#D2D2D2",
    padding: 16,
  },
  infos: {
    flex: 2,
    cursor: "pointer",
  },
  underInfo: {
    display: "flex",
    flexDirection: "row",
  },
  buttons: {
    flex: 1,
    textAlign: "end",
    marginTop: 16,
    marginRight: 8,
  },
  title: {
    margin: 0,
  },
  subtitle: {
    marginTop: "8px",
  },
});
const infoStyle = makeStyles({
  bgContainer: {
    border: "1px solid var(--ck-color-base-border)",
    backgroundColor: "#EEE",
    height: "500px",
    overflowY: "scroll",
    zIndex: "0",
    padding: "calc( 2 * var(--ck-spacing-large) )",
  },
  contentContainer: {
    border: "1px hsl( 0,0%,82.7% ) solid",
    backgroundColor: "white",
    margin: "4px 10px",
  },
  buttonDiv: {
    margin: "8px 0px",
    textAlign: "center",
  },
});

export default function DocumentCard(props) {
  const classes = useStyles();
  const infoClasses = infoStyle();
  const modaClasses = useModalStyles();
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const [deadline, setDeadline] = useState("");
  const [documentText, setDocumentText] = useState("");
  const [company, setCompany] = useState("");
  const [nr, setNr] = useState("");
  const [base, setBase] = useState(false);
  const [lastUpdate, setLastUpdate] = useState("");

  const [modal, setModal] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadDocument() {
      try {
        const response = await api.get(`/docs/${props.id}`);
        const document = response.data;

        setTitle(document.titulo);
        setStatus(document.status);
        setDate(moment(document.data).format("DD/MM/YYYY"));
        setDeadline(moment(document.prazo).format("DD/MM/YYYY"));
        setDocumentText(document.texto);
        setCompany(document.empresa);
        setNr(document.nr);
        setBase(document.base);
        setLastUpdate(moment(document.updatedAt).format("lll"));
      } catch (error) {
        console.log(error);
      }
    }

    loadDocument();
  }, []);

  function editDocument() {
    history.push(`/admin/documentos/${props.id}/edit`);
  }

  async function deleteDocument() {
    try {
      setLoading(true);
      await api.delete(`/docs/${props.id}`);

      history.push("/admin");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <div xs={10} className={classes.container}>
        <GridItem
          xs={10}
          extraClass={classes.infos}
          onClick={() => setOpenPanel(!openPanel)}
        >
          <GridItem xs={12}>
            <h3 className={classes.title}>{title}</h3>
          </GridItem>
          <GridItem container extraClass={classes.underInfo}>
            <GridItem xs={12} md={3}>
              <p className={classes.subtitle}>Status: {status}</p>
            </GridItem>
            <GridItem xs={12} md={3}>
              <p className={classes.subtitle}>Data: {date}</p>
            </GridItem>
            <GridItem xs={12} md={3}>
              <p className={classes.subtitle}>Prazo: {deadline}</p>
            </GridItem>
          </GridItem>
        </GridItem>
        <GridItem xs={2} extraClass={classes.buttons}>
          <Tooltip id="tooltip-top" title="Editar documento" placement="top">
            <IconButton
              style={{ color: successColor[0] }}
              aria-label="Exportar"
              onClick={() => editDocument()}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip id="tooltip-top" title="Excluir documento" placement="top">
            <IconButton
              aria-label="Deletar"
              style={{ color: dangerColor[0] }}
              onClick={() => {
                console.log(title + " : " + base);
                setModal(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </GridItem>

        {/* Modal de exclusão */}
        <React.Fragment>
          <Dialog
            classes={{
              root: modaClasses.center,
              paper: modaClasses.modal,
            }}
            open={modal}
            onClose={() => setModal(false)}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="modal-slide-title"
              disableTypography
              className={modaClasses.modalHeader}
            >
              <Button
                justIcon
                className={modaClasses.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setModal(false)}
              >
                <Close className={modaClasses.modalClose} />
              </Button>
              <h4 className={modaClasses.modalTitle}>Excluir documento</h4>
            </DialogTitle>

            <DialogContent
              id="modal-slide-description"
              className={modaClasses.modalBody}
            >
              <h5>
                Você realmente quer excluir esse documento? Todas as informações
                associadas serão perdidas.
              </h5>
            </DialogContent>

            <DialogActions
              className={
                modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
              }
            >
              <Button disabled={loading} onClick={() => setModal(false)}>
                Não
              </Button>

              <Button
                withProgress
                loading={loading}
                disabled={loading}
                onClick={deleteDocument}
                color="danger"
              >
                Sim
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </div>
      {openPanel ? (
        <div className={infoClasses.bgContainer}>
          <GridItem container justify="center">
            <GridItem>
              <p>Empresa: {company === "" ? "Não informado" : company}</p>
            </GridItem>
            <GridItem>
              <p>Nr: {nr === "" ? "Não informado" : nr}</p>
            </GridItem>
            <GridItem>
              <p>Última modificação: {lastUpdate}</p>
            </GridItem>
          </GridItem>
          <div className={infoClasses.contentContainer}>
            <EditorViewer initialContent={documentText} />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

DocumentCard.propTypes = {
  id: PropTypes.number,
  // title: PropTypes.string,
  // status: PropTypes.string,
  // date: PropTypes.string,
  // deadline: PropTypes.string,
  // documentText: PropTypes.string,
  // base: PropTypes.bool,
};
