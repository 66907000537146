import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import { faqQuest } from "assets/jss/Texts/siteTexts";
const faqStyle = makeStyles({
  header: {
    backgroundColor: "white",
  },
  item: {
    backgroundColor: "white",
    margin: "12px 0px",
  },
  itemIcon: {
    marginTop: "8px",
  },
  rotateIcon: {
    transform: "rotateZ(90deg)",
  },
  itemTitle: {
    fontSize: "20px",
    fontWeight: "bolder",
    cursor: "pointer",
  },
  itemText: {
    marginRight: "32px",
    marginBottom: "8px",
  },
});

export default function FAQ() {
  const classes = faqStyle();

  function faqItem(title, description) {
    const [openMenu, setOpenMenu] = useState(false);
    return (
      <GridItem container justify="right" extraClass={classes.item}>
        <GridItem
          xs={11}
          extraClass={classes.itemTitle}
          onClick={() => setOpenMenu(!openMenu)}
        >
          <p>{title}</p>
        </GridItem>
        <GridItem xs={1} extraClass={classes.itemIcon}>
          <Button
            justIcon
            simple
            color="primary"
            onClick={() => setOpenMenu(!openMenu)}
          >
            {openMenu ? (
              <ArrowForwardIcon className={classes.rotateIcon} />
            ) : (
              <ArrowForwardIcon style={{ padding: "0px" }} />
            )}
          </Button>
        </GridItem>
        {openMenu ? (
          <GridItem xs={12} extraClass={classes.itemText}>
            <p>{description}</p>
          </GridItem>
        ) : null}
      </GridItem>
    );
  }

  return (
    <GridItem container justify="center">
      <GridItem xs={11}>
        <GridItem xs={12}>
          <h2> Perguntas que talvez você esteja se fazendo </h2>
        </GridItem>
        {faqItem(faqQuest[0][1], faqQuest[0][2])}
        {faqItem(faqQuest[1][1], faqQuest[1][2])}
        {faqItem(faqQuest[2][1], faqQuest[2][2])}
        {faqItem(faqQuest[3][1], faqQuest[3][2])}
        {faqItem(faqQuest[4][1], faqQuest[4][2])}
        {faqItem(faqQuest[5][1], faqQuest[5][2])}
        {faqItem(faqQuest[6][1], faqQuest[6][2])}
        {faqItem(faqQuest[7][1], faqQuest[7][2])}
        {faqItem(faqQuest[8][1], faqQuest[8][2])}
        {faqItem(faqQuest[9][1], faqQuest[9][2])}
      </GridItem>
    </GridItem>
  );
}
