import React from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";

import actionCardStyle from "assets/jss/material-dashboard-react/components/actionCardStyle";
import GridContainer from "components/Grid/GridContainer";
const useStyles = makeStyles(actionCardStyle);

export default function EmptyActionCard() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <GridContainer extraClass={classes.center}>
      <GridItem xs={12} sm={10} md={6}>
        <div className={classes.emptyCard}>
          <h3 className={classes.emptyTitle}>
            Você ainda não criou nenhuma ação
          </h3>
          <Button
            round
            color="success"
            onClick={() => {
              history.push(`/admin/acoes/new`);
            }}
          >
            Criar nova ação
          </Button>
        </div>
      </GridItem>
    </GridContainer>
  );
}
