import React, { useState, forwardRef, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/svg/image_placeholder.svg";

const useStyles = makeStyles((theme) => ({
  fileInput: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    "& > input[type=file]": {
      display: "none",
    },
  },
  thumbnail: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 400,
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      width: 250,
    },
  },
}));

// eslint-disable-next-line react/display-name
const ImageUpload = forwardRef(
  (
    { addButtonProps, changeButtonProps, removeButtonProps, defaultImageUrl },
    fileRef
  ) => {
    const classes = useStyles();
    const [file, setFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(
      !defaultImageUrl ? defaultImage : defaultImageUrl
    );
    let fileInput = fileRef;

    useEffect(
      () =>
        setImagePreviewUrl(!defaultImageUrl ? defaultImage : defaultImageUrl),
      [defaultImageUrl]
    );

    const handleImageChange = (e) => {
      e.preventDefault();

      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
        setFile(file);
        setImagePreviewUrl(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    };

    const handleClick = () => {
      fileInput.current.click();
    };

    const handleRemove = () => {
      setFile(null);
      setImagePreviewUrl(defaultImage);

      fileInput.current.value = null;
    };

    return (
      <div className={classes.fileInput}>
        <input type="file" onChange={handleImageChange} ref={fileInput} />

        <img
          className={classes.thumbnail}
          src={imagePreviewUrl}
          alt="..."
          onClick={handleClick}
        />

        <div>
          {file === null ? (
            <Button {...addButtonProps} onClick={() => handleClick()}>
              Selecione uma imagem
            </Button>
          ) : (
            <span>
              <Button {...changeButtonProps} onClick={() => handleClick()}>
                Alterar
              </Button>

              <Button {...removeButtonProps} onClick={() => handleRemove()}>
                Remover
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
);

ImageUpload.propTypes = {
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  defaultImageUrl: PropTypes.string,
};

export default ImageUpload;
