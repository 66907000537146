import React, { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import api from "services/api";
import { validation } from "utils/validate";
import notificationRules from "./notificationRules";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import AdminRestriction from "components/AccessWarning/AdminRestriction.js";
import { blockAccess } from "utils/validate.js";

import stylesDash from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { dangerColor } from "assets/jss/material-dashboard-react";

const useStyles = makeStyles(stylesDash);

export default function NewNotification() {
  const history = useHistory();
  const classes = useStyles();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [link, setLink] = useState("");

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  function isValid() {
    const result = validation(
      {
        description,
        title,
      },
      {
        description: notificationRules.description,
        title: notificationRules.title,
      }
    );

    setErrors(result ? result : {});
    return result == null;
  }

  async function handleCreateNotification() {
    if (isValid()) {
      try {
        setLoading(true);
        setErrors({});

        await api.post("/notifications", {
          titulo: title,
          categoria: category,
          descricao: description,
          link: link,
          data: moment(date),
        });

        history.push("/admin/notifications");
      } catch (error) {
        setErrorMessage("Algo deu errado ao cadastrar a notificação");
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <div>
      {blockAccess(2) ? (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Nova notificação</h4>
                <p className={classes.cardCategoryWhite}>
                  Crie uma nova notificação para os usuários
                </p>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  {errorMessage ? (
                    <p
                      style={{
                        color: dangerColor[0],
                        fontWeight: "bold",
                        marginLeft: 10,
                      }}
                    >
                      {errorMessage}
                    </p>
                  ) : null}
                  <GridItem xs={12}>
                    <CustomInput
                      error={errors.title}
                      errorText={errors.title}
                      labelText="Titulo"
                      id="title"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: title,
                        onChange: (e) => setTitle(e.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <CustomInput
                      error={errors.description}
                      errorText={errors.description}
                      labelText="Descrição"
                      id="description"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: description,
                        onChange: (e) => setDescription(e.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={8} sm={10}>
                    <CustomInput
                      labelText="Link"
                      id="link"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: link,
                        onChange: (e) => setLink(e.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={2}>
                    <CustomInput
                      labelText="Categoria"
                      id="category"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: category,
                        onChange: (e) => setCategory(e.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem container alignItems="center" xs={12} md={6}>
                    <CustomInput
                      labelText="Data"
                      id="birth-date"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "date",
                        value: date,
                        onChange: (e) => setDate(e.target.value),
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>

              <CardFooter>
                <Button
                  withProgress
                  loading={loading}
                  disabled={loading}
                  onClick={handleCreateNotification}
                  color="primary"
                >
                  Criar Notificação
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <AdminRestriction />
      )}
    </div>
  );
}
