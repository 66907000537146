import React from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DescriptionIcon from "@material-ui/icons/Description";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Homepage/Header.js";
import Footer from "components/Homepage/Footer.js";
import StartComponent from "components/Homepage/StartComponent.js";
import NewsletterFormDialog from "components/Newsletter/NewsletterFormDialog";
//images
import {
  imageHome1,
  imageHome2,
  imageHome3,
} from "assets/jss/Texts/siteImages.js";

import {
  homepageStyle,
  homeStyle,
} from "assets/jss/material-dashboard-react/components/homepageStyle.js";
const useStyles = makeStyles((theme) => ({
  ...homepageStyle,
  ...homeStyle(theme, imageHome1),
}));
const dinamicStyle = makeStyles((theme) => ({
  hidenImage: {
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

import {
  homeTitulo,
  homeSubtitulo,
  homeTexto,
  homeESocial,
  homeDocumentos,
  homeAcoes,
  homeNotificacoes,
} from "assets/jss/Texts/siteTexts.js";

export default function Homepage() {
  const history = useHistory();
  const classes = useStyles();
  const imageClass = dinamicStyle();

  function infoComponent(icon, title, text) {
    return (
      <GridContainer>
        <GridItem xs={12}>{icon}</GridItem>
        <GridItem xs={12} extraClass={classes.infoTitle}>
          {title}
        </GridItem>
        <GridItem xs={12} extraClass={classes.infoText}>
          {text}
        </GridItem>
        <GridItem xs={12}>
          <a
            href="#"
            className={classes.infoLink}
            onClick={() => {
              history.push(`/auth/solucoes`);
            }}
          >
            Saiba mais...
          </a>
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <div>
      <Header page="home" />
      <GridContainer>
        <GridItem container extraClass={classes.topBanner}>
          <GridContainer extraClass={classes.topBannerOverlay}>
            <GridContainer>
              <GridItem xs={12} extraClass={classes.topBannerContainer}>
                <GridItem xs={12} sm={4}>
                  <p className={classes.topBannerText}>{homeTitulo}</p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p className={classes.topBannerText}>{homeSubtitulo}</p>
                </GridItem>
              </GridItem>
              <GridItem xs={12}>
                <GridItem extraClass={classes.topBannerButton}>
                  <NewsletterFormDialog color="primary" />
                </GridItem>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </GridItem>
        <GridItem container>
          <GridItem xs={10} sm={6} md={4} extraClass={classes.midImage}>
            <img
              src={imageHome2}
              alt="representação de documentos"
              className={imageClass.hidenImage}
            />
          </GridItem>
          <GridItem container extraClass={classes.midBanner}>
            <GridItem xs={12} md={7}>
              {homeTexto}
            </GridItem>
          </GridItem>
        </GridItem>
        <GridItem container justify="center">
          <GridItem xs={12} md={2} extraClass={classes.infoContainer}>
            {infoComponent(
              <img
                src={imageHome3}
                alt="Imagem"
                style={{
                  display: "inline-block",
                  backgroundColor: "#00B515",
                  borderRadius: "16px",
                  padding: "15px",
                  width: "30px",
                  height: "30px",
                }}
              />,
              "e-Social",
              homeESocial
            )}
          </GridItem>
          <GridItem xs={1} />
          <GridItem xs={12} md={2} extraClass={classes.infoContainer}>
            {infoComponent(
              <DescriptionIcon fontSize="large" className={classes.infoItem} />,
              "Documentos",
              homeDocumentos
            )}
          </GridItem>
          <GridItem xs={1} />
          <GridItem xs={12} md={2} extraClass={classes.infoContainer}>
            {infoComponent(
              <NotificationsIcon
                fontSize="large"
                className={classes.infoItem}
              />,
              "Notificações",
              homeNotificacoes
            )}
          </GridItem>
          <GridItem xs={1} />
          <GridItem xs={12} md={2} extraClass={classes.infoContainer}>
            {infoComponent(
              <PlaylistAddCheckIcon
                fontSize="large"
                className={classes.infoItem}
              />,
              "Ações",
              homeAcoes
            )}
          </GridItem>
        </GridItem>
        <StartComponent />
      </GridContainer>
      <Footer />
    </div>
  );
}
