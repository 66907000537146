import {
  grayColor,
  whiteColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react.js";

const actionCardStyle = {
  center: {
    justifyContent: "center",
  },
  emptyCard: {
    backgroundColor: whiteColor,
    padding: "32px 12px",

    textAlign: "center",
    border: "1px solid " + grayColor[5],
    borderRadius: "8px",
    boxShadow:
      "0 10px 10px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.12)",
  },
  emptyTitle: {
    marginBottom: "32px",
    fontWeight: "bolder",
  },
  card: {
    backgroundColor: whiteColor,
    padding: "16px",
    margin: "16px 0px",

    border: "1px solid " + grayColor[5],
    borderRadius: "8px",
    boxShadow:
      "0 10px 10px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.12)",
  },
  lastUpdateText: {
    margin: "0px",
  },
  tagContainer: {
    display: "flex",
    marginTop: "-12px",
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  title: {
    marginBottom: "16px",
    padding: "8px",
  },
  infos: {
    display: "flex",
  },
  infoText: {
    margin: "8px 64px 8px 0px",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "24px",
  },
};

export default actionCardStyle;
