import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import api from "services/api";
import { validation } from "utils/validate";
import planRules from "./planRules";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";
import stylesDash from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { dangerColor } from "assets/jss/material-dashboard-react";
import { maskMoney } from "utils/masks";

const styles = {
  ...stylesSelect,
  ...stylesDash,
};

const useStyles = makeStyles(styles);

export default function EditPlan() {
  const history = useHistory();
  const classes = useStyles();
  let { id: planId } = useParams();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [value, setValue] = useState("");
  const [installments, setInstallments] = useState("");
  const [months, setMonths] = useState(1);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function loadPlan() {
      try {
        setLoading(true);

        const planDatas = (await api.get(`/plans/${planId}`)).data;

        setTitle(planDatas.titulo);
        setDescription(planDatas.descricao);
        setInstallments(planDatas.quantidade);
        setMonths(planDatas.months);

        const stringValue = `${planDatas.valor}`;
        const characters = stringValue.split("");
        const indexOfDot = characters.findIndex((elem) => elem === ".");
        const zeros =
          2 - (characters.slice(indexOfDot).length - 1) > 0
            ? 2 - (characters.slice(indexOfDot).length - 1) > 1
              ? "00"
              : "0"
            : "";
        const planValue = maskMoney(`${stringValue.replace(".", "")}${zeros}`);
        setValue(planValue);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadPlan();
  }, []);

  function isValid() {
    const result = validation(
      {
        title,
        description,
        value,
        installments,
        months,
      },
      {
        title: planRules.title,
        description: planRules.description,
        value: planRules.value,
        installments: planRules.installments,
        months: planRules.months,
      }
    );

    setErrors(result ? result : {});
    return result == null;
  }

  async function handleUpdateAdmin() {
    if (isValid()) {
      try {
        setLoading(true);
        setErrors({});

        await api.put(`/plans/${planId}`, {
          titulo: title,
          descricao: description,
          valor: parseFloat(value.replace(/\./g, "").replace(",", ".")),
          quantidade: parseInt(installments, 10),
          months: parseInt(months, 10),
        });

        history.push("/admin/manage-plans/");
      } catch (error) {
        console.log(error.response.data);
        setErrorMessage(error.response.data.error);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Editar Plano</h4>
              <p className={classes.cardCategoryWhite}>
                Edição das informações do plano {title}
              </p>
            </CardHeader>
            {errorMessage && (
              <p
                style={{
                  color: dangerColor[0],
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
              >
                {errorMessage}
              </p>
            )}

            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={errors.title}
                    errorText={errors.title}
                    labelText="Título"
                    id="title"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: title,
                      onChange: (e) => setTitle(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={errors.description}
                    errorText={errors.description}
                    labelText="Descrição"
                    id="description"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: description,
                      onChange: (e) => setDescription(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    error={errors.value}
                    errorText={errors.value}
                    labelText="Valor"
                    id="value"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: value,
                      maxLength: 14,
                      onChange: (e) => setValue(maskMoney(e.target.value)),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    error={errors.installments}
                    errorText={errors.installments}
                    labelText="Parcelas"
                    id="installments"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      value: installments,
                      onChange: (e) => setInstallments(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    error={errors.months}
                    errorText={errors.months}
                    labelText="Meses de acesso premium"
                    id="months"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      value: months,
                      onChange: (e) => setMonths(e.target.value),
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>

            <CardFooter>
              <Button
                withProgress
                loading={loading}
                disabled={loading}
                onClick={handleUpdateAdmin}
                color="primary"
              >
                Salvar Alterações
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
