import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "services/api";
// libs
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import UploadImage from "components/CustomUpload/ImageUpload";
import Table from "components/Table/Table.js";

import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";
import stylesDash from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import { warningColor, dangerColor } from "assets/jss/material-dashboard-react";

const styles = {
  ...stylesSelect,
  ...stylesDash,
};

const useStyles = makeStyles(styles);
const useModalStyles = makeStyles(modalStyle);

export default function EditPost() {
  const history = useHistory();
  const { id: postId } = useParams();
  const classes = useStyles();
  const modaClasses = useModalStyles();
  const fileRef = useRef();
  const editor = useRef();
  const [modal, setModal] = useState(false);
  const [commentToRemove, setCommentToRemove] = useState("");
  const [comments, setComments] = useState([]);
  const [initialContent, setInitialContent] = useState(null);
  const [cover, setCover] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [title, setTitle] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const actionsButtons = (currentComment) => (
    <React.Fragment>
      <Button
        onClick={() => {
          setCommentToRemove(currentComment);
          setModal(true);
        }}
        color="danger"
        className={classes.actionButton}
      >
        <DeleteIcon className={classes.icon} />
      </Button>
    </React.Fragment>
  );

  async function getComments() {
    try {
      const commentsFromServer = (
        await api.get(`/blog/posts/${postId}/comments`)
      ).data;
      setComments(
        commentsFromServer.map((row) => [row.comentario, actionsButtons(row)])
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function getDatas() {
    try {
      setError(null);
      setLoading(true);

      const postFromServer = (await api.get(`/blog/posts/${postId}`)).data;
      setTitle(postFromServer.titulo);
      setInitialContent(postFromServer.texto);

      if (postFromServer.id_capa) {
        setCover(postFromServer.id_capa);
      }

      setComments(
        postFromServer.comments.map((row) => [
          row.comentario,
          actionsButtons(row),
        ])
      );
    } catch (error) {
      setError("Ocorreu um erro enquanto a postagem era carregada...");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => getDatas(page, rowsPerPage), []);

  function onChangePage(event, page) {
    setPage(page);
    getDatas(page, rowsPerPage);
  }

  function onChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    getDatas(page, event.target.value);
  }

  async function removeComment() {
    try {
      setLoadingDelete(true);

      await api.delete(`/blog/posts/comments/${commentToRemove.id}`);

      await getComments();
    } catch (error) {
      console.log(error);
      setError("Ocorreu um erro enquanto o comentário era removido...");
    } finally {
      setLoadingDelete(false);
      setModal(false);
    }
  }

  async function savePost() {
    if (title) {
      try {
        setError("");
        setLoading(true);
        const formData = new FormData();
        formData.append("img", fileRef.current?.files[0]);

        let response = undefined;
        if (cover && fileRef.current?.value) {
          await api.delete(`/blog/images/${cover}`);
          response = await api.post("/blog/images", formData);
        }

        await api.put(`/blog/posts/${postId}`, {
          titulo: title,
          id_capa: response?.data?.id,
          texto: editor.current.getContents(true),
        });

        history.push("/admin/blog");
      } catch (error) {
        console.log(error);
        console.log(error?.data);
        setError("Ocorreu um erro enquanto a postagem estava sendo salva...");
      } finally {
        setLoading(false);
      }
    } else {
      setError("Escolha um titulo para a postagem...");
    }
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Editar postagem</h4>
              <p className={classes.cardCategoryWhite}>
                Edição das informações da postagem
              </p>
            </CardHeader>

            <CardBody>
              {error && (
                <p
                  style={{
                    color: dangerColor[0],
                    fontWeight: "bold",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {error}
                </p>
              )}

              <GridContainer>
                <GridItem xs={12}>
                  <CustomInput
                    labelText="Título da postagem"
                    id="title"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: title,
                      onChange: (e) => setTitle(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem style={{ zIndex: 1 }} xs={12}>
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: -6,
                      padding: 0,
                      fontWeight: "bold",
                      color: warningColor[1],
                    }}
                  >
                    Imagem para a capa
                  </p>
                </GridItem>
                <UploadImage
                  defaultImageUrl={
                    cover
                      ? `${api.defaults.baseURL}/blog/images/${cover}`
                      : undefined
                  }
                  ref={fileRef}
                />

                <GridItem xs={12}>
                  {initialContent !== null && (
                    <SunEditor
                      defaultValue={initialContent}
                      lang="pt_br"
                      name="document"
                      getSunEditorInstance={(sunEditor) =>
                        (editor.current = sunEditor)
                      }
                      setOptions={{
                        minHeight: "400px",
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize"],
                          ["paragraphStyle", "blockquote"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor", "textStyle"],
                          ["removeFormat"],
                          ["outdent", "indent"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          ["table", "link", "image", "video"],
                          ["fullScreen"],
                          ["preview", "print"],
                        ],
                      }}
                    />
                  )}
                </GridItem>

                <h3>Comentários</h3>
                <Table
                  loading={loading}
                  tablePagination
                  tablePaginationInfo={{
                    rowsPerPage,
                    page,
                    total: comments.length, // this will change when the api has a pagination
                    onChangePage,
                    onChangeRowsPerPage,
                  }}
                  tableHeaderColor="primary"
                  tableHead={["Texto", "Ações"]}
                  tableData={comments}
                />
              </GridContainer>
            </CardBody>

            <CardFooter>
              <Button
                disabled={loading}
                loading={loading}
                withProgress
                color="primary"
                onClick={savePost}
              >
                Salvar alterações
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>Excluir comentário</h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          <h5>
            Você realmente quer excluir o comentário{" "}
            {commentToRemove.comentario}?
          </h5>
        </DialogContent>

        <DialogActions
          className={
            modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
          }
        >
          <Button disabled={loadingDelete} onClick={() => setModal(false)}>
            Não
          </Button>

          <Button
            withProgress
            disabled={loadingDelete}
            loading={loadingDelete}
            color="danger"
            onClick={removeComment}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
