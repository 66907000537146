import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "services/api";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import { dangerColor } from "assets/jss/material-dashboard-react";

const useStyles = makeStyles({
  ...styles,
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px",
    },
  },
});

const useModalStyles = makeStyles(modalStyle);

export default function ListPlans() {
  const history = useHistory();
  const classes = useStyles();
  const modaClasses = useModalStyles();
  const [admins, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [modal, setModal] = useState(false);
  const [planToRemove, setplanToRemove] = useState("");
  const [error, setError] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [, /*totalPages*/ setTotalPages] = useState(0);

  const actionsButtons = (currentPlan) => (
    <React.Fragment>
      <Button color="success" className={classes.actionButton}>
        <Edit
          onClick={() => {
            history.push(`/admin/manage-plans/${currentPlan.id}/edit`);
          }}
          className={classes.icon}
        />
      </Button>

      <Button
        onClick={() => {
          setplanToRemove(currentPlan);
          setModal(true);
        }}
        color="danger"
        className={classes.actionButton}
      >
        <DeleteIcon className={classes.icon} />
      </Button>
    </React.Fragment>
  );

  useEffect(() => getDatas(page, rowsPerPage), []);

  async function getDatas(page, rowsPerPage) {
    try {
      setError(null);
      setLoading(true);

      const plansFromServer = (
        await api.get(`/plans?page=${page + 1}&size=${rowsPerPage}`)
      ).data;

      setPlans(
        plansFromServer.data.map((row) => [
          row.titulo,
          row.descricao,
          row.valor,
          row.months,
          actionsButtons(row),
        ])
      );

      setTotalItems(plansFromServer.totalItems);
      setTotalPages(plansFromServer.totalPages);
    } catch (error) {
      setError("Ocorreu um erro enquanto os planos eram carregados...");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function onChangePage(event, page) {
    setPage(page);
    getDatas(page, rowsPerPage);
  }

  function onChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    getDatas(page, event.target.value);
  }

  async function handleDeletePlan() {
    try {
      setError(null);
      setLoadingDelete(true);

      await api.delete(`/plans/${planToRemove.id}`);
      await getDatas(page, rowsPerPage);
    } catch (error) {
      console.log(error);
      console.log(error.response);
      setError("Ocorreu um erro enquanto tentavamos excluir um plano...");
    } finally {
      setLoadingDelete(false);
      setModal(false);
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Lista de planos</h4>
          <p className={classes.cardCategoryWhite}>Tabela com os planos</p>
        </CardHeader>

        <CardBody>
          {error && (
            <p
              style={{
                color: dangerColor[0],
                fontWeight: "bold",
                margin: 0,
                padding: 0,
              }}
            >
              {error}
            </p>
          )}

          <Table
            loading={loading}
            tablePagination
            tablePaginationInfo={{
              rowsPerPage,
              page,
              total: totalItems,
              onChangePage,
              onChangeRowsPerPage,
            }}
            tableHeaderColor="primary"
            tableHead={["Título", "Descrição", "Valor", "Meses", "Ações"]}
            tableData={admins}
          />
        </CardBody>
      </Card>

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>Excluir plano</h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          <h5>Você realmente quer excluir o plano {planToRemove.titulo}?</h5>
        </DialogContent>

        <DialogActions
          className={
            modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
          }
        >
          <Button disabled={loadingDelete} onClick={() => setModal(false)}>
            Não
          </Button>

          <Button
            withProgress
            disabled={loadingDelete}
            loading={loadingDelete}
            onClick={handleDeletePlan}
            color="danger"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
