// import React, { useState, useRef, useEffect } from "react";
import React, { useState, useEffect } from "react";
import api from "services/api";
import emailjs from "emailjs-com";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import UploadImage from "components/CustomUpload/ImageUpload";

import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";
import stylesDash from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { primaryColor } from "assets/jss/material-dashboard-react";
const useStyles = makeStyles({
  ...stylesDash,
  ...stylesSelect,
  reportText: {
    color: primaryColor[0],
    padding: "8px",
  },
  errorText: {
    color: "red",
    fontWeight: "bolder",
    marginLeft: "16px",
  },
  successText: {
    color: "green",
    fontWeight: "bolder",
    marginLeft: "16px",
  },
});

// EmailJS config
import {
  mailServiceId,
  mailUserId,
  errorReportTemplateId,
} from "assets/jss/Texts/siteLinks.js";

function ErrorReport() {
  const classes = useStyles();
  // const fileRef = useRef();

  const [email, setEmail] = useState("");
  const [document, setDocument] = useState("");
  const [documents, setDocuments] = useState([]);
  const [description, setDescription] = useState("");

  //errors
  const [message, setMessage] = useState(0);
  const [emailError, setEmailError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  useEffect(() => {
    async function loadDocuments() {
      try {
        const response = await api.get("/docs?page=1&size=10000");

        setDocuments(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    loadDocuments();
  }, []);

  function sendEmail() {
    setMessage(0);
    emailjs
      .send(
        mailServiceId,
        errorReportTemplateId,
        {
          email: email,
          document: document,
          message: description,
        },
        mailUserId
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmail("");
          setDescription("");
          setDocument("");
          setMessage(1);
        },
        (error) => {
          console.log("erro: " + error.text);
          setMessage(2);
        }
      );
  }

  function validateField(field, fieldError) {
    if (field === "") {
      fieldError(true);
      return false;
    } else {
      fieldError(false);
      return true;
    }
  }

  function errorSubmit() {
    if (
      validateField(email, setEmailError) &&
      validateField(description, setDescriptionError)
    ) {
      sendEmail();
    }
  }

  function switchMessage() {
    switch (message) {
      case 1:
        return (
          <h4 className={classes.successText}>
            Obrigado por nos informar. Iremos analisar e retonaremos assim que
            possível.
          </h4>
        );
      case 2:
        return (
          <h4 className={classes.errorText}>
            Algo deu errado. Tente novamente em alguns instantes
          </h4>
        );
      default:
        return null;
    }
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <h3 className={classes.reportText}>
            Encontrou um erro em um dos modelos? Nos informe por meio deste
            formulario
          </h3>
        </GridItem>
        <GridItem>{switchMessage()}</GridItem>
        <GridItem xs={12}>
          <CustomInput
            error={emailError}
            errorText={
              emailError ? "Preencha o campo com um e-mail válido" : ""
            }
            labelText="Informe seu e-mail"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              value: email,
              onChange: (e) => setEmail(e.target.value),
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="status" className={classes.selectLabel}>
              Selecione o documento com erro
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={document}
              onChange={(e) => setDocument(e.target.value)}
              inputProps={{
                name: "client",
                id: "client",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Selecionar documento
              </MenuItem>
              {documents.map((doc) => (
                <MenuItem
                  key={doc.id}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={doc.titulo}
                >
                  {doc.titulo}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            error={descriptionError}
            errorText={
              descriptionError
                ? "Preencha o campo de descrição do problema"
                : ""
            }
            labelText="Descrição do erro"
            id="document"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              rows: 3,
              type: "textarea",
              value: description,
              onChange: (e) => setDescription(e.target.value),
            }}
          />
        </GridItem>

        {/* <GridItem xs={12}>
          <h4>Se desejar, anexe aqui uma imagem do erro:</h4>
        </GridItem>
        <UploadImage ref={fileRef} /> */}

        <GridItem xs={12}>
          <Button color="primary" onClick={errorSubmit}>
            Enviar erro
          </Button>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}

export default ErrorReport;
