import React from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
// core components
// style components
import { hexToRgb, blackColor } from "assets/jss/material-dashboard-react";
const useStyles = makeStyles({
  card: {
    backgroundColor: "white",
    margin: "0px 16px",
    minWidth: "200px",
    minHeight: "140px",
    borderRadius: "12px",
    padding: "0px 8px",
    boxShadow:
      "0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.14), 0 7px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ",.4)",
    textAlign: "center",
  },
  icon: {
    marginTop: "16px",
  },
  title: {
    fontSize: "20px",
    paddingLeft: "8px",
    margin: "0px",
  },
});

export default function NewCard() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div
      className={classes.card}
      onClick={() => history.push(`/admin/acoes/new`)}
    >
      <IconButton
        className={classes.icon}
        onClick={() => history.push(`/admin/acoes/new`)}
      >
        <AddCircleOutline style={{ fontSize: "48px", color: "black" }} />
      </IconButton>
      <p className={classes.title}>Criar nova ação</p>
    </div>
  );
}
