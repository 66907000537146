import React, { useState, useRef, useEffect } from "react";
import SunEditor from "suneditor-react";
import { useParams, useHistory } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
// @material-ui/core
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// import Info from "components/MyDocuments/DocumentInfo.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";
import stylesDash from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import api from "services/api";
import moment from "moment";

const useStyles = makeStyles({
  ...styles,
  ...stylesSelect,
  ...stylesDash,
  statusMargin: {
    marginTop: "12px",
  },
  fieldsMargin: {
    marginTop: "-20px",
  },
  displayContainer: {
    display: "flex",
    flexDirection: "row",
  },
  displayItem: {
    marginRight: "12px",
  },
  infoDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "16px",
  },
  adminManage: {
    marginBottom: "12px",
  },
});

const useModalStyles = makeStyles(modalStyle);

export default function EditDocument() {
  const classes = useStyles();
  const modaClasses = useModalStyles();
  const editor = useRef();
  const history = useHistory();
  let { id: documentId } = useParams();

  const [previewModal, setPreviewModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const [company, setCompany] = useState("");
  const [date, setDate] = useState("");
  const [deadline, setDeadline] = useState("");
  const [nr, setNr] = useState("");
  const [initialContent, setInitialContent] = useState(null);
  const [availableFor, setAvailableFor] = useState("PUBLIC");
  const [loading, setLoading] = useState(false);
  const [isAdmin] = useState(localStorage.getItem("@isAdmin"));

  useEffect(() => {
    async function loadCategories() {
      console.log("Edição padrão");
      try {
        const response = await api.get("/docs/categories");

        setCategories(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    async function loadDocument() {
      setLoading(true);
      try {
        const response = await api.get(`/docs/${documentId}`);

        setTitle(response.data.titulo);
        setStatus(response.data.status);
        setCompany(response.data.empresa);
        setDate(moment(response.data.data).format("YYYY-MM-DD"));
        setDeadline(moment(response.data.prazo).format("YYYY-MM-DD"));
        setAvailableFor(response.data.tipo);
        setNr(response.data.nr);
        setInitialContent(response.data.texto);
        setCategory(response.data.category.id);
        console.log(response.data.category);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadCategories();
    loadDocument();
  }, []);

  async function saveDocument() {
    try {
      setLoading(true);

      await api.put(`/docs/${documentId}`, {
        titulo: title,
        base: isAdmin ? true : false,
        nr: isAdmin ? undefined : nr,
        empresa: isAdmin ? undefined : company,
        tipo: isAdmin ? availableFor : undefined,
        status: status,
        prazo: isAdmin ? undefined : moment(deadline),
        id_categoria: isAdmin && category !== "" ? category : undefined,
        data: isAdmin ? undefined : moment(date),
        texto: editor.current.getContents(true),
      });

      history.push("/admin/documentos");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Editar documento</h4>
          <p className={classes.cardCategoryWhite}>
            Informe as caracteristicas de seu documento
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem container>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  labelText="Titulo"
                  id="title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: title,
                    onChange: (e) => setTitle(e.target.value),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <div className={classes.statusMargin}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="status"
                      className={classes.selectLabel}
                    >
                      Status
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      inputProps={{
                        name: "status",
                        id: "status",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Selecione o status do documento
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="rascunho"
                      >
                        Rascunho
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="finalizado"
                      >
                        Finalizado
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="assinado"
                      >
                        Assinado
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="vigente"
                      >
                        Vigente
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="expirado"
                      >
                        Expirado
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </GridItem>
            </GridItem>
            <GridItem container>
              {!isAdmin ? (
                <GridItem xs={12}>
                  <CustomInput
                    labelText="Empresa"
                    id="company"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: company,
                      onChange: (e) => setCompany(e.target.value),
                    }}
                  />
                </GridItem>
              ) : null}
            </GridItem>
            {isAdmin ? (
              <GridItem container extraClass={classes.adminManage}>
                <GridItem xs={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="availableFor"
                      className={classes.selectLabel}
                    >
                      Disponível para:
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={availableFor}
                      onChange={(e) => setAvailableFor(e.target.value)}
                      inputProps={{
                        name: "availableFor",
                        id: "availableFor",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Selecione a disponibilidade do documento
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="PUBLIC"
                      >
                        Plano gratuito
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="PRIVATE"
                      >
                        Plano premium
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="category"
                      className={classes.selectLabel}
                    >
                      Categoria
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      inputProps={{
                        name: "category",
                        id: "category",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Selecione a categoria do documento
                      </MenuItem>
                      {categories.map((c) => (
                        <MenuItem
                          key={c.id}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={c.id}
                        >
                          {c.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridItem>
            ) : (
              <GridItem container>
                <GridItem xs={8} sm={4}>
                  <div className={classes.displayContainer}>
                    <h4 className={classes.displayItem}>Data:</h4>
                    <div className={classes.fieldsMargin}>
                      <CustomInput
                        id="date"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "date",
                          value: date,
                          onChange: (e) => setDate(e.target.value),
                        }}
                      />
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={8} sm={4}>
                  <div className={classes.displayContainer}>
                    <h4 className={classes.displayItem}>Prazo:</h4>
                    <div className={classes.fieldsMargin}>
                      <CustomInput
                        id="date"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "date",
                          value: deadline,
                          onChange: (e) => setDeadline(e.target.value),
                        }}
                      />
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={7} sm={4}>
                  <div className={classes.displayContainer}>
                    <h4 className={classes.displayItem}>NR:</h4>
                    <div className={classes.fieldsMargin}>
                      <CustomInput
                        id="nr"
                        formControlProps={{
                          fullWidth: false,
                        }}
                        inputProps={{
                          type: "text",
                          value: nr,
                          onChange: (e) => setNr(e.target.value),
                        }}
                      />
                    </div>
                  </div>
                </GridItem>
              </GridItem>
            )}
          </GridContainer>

          {initialContent !== null && (
            <SunEditor
              defaultValue={initialContent}
              lang="pt_br"
              name="document"
              getSunEditorInstance={(sunEditor) => (editor.current = sunEditor)}
              setOptions={{
                minHeight: "400px",
                buttonList: [
                  ["undo", "redo"],
                  ["font", "fontSize"],
                  ["paragraphStyle", "blockquote"],
                  [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                  ],
                  ["fontColor", "hiliteColor", "textStyle"],
                  ["removeFormat"],
                  ["outdent", "indent"],
                  ["align", "horizontalRule", "list", "lineHeight"],
                  ["table", "link", "image", "video"],
                  ["fullScreen"],
                  ["preview", "print"],
                ],
              }}
            />
          )}

          <GridContainer extraClass={classes.buttonDiv}>
            <GridItem>
              <Button
                color="success"
                withProgress
                loading={loading}
                disabled={loading}
                round
                onClick={() => setPreviewModal(true)}
              >
                Salvar
              </Button>
            </GridItem>
            {/* <GridItem>
              <Button color="danger" round>
                Descartar alterações
              </Button>
            </GridItem> */}
          </GridContainer>
        </CardBody>
      </Card>

      {/* modal de preview */}
      <React.Fragment>
        <Dialog
          classes={{
            root: modaClasses.center,
            paper: modaClasses.modal,
          }}
          open={previewModal}
          onClose={() => setPreviewModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="modal-slide-title"
            disableTypography
            className={modaClasses.modalHeader}
          >
            <Button
              justIcon
              className={modaClasses.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setPreviewModal(false)}
            >
              <Close className={modaClasses.modalClose} />
            </Button>
            <h4 className={modaClasses.modalTitle}>Documento</h4>
          </DialogTitle>

          <DialogContent
            id="modal-slide-description"
            className={modaClasses.modalBody}
          >
            {editor.current && (
              <div
                dangerouslySetInnerHTML={{
                  __html: editor.current.getContents(true),
                }}
              />
            )}
          </DialogContent>

          <DialogActions
            className={
              modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
            }
          >
            <Button
              withProgress
              loading={loading}
              disabled={loading}
              color="success"
              onClick={saveDocument}
            >
              SALVAR EM MINHA BIBLIOTECA
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  );
}
