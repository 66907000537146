import React, { useState, useEffect } from "react";
import api from "services/api";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import DeleteIcon from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
// core components
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import AdminRestriction from "components/AccessWarning/AdminRestriction.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
const useModalStyles = makeStyles(modalStyle);
const useStyles = makeStyles({
  ...styles,
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px",
    },
  },
  messageBox: {
    backgroundColor: "#eee",
    borderRadius: "12px",
    padding: "12px",
    marginTop: "16px",
  },
});

export default function NewsletterList() {
  const classes = useStyles();
  const modaClasses = useModalStyles();
  const [isAdmin] = useState(localStorage.getItem("@adminType") == 1);
  const [modal, setModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [error, setError] = useState("");
  const [emailToRemove, setEmailToRemove] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const actionsButtons = (current) => (
    <Button
      onClick={() => {
        setEmailToRemove(current);
        setModal(true);
      }}
      color="danger"
      className={classes.actionButton}
    >
      <DeleteIcon className={classes.icon} />
    </Button>
  );

  async function getDatas() {
    try {
      setError(null);
      setLoading(true);

      const usersFromServer = (await api.get("/newsletter")).data;
      console.log(usersFromServer);
      setUsers(
        usersFromServer.map((row) => [row.nome, row.email, actionsButtons(row)])
      );
      if (usersFromServer.length == 0) {
        setError("Nenhum email encontrado");
      }
    } catch (error) {
      setError("Ocorreu um erro enquanto os emails eram carregados...");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => getDatas(page, rowsPerPage), []);

  function onChangePage(event, page) {
    setPage(page);
    getDatas(page, rowsPerPage);
  }

  function onChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    getDatas(page, event.target.value);
  }

  async function handleDeleteEmail() {
    try {
      setError(null);
      setLoadingDelete(true);

      console.log("Delete: " + emailToRemove.email);
      await api.delete(`/newsletter`, { data: { email: emailToRemove.email } });
      await getDatas();
    } catch (error) {
      console.log(error);
      console.log(error.response);
      setError("Ocorreu um erro enquanto tentavamos excluir essa entrada...");
    } finally {
      setLoadingDelete(false);
      setModal(false);
    }
  }

  return (
    <React.Fragment>
      {isAdmin ? (
        <GridItem>
          <p
            style={{
              color: "red",
              fontWeight: "bolder",
              margin: 0,
              padding: 0,
            }}
          >
            {error}
          </p>
          <Table
            loading={loading}
            tablePagination
            tablePaginationInfo={{
              rowsPerPage,
              page,
              total: users.length, // this will change when the api has a pagination
              onChangePage,
              onChangeRowsPerPage,
            }}
            tableHeaderColor="primary"
            tableHead={["Nome", "Email", "Apagar"]}
            tableData={users}
          />
        </GridItem>
      ) : (
        <AdminRestriction />
      )}

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-slide-title"
          disableTypography
          className={modaClasses.modalHeader}
        >
          <Button
            justIcon
            className={modaClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={modaClasses.modalClose} />
          </Button>
          <h4 className={modaClasses.modalTitle}>Excluir entrada</h4>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          <h5>
            Você realmente quer excluir o email de {emailToRemove.nome} {""}
            da lista?
          </h5>
        </DialogContent>

        <DialogActions
          className={
            modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
          }
        >
          <Button disabled={loadingDelete} onClick={() => setModal(false)}>
            Não
          </Button>

          <Button
            withProgress
            disabled={loadingDelete}
            loading={loadingDelete}
            onClick={handleDeleteEmail}
            color="danger"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
