import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Instagram from "@material-ui/icons/Instagram";
import Facebook from "@material-ui/icons/Facebook";
import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import {
  facebookLink,
  instagramLink,
  emailLink,
} from "assets/jss/Texts/siteLinks.js";

import logo from "assets/img/homepage/AZ_LOGO_PREENCHIDA.png";
import { cnpj } from "assets/jss/Texts/siteTexts.js";
import {
  footerStyle,
  homepageStyle,
} from "assets/jss/material-dashboard-react/components/homepageStyle.js";
const useStyles = makeStyles({ ...footerStyle, ...homepageStyle });
const dinamicStyle = makeStyles((theme) => ({
  forhandsLink: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const fhClasses = dinamicStyle();

  return (
    <GridContainer extraClass={classes.foot}>
      <GridItem container>
        <GridItem xs={12} md={2}>
          <GridContainer extraClass={classes.center}>
            <GridItem xs={12}>
              <img
                src={logo}
                alt="logo da plataforma"
                className={classes.logo}
              />
            </GridItem>
            <GridItem container justify="space-around">
              <GridItem xs={4}>
                <IconButton
                  aria-label="icone instagram"
                  onClick={() => window.open(instagramLink)}
                >
                  <Instagram
                    fontSize="large"
                    classes={{ root: classes.socialMediaIcon }}
                  />
                </IconButton>
              </GridItem>
              <GridItem xs={4}>
                <IconButton
                  aria-label="icone facebook"
                  onClick={() => window.open(facebookLink)}
                >
                  <Facebook
                    fontSize="large"
                    classes={{ root: classes.socialMediaIcon }}
                  />
                </IconButton>
              </GridItem>
              <GridItem xs={4}>
                <IconButton
                  aria-label="icone email"
                  onClick={() => window.open(emailLink)}
                >
                  <Email
                    fontSize="large"
                    classes={{ root: classes.socialMediaIcon }}
                  />
                </IconButton>
              </GridItem>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={1} />
        <GridItem xs={12} md={3} extraClass={classes.infos}>
          <h4 className={classes.title}>Sobre nós</h4>
          <a href="/auth/solucoes" className={classes.whiteText}>
            Sobre a plataforma
          </a>
          <br />
          <a href="/auth/contato" className={classes.whiteText}>
            Fale conosco
          </a>
        </GridItem>
        <GridItem xs={12} md={3} extraClass={classes.infos}>
          <h4 className={classes.title}>Legal</h4>
          <a href="/blog/policy" className={classes.whiteText}>
            Politica de privacidade
          </a>
          <br />
          <a href="/blog/terms" className={classes.whiteText}>
            Termos de uso
          </a>
        </GridItem>
        <GridItem xs={12} md={3} extraClass={classes.infos}>
          <h4 className={classes.title}>Ajuda</h4>
          <a href="/blog" className={classes.whiteText}>
            Blog
          </a>
          <br />
          <a href="/auth/contato" className={classes.whiteText}>
            {/* Tutoriais */}
            Contato
          </a>
        </GridItem>
      </GridItem>
      <GridItem xs={12}>
        <Divider classes={{ root: classes.divider }} />
      </GridItem>
      <GridItem container>
        <GridItem xs={12} sm={6}>
          <p>
            AZsafe - CNPJ: {cnpj} - Todos os direitos reservados &copy;{" "}
            {1900 + new Date().getYear()}
          </p>
        </GridItem>
        <GridItem xs={12} sm={6} extraClass={fhClasses.forhandsLink}>
          <p>
            Desenvolvido por{" "}
            <a
              href="https://forhands.net"
              target="_blank"
              rel="noreferrer"
              className={classes.whiteText}
            >
              For Hands LTDA
            </a>
          </p>
        </GridItem>
      </GridItem>
    </GridContainer>
  );
}
