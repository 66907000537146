import { container } from "assets/jss/material-dashboard-react.js";

const authStyle = () => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    width: "100%",
  },
  content: {
    padding: "5px 5px",
    minHeight: "calc(100vh - 60px)",
  },
  container,
});

export default authStyle;
