import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { login, adminLogin } from "store/actions/auth";
import { useHistory } from "react-router-dom";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import ArrowBack from "@material-ui/icons/ArrowBack";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import LoginForm from "./LoginForm";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles((theme) => ({
  ...styles,
  textCenter: {
    textAlign: "center",
  },
  loginLink: {
    textAlign: "center",
    fontWeight: "bolder",
    fontSize: "16px",
    marginTop: "24px",
    marginBottom: "8px",
    cursor: "pointer",
  },
  logoImage: {
    margin: "24px 0px",
    paddingLeft: "88px",
    maxWidth: "60%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "64px",
    },
  },
}));

import logo from "assets/img/homepage/AZ_LOGO_HEADER.png";

function LoginCard({ isAdminLogin }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [empty, setEmpty] = useState("");

  const isNotEmpty = (details) => {
    return details.email !== "" && details.password !== "";
  };

  const callLogin = (details) => {
    if (isNotEmpty(details)) {
      setEmpty("");

      if (!isAdminLogin) {
        dispatch(
          login({
            username: details.email,
            password: details.password,
          })
        );
      } else {
        dispatch(
          adminLogin({
            username: details.email,
            password: details.password,
          })
        );
      }
    } else {
      setEmpty("Você precisa passar as credenciais");
    }
  };

  return (
    <GridContainer justify="center">
      <GridItem xs={12}>
        <Button
          color="primary"
          simple
          size="lg"
          onClick={() => history.push(`/auth/home`)}
        >
          <ArrowBack />
          Voltar ao inicio
        </Button>
      </GridItem>
      <GridItem>
        <img src={logo} className={classes.logoImage} />
      </GridItem>
      <GridItem xs={12} />
      <GridItem xs={12} sm={8} md={6}>
        <Card>
          <CardHeader color="primary">
            {isAdminLogin ? (
              <h2 className={`${classes.cardTitleWhite} ${classes.textCenter}`}>
                Login de Administrador
              </h2>
            ) : (
              <h1 className={`${classes.cardTitleWhite} ${classes.textCenter}`}>
                Login
              </h1>
            )}
          </CardHeader>
          <CardBody>
            <LoginForm empty={empty} callLogin={callLogin} />
            <GridItem xs={12} extraClass={classes.loginLink}>
              <a onClick={() => history.push(`/auth/sign-up`)}>
                Ainda não tem conta? Cadastre-se!
              </a>
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

LoginCard.propTypes = {
  isAdminLogin: PropTypes.bool,
};

export default LoginCard;
