import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import PropTypes from "prop-types";

function EditorViewer({ initialContent, enableButtons = true }) {
  return (
    <SunEditor
      readOnly={true}
      defaultValue={initialContent}
      lang="pt_br"
      name="document"
      setOptions={{
        resizingBar: false,
        height: "100%",
        buttonList: enableButtons ? [["preview", "print"]] : [],
      }}
    />
  );
}

EditorViewer.propTypes = {
  initialContent: PropTypes.string.isRequired,
  enableButtons: PropTypes.bool,
};

export default EditorViewer;
