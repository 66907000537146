import api from "services/api";
import moment from "moment";

import { logout } from "./auth";

export const SET_USER = "USER_SET_USER";
export const LOAD_USER = "USER_LOAD_USER";
export const LOAD_ADMIN = "USER_LOAD_ADMIN";
export const SET_LOADING = "USER_SET_LOADING";
export const SET_ERROR = "USER_SET_ERROR";

export function setError(error) {
  return {
    type: SET_ERROR,
    error,
  };
}

export function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    isLoading,
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

export function loadUser() {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const userId = localStorage.getItem("@userId");

      const user = (await api.get(`/users/${userId}`)).data;
      const address = (await api.get(`/users/${userId}/addresses`)).data;
      const wallet = (await api.get(`/users/${userId}/cards`)).data;

      let paymentStatus = "";
      let monthsLeft = 0;

      if (wallet?.id_assinatura?.length > 0) {
        const chargeStatusPromise = api.get(
          `/payment/plans/${wallet?.id_assinatura[0]}?noExtraDatas=true`
        );

        const planPromise =
          user.plano !== 0
            ? api.get(`/plans/${user.plano}`)
            : { data: { months: 0 } }; // free plan

        const [
          { data: chargeStatus },
          { data: planDatas },
        ] = await Promise.all([chargeStatusPromise, planPromise]);

        paymentStatus = chargeStatus.status;
        const usedMonths = moment().diff(
          moment(wallet?.id_assinatura[0].dueDate),
          "months"
        );
        monthsLeft = planDatas.months - usedMonths;
      }

      dispatch({
        type: LOAD_USER,
        user: {
          id: user.id,
          name: user.nome,
          email: user.email,
          gender: user.genero,
          birthDate: moment(user.data_nascimento),
          personType: user.cpf ? "CPF" : "CNPJ",
          personIdentifier: user.cpf || user.cnpj,
          planId: user.plano,
          freeTest: user.free_test,
          isBlocked: user.bloqueado,
          paymentStatus,
          monthsLeft,
          createdAt: moment(user.createdAt),
          updatedAt: moment(user.updatedAt),
        },
        address: {
          id: address.id,
          zipCode: address.cep,
          state: address.estado,
          city: address.cidade,
          neighborhood: address.bairro,
          street: address.rua,
          number: address.numero,
          complement: address.complemento,
          createdAt: address.createdAt,
          updatedAt: address.updatedAt,
        },
        wallet: {
          charges: wallet.id_assinatura,
          oldCharges: wallet.old_charges,
        },
      });
    } catch (error) {
      console.log(error);
      dispatch(
        setError(
          "Ocorreu um erro enquanto os seus dados estavam sendo carregados. Iremos fazer o seu logout no sistema em alguns segundos."
        )
      );

      setTimeout(() => {
        dispatch(logout());
        dispatch(setError(null));
      }, 10000); //10s
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function updateUser(user) {
  return async (dispatch, getState) => {
    try {
      const {
        user: {
          userProfile: { personType },
        },
      } = getState();
      const { address } = user;
      dispatch(setLoading(true));
      const userId = localStorage.getItem("@userId");

      await api.put(`/users/${userId}`, {
        nome: user.name,
        email: user.email,
        genero: user.gender,
        data_nascimento: moment(user.birthDate),
        cpf: personType === "CPF" ? user.personIdentifier : undefined,
        cnpj: personType !== "CPF" ? user.personIdentifier : undefined,
      });
      await api.put(`/users/${userId}/addresses`, {
        cep: address.zipCode,
        estado: address.state,
        cidade: address.city,
        bairro: address.neighborhood,
        rua: address.street,
        numero: address.number,
        complemento: address.complement,
      });

      if (user.password) {
        await api.put(`/users/${userId}/password`, {
          senha: user.oldPassword,
          nova_senha: user.password,
        });
      }

      user.birthDate = moment(user.birthDate);
      dispatch(setUser(user));
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);

      if (error.response?.data?.error === "Senha invalida") {
        dispatch(setError("Senha atual incorreta..."));
      } else {
        dispatch(
          setError(
            "Ocorreu um erro enquanto os seus dados eram atualizados. Tente novamente!"
          )
        );
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function loadAdmin() {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const userId = localStorage.getItem("@userId");

      const user = (await api.get(`/admins/${userId}`)).data;

      dispatch({
        type: LOAD_ADMIN,
        admin: {
          id: user.id,
          name: user.nome,
          email: user.email,
          adminType: user.tipo,
          createdAt: moment(user.createdAt),
          updatedAt: moment(user.updatedAt),
        },
      });
    } catch (error) {
      console.log(error);
      dispatch(
        setError(
          "Ocorreu um erro enquanto os seus dados estavam sendo carregados. Iremos fazer o seu logout no sistema em alguns segundos."
        )
      );

      setTimeout(() => {
        dispatch(logout());
        dispatch(setError(null));
      }, 10000); //10s
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function updateAdmin(user) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const userId = localStorage.getItem("@userId");

      await api.put(`/admins/${userId}`, {
        nome: user.name,
        email: user.email,
        tipo: user.adminType,
      });

      if (user.password) {
        await api.put(`/admins/${userId}/password`, {
          senha: user.oldPassword,
          nova_senha: user.password,
        });
      }

      dispatch(setUser(user));
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);

      if (error.response?.data?.error === "Senha invalida") {
        dispatch(setError("Senha atual incorreta..."));
      } else {
        dispatch(
          setError(
            "Ocorreu um erro enquanto os seus dados eram atualizados. Tente novamente!"
          )
        );
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
}
