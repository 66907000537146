import React, { useState } from "react";
import emailjs from "emailjs-com";
// material-ui core
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// style components
import { makeStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  successColor,
} from "assets/jss/material-dashboard-react";
const useStyles = makeStyles({
  header: {
    padding: "4px 8px",
    fontWeight: "bolder",
  },
  content: {
    margin: "0px 16px",
  },
  buttonDiv: {
    margin: "16px",
  },
  progress: {
    margin: "16px",
    color: primaryColor[0],
  },
  submittedTitle: {
    marginTop: "32px",
    fontWeight: "bolder",
    textAlign: "center",
    color: successColor[2],
  },
  submittedSubtitle: {
    margin: "32px 48px",
    fontWeight: "bolder",
    textAlign: "center",
    fontSize: "16px",
  },
});

// EmailJS config
import {
  mailServiceId,
  mailUserId,
  feedbackTemplateId,
} from "assets/jss/Texts/siteLinks.js";

export default function FeedbackCard() {
  const classes = useStyles();
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  function sendEmail() {
    emailjs
      .send(
        mailServiceId,
        feedbackTemplateId,
        {
          message: description,
        },
        mailUserId
      )
      .then(
        (result) => {
          console.log(result.text);
          setDescription("");
          setLoading(false);
          setSubmitted(true);
        },
        (error) => {
          console.log("erro: " + error.text);
        }
      );
  }

  function sendFeedback() {
    if (description === "") setError(true);
    else {
      setError(false);
      setLoading(true);
      sendEmail();
      setTimeout(function () {
        setDescription("");
        setLoading(false);
        setSubmitted(true);
      }, 2000);
    }
  }

  return (
    <GridContainer justify="center">
      <GridItem xs={10}>
        {submitted ? (
          <Card>
            <GridItem xs={12}>
              <h3 className={classes.submittedTitle}>
                Agradeçemos sua colaboração!
              </h3>
            </GridItem>
            <GridItem xs={12}>
              <p className={classes.submittedSubtitle}>
                Sua mensagem foi enviada e será analisada pela nossa equipe.
                Toda ajuda é muito importante para melhorarmos nossos serviços
                cada vez mais. Se restar alguma dúvida entre em contato conosco
                pelo email
                <a href="mailto:contato@azsafe.com"> contato@azsafe.com </a>
              </p>
            </GridItem>
          </Card>
        ) : (
          <Card>
            <GridItem xs={12}>
              <h3 className={classes.header}>
                O que tem achado de nosso sistema?
              </h3>
            </GridItem>
            <GridItem xs={12}>
              <h4 className={classes.content}>
                Para melhorarmos nossos serviços contamos com seu apoio. Nos
                informe suas opiniões e como acha que podemos melhorar.
              </h4>
            </GridItem>
            <GridItem xs={12} extraClass={classes.content}>
              {loading ? (
                <CircularProgress
                  className={classes.progress}
                  size={40}
                  thickness={4}
                  value={100}
                />
              ) : (
                <CustomInput
                  error={error}
                  labelText="Descreva suas opiniões"
                  id="description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 3,
                    type: "text",
                    value: description,
                    onChange: (e) => setDescription(e.target.value),
                  }}
                />
              )}
            </GridItem>
            <GridItem extraClass={classes.buttonDiv}>
              <Button
                loading={loading}
                disabled={loading}
                color="primary"
                onClick={() => sendFeedback()}
              >
                Enviar
              </Button>
            </GridItem>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
}
