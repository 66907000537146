import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
const useModalStyles = makeStyles(modalStyle);
import { primaryColor } from "assets/jss/material-dashboard-react";
const useStyles = makeStyles({
  red: { backgroundColor: "red" },
  green: { backgroundColor: "green" },
  blue: { backgroundColor: "blue" },
  externalLink: {
    color: primaryColor[0],
    cursor: "pointer",
  },
});
import { contactEmail } from "assets/jss/Texts/siteLinks.js";

export default function BlockWarning({ modalOpen, setModalOpen }) {
  const history = useHistory();
  const classes = useStyles();
  const modaClasses = useModalStyles();

  return (
    <Dialog
      classes={{
        root: modaClasses.center,
        paper: modaClasses.modal,
      }}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <GridContainer>
        <React.Fragment>
          <GridItem xs={12}>
            <DialogTitle
              id="modal-slide-title"
              disableTypography
              className={modaClasses.modalHeader}
            >
              <h4 className={modaClasses.modalTitle}>Usuário bloqueado</h4>
            </DialogTitle>
          </GridItem>
          <DialogContent
            id="modal-slide-description"
            className={modaClasses.modalBody}
          >
            <GridItem container justify="center">
              <GridItem xs={12}>
                <p>
                  Aparentemente seu acesso foi bloqueado pelo sistema por algum
                  motivo que inflinge nossos{" "}
                  <a
                    className={classes.externalLink}
                    onClick={() => window.open("/auth/terms")}
                  >
                    Termos de uso
                  </a>
                  {". "}
                  Se acha que foi um engano entre em contato com a gente pelo
                  email {contactEmail} pra esclarecer o caso.
                </p>
              </GridItem>
            </GridItem>
          </DialogContent>
          <DialogActions
            className={
              modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
            }
          >
            <Button onClick={() => history.push(`auth/home`)} color="primary">
              Voltar ao início
            </Button>
          </DialogActions>
        </React.Fragment>
      </GridContainer>
    </Dialog>
  );
}

BlockWarning.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
};
