import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "services/api";
//@material-ui/core components
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
//core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TutorialCard from "components/TutorialCard/TutorialCard.js";

import { primaryColor } from "assets/jss/material-dashboard-react";
const useStyles = makeStyles({
  title: {
    textAlign: "center",
    fontWeight: "bolder",
  },
  homeLink: {
    cursor: "pointer",
    marginRight: "-8px",
  },
  thisTab: {
    marginLeft: "-8px",
    color: primaryColor[0],
    fontWeight: "bolder",
  },
  errorText: {
    color: "red",
    fontWeight: "bolder",
  },
  button: {
    marginTop: "32px",
  },
  loadingProgress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 100,
    color: primaryColor[0],
  },
});

export default function Tutoriais() {
  const history = useHistory();
  const classes = useStyles();
  const [datas, setDatas] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  async function getDatas() {
    try {
      setError(null);
      setLoading(true);

      const dataFromServer = (await api.get("/tutorials")).data;
      setDatas(
        dataFromServer.map((row) => [row.titulo, row.descricao, row.midia])
      );
      if (dataFromServer.length == 0) {
        setError("Nenhum tutorial encontrado");
      }
    } catch (error) {
      setError("Ocorreu um erro enquanto os tutoriais eram carregados...");
      console.log(error);
    } finally {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => getDatas(), []);

  function tutorialTable() {
    return (
      <GridItem xs={12}>
        <Table aria-label="tabela de tutoriais">
          <TableBody>
            {datas.map((prop, key) => {
              console.log("prop " + key + ": " + prop);
              return (
                <TableRow key={key}>
                  <TableCell component="th" scope="row" key={key}>
                    <TutorialCard
                      title={prop[0]}
                      text={prop[1]}
                      video={prop[2]}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </GridItem>
    );
  }

  return (
    <div>
      <GridContainer justify="center">
        <GridItem container>
          <GridItem>
            <p
              className={classes.homeLink}
              onClick={() => {
                history.push(`/auth/home`);
              }}
            >
              Home
            </p>
          </GridItem>
          <GridItem>
            <p>-</p>
          </GridItem>
          <GridItem>
            <p className={classes.thisTab}>Tutoriais</p>
          </GridItem>
          <GridItem xs={12}>
            <Divider variant="fullWidth" />
          </GridItem>
        </GridItem>
        <GridItem container>
          <GridItem xs={12}>
            <h3 className={classes.title}>Tutoriais sobre nossa plataforma</h3>
          </GridItem>
          {!loading ? (
            <React.Fragment>{tutorialTable()}</React.Fragment>
          ) : (
            <GridItem xs={12}>
              <div className={classes.loadingProgress}>
                <CircularProgress style={{ color: primaryColor[0] }} />
              </div>
            </GridItem>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}
