/* eslint-disable no-unused-vars */
import React from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core
import { makeStyles } from "@material-ui/core";
// @material-ui/icons
import ArrowBack from "@material-ui/icons/ArrowBack";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import logo from "assets/img/homepage/AZ_LOGO_HEADER.png";
import approve from "assets/img/homepage/approve.png";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles((theme) => ({
  ...styles,
  textCenter: {
    textAlign: "center",
  },
  logoImage: {
    margin: "24px 0px",
    paddingLeft: "88px",
    maxWidth: "60%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "64px",
    },
  },
  imageContainer: {
    textAlign: "center",
  },
  approveImage: {
    maxWidth: "60%",
  },
  content: {
    textAlign: "center",
    margin: "0px 40px",
  },
  loginButton: {
    margin: "16px 0px",
  },
}));

export default function ConfirmEmailPage() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <GridContainer justify="center">
      <GridItem xs={12}>
        <Button
          color="primary"
          simple
          size="lg"
          onClick={() => history.push(`/auth/home`)}
        >
          <ArrowBack />
          Voltar ao inicio
        </Button>
      </GridItem>
      <GridItem>
        <img src={logo} className={classes.logoImage} />
      </GridItem>
      <GridItem xs={12} />
      <GridItem xs={12} sm={8} md={6}>
        <Card>
          <CardHeader color="primary">
            <h2 className={`${classes.cardTitleWhite} ${classes.textCenter}`}>
              Confirmação de Email
            </h2>
          </CardHeader>
          <CardBody>
            <GridContainer justify="center">
              <GridItem xs={12} extraClass={classes.imageContainer}>
                <img src={approve} className={classes.approveImage} />
              </GridItem>
              <GridItem xs={12} extraClass={classes.content}>
                <h4>
                  Parabéns. Seu email foi confirmado e você já está cadastrado
                  no AZsafe. Faça o login para começar a usar nosso sistema.
                </h4>
              </GridItem>
              <GridItem extraClass={classes.loginButton}>
                <Button
                  color="primary"
                  onClick={() => history.push("/auth/login")}
                >
                  Fazer login
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
