export default {
  title: {
    presence: {
      message: "Informe o título",
      allowEmpty: false,
    },
    length: {
      minimum: 4,
      message: "Título muito curto",
    },
  },
  description: {
    presence: {
      message: "Informe a descrição",
      allowEmpty: false,
    },
    length: {
      minimum: 4,
      message: "Descrição muito curta",
    },
  },
  value: {
    presence: {
      message: "Informe o valor",
      allowEmpty: false,
    },
  },
  installments: {
    presence: {
      allowEmpty: false,
      message: "Informe a quantidade de parcelas",
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      message: "Informe um número inteiro maior que 0",
    },
  },
  months: {
    presence: {
      allowEmpty: false,
      message: "Informe a quantidade de meses do plano",
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      message: "Informe um número inteiro maior que 0",
    },
  },
};
