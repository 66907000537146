import {
  SET_SIGNED,
  SET_ERROR,
  SET_LOADING,
  TOGGLE_MODAL,
} from "../actions/auth";

const INITIAL_STATE = {
  signed: false,
  loading: false,
  error: null,
  modalLogout: false,
};

const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SIGNED:
      return {
        ...state,
        signed: action.signed,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      };

    case TOGGLE_MODAL:
      return {
        ...state,
        modalLogout: !state.modalLogout,
      };

    default:
      return state;
  }
};

export default auth;
