import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setForm } from "store/actions/signUpForm";
import PropTypes from "prop-types";
import { validation } from "utils/validate";
import signUpRules from "../signUpRules";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import stylesCheckbox from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";

const useStyles = makeStyles({
  ...styles,
  ...stylesCheckbox,
  ...stylesSelect,
  containerBordered: {
    border: "#00000020 0.2px solid",
    borderRadius: "6px",
    marginTop: "14px !important",
  },
  subTitle: {
    fontSize: "25px",
  },
});

import { maskCPF } from "utils/masks";
import { maskCNPJ } from "utils/masks";

export default function InitialForm({ nextStep }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const signUpForm = useSelector((state) => state.signUpForm);
  const [name, setName] = useState(signUpForm.name);
  const [email, setEmail] = useState(signUpForm.email);
  const [gender, setGender] = useState(signUpForm.gender);
  const [personType, setPersonType] = useState(signUpForm.personType);
  const [birthDate, setBirthDate] = useState(signUpForm.birthDate);
  const [personIdentifier, setPersonIdentifier] = useState(
    signUpForm.personIdentifier
  );
  const [erros, setErros] = useState({});
  const [password, setPassword] = useState(signUpForm.password);
  const [confirmPassword, setConfirmPassword] = useState("");

  function isValid() {
    const result = validation(
      {
        email,
        name,
        personIdentifier,
        password,
        confirmPassword,
      },
      {
        email: signUpRules.email,
        name: signUpRules.name,
        personIdentifier: signUpRules.personIdentifier,
        password: signUpRules.password,
        confirmPassword: signUpRules.confirmPassword,
      }
    );

    setErros(result ? result : {});
    return result == null;
  }

  function handleNextStep() {
    if (isValid()) {
      dispatch(
        setForm({
          name,
          email,
          password,
          gender,
          personType,
          birthDate,
          personIdentifier,
        })
      );
      setErros({});

      nextStep();
    }
  }

  return (
    <React.Fragment>
      <GridContainer extraClass={classes.containerBordered}>
        <GridItem
          container
          justify="center"
          alignItems="center"
          xs={12}
          md={6}
          sm={12}
        >
          <h2 className={`${classes.cardTitle} ${classes.subTitle}`}>
            Tipo de cadastro
          </h2>
        </GridItem>

        <GridItem xs={12} md={6} sm={6}>
          <GridItem xs={12} md={12} sm={12}>
            <FormControlLabel
              control={
                <Radio
                  checked={personType === "CPF"}
                  onChange={() => {
                    setPersonIdentifier("");
                    setPersonType("CPF");
                  }}
                  value="CPF"
                  name="CPF"
                  aria-label="CPF"
                  icon={
                    <FiberManualRecord className={classes.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classes.radioChecked} />
                  }
                  classes={{
                    checked: classes.radio,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label="Pessoa Física"
            />
          </GridItem>

          <GridItem xs={12} md={12} sm={12}>
            <FormControlLabel
              control={
                <Radio
                  checked={personType === "CNPJ"}
                  onChange={() => {
                    setPersonIdentifier("");
                    setPersonType("CNPJ");
                  }}
                  value="CNPJ"
                  name="CNPJ"
                  aria-label="CNPJ"
                  icon={
                    <FiberManualRecord className={classes.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classes.radioChecked} />
                  }
                  classes={{
                    checked: classes.radio,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label="Pessoa Jurídica"
            />
          </GridItem>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem sm={12} md={6}>
          <CustomInput
            error={erros.name}
            errorText={erros.name}
            labelText="Nome"
            id="name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              value: name,
              onChange: (e) => setName(e.target.value),
            }}
          />
        </GridItem>

        <GridItem sm={12} md={6}>
          <CustomInput
            error={erros.email}
            errorText={erros.email}
            labelText="Email"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "email",
              value: email,
              onChange: (e) => setEmail(e.target.value),
            }}
          />
        </GridItem>

        {personType === "CPF" && (
          <React.Fragment>
            <GridItem container alignItems="center" xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Data de nascimento"
                id="birth date"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "date",
                  value: birthDate,
                  onChange: (e) => setBirthDate(e.target.value),
                }}
              />
            </GridItem>

            <GridItem container alignItems="center" xs={12} md={6} sm={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="gender" className={classes.selectLabel}>
                  Gênero
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  inputProps={{
                    name: "gender",
                    id: "gender",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Selecione o gênero
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="male"
                  >
                    Masculino
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="female"
                  >
                    Feminino
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="not defined"
                  >
                    Prefiro não informar
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </React.Fragment>
        )}

        <GridItem xs={12} md={6} sm={12}>
          <CustomInput
            error={erros.personIdentifier}
            errorText={erros.personIdentifier}
            labelText={personType === "CPF" ? "CPF" : "CNPJ"}
            id="address"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              maxLength: personType === "CPF" ? 14 : 18,
              value: personIdentifier,
              onChange: (e) => {
                if (personType === "CPF") {
                  setPersonIdentifier(maskCPF(e.target.value));
                } else {
                  setPersonIdentifier(maskCNPJ(e.target.value));
                }
              },
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            error={erros.password}
            errorText={erros.password}
            labelText="Senha"
            id="password"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "password",
              value: password,
              onChange: (e) => setPassword(e.target.value),
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            error={erros.confirmPassword}
            errorText={erros.confirmPassword}
            labelText="Confirme a senha"
            id="confirm-password"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "password",
              value: confirmPassword,
              onChange: (e) => setConfirmPassword(e.target.value),
            }}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem container xs={6} justify="center">
          <Button round disabled color="primary">
            Voltar
          </Button>
        </GridItem>

        <GridItem container xs={6} justify="center">
          <Button round color="primary" onClick={handleNextStep}>
            Continuar
          </Button>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}

InitialForm.propTypes = {
  nextStep: PropTypes.func,
};
