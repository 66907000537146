import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import AdminRestriction from "components/AccessWarning/AdminRestriction.js";
import { blockAccess } from "utils/validate.js";
import NewTutorial from "./NewTutorial";
import TutorialList from "./TutorialList";

export default function MenageTutorial() {
  return blockAccess(3) ? (
    <GridContainer>
      <GridItem xs={12}>
        <CustomTabs
          headerColor="primary"
          tabs={[
            {
              tabName: "Lista de tutoriais",
              tabContent: <TutorialList />,
            },
            {
              tabName: "Criar novo",
              tabContent: <NewTutorial />,
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  ) : (
    <AdminRestriction />
  );
}
