import React, { useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Homepage/Header.js";
import Footer from "components/Homepage/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import StartComponent from "components/Homepage/StartComponent.js";
import Button from "components/CustomButtons/Button.js";
import FAQ from "components/FAQ/FAQ.js";
import NewsletterFormDialog from "components/Newsletter/NewsletterFormDialog";

import {
  solutionStyle,
  homepageStyle,
} from "assets/jss/material-dashboard-react/components/homepageStyle.js";
const useStyles = makeStyles({
  ...solutionStyle,
  ...homepageStyle,
});

import {
  imageSolucoes1,
  imageSolucoes2,
  imageSolucoes3,
  imageSolucoes4,
} from "assets/jss/Texts/siteImages.js";

import {
  solucoesTitulo,
  solucoesSubtitulo,
  grupoUmTitulo,
  grupoUmTexto1,
  grupoUmTexto2,
  grupoDoisTitulo,
  grupoDoisTexto1,
  grupoDoisTexto2,
} from "assets/jss/Texts/siteTexts.js";

export default function Solucoes() {
  const classes = useStyles();
  const targetRef = useRef(null);

  const scrollToComponent = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <Header page="solucoes" />
      <GridContainer>
        <GridItem container extraClass={classes.banner}>
          <GridItem xs={12} sm={8} md={4}>
            <img
              src={imageSolucoes1}
              alt="imagem temporaria"
              className={classes.bannerImage}
            />
          </GridItem>
          <GridItem container xs={12} md={8}>
            <GridItem xs={12} md={10}>
              <h3>{solucoesTitulo}</h3>
            </GridItem>
            <GridItem xs={12} md={10} extraClass={classes.bannerSubtitle}>
              {solucoesSubtitulo}
            </GridItem>
            <GridItem container extraClass={classes.bannerButton}>
              <GridItem xs={12} sm={6}>
                <NewsletterFormDialog color="primary" />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Button
                  simple
                  className={classes.transperentButton}
                  onClick={scrollToComponent}
                >
                  Ver tutoriais
                </Button>
              </GridItem>
            </GridItem>
          </GridItem>
        </GridItem>
        <GridItem container extraClass={classes.group}>
          <GridItem xs={12} sm={8} md={4}>
            <img
              src={imageSolucoes2}
              alt="imagem temporaria"
              className={classes.image}
            />
          </GridItem>
          <GridItem xs={12} md={8}>
            <GridItem xs={10} extraClass={classes.groupTitle}>
              <h3>{grupoUmTitulo}</h3>
            </GridItem>
            <GridItem xs={8} extraClass={classes.groupText}>
              <p>{grupoUmTexto1}</p>
            </GridItem>
            <GridItem xs={8} extraClass={classes.groupText}>
              <p>{grupoUmTexto2}</p>
            </GridItem>
          </GridItem>
        </GridItem>
        <GridItem container extraClass={classes.rtl}>
          <GridItem xs={12} sm={8} md={4}>
            <img
              src={imageSolucoes3}
              alt="imagem temporaria"
              className={classes.image}
            />
          </GridItem>
          <GridItem xs={12} md={8}>
            <GridItem xs={10} extraClass={classes.groupTitle}>
              <h3>{grupoDoisTitulo}</h3>
            </GridItem>
            <GridItem xs={8} extraClass={classes.groupText}>
              <p>{grupoDoisTexto1}</p>
            </GridItem>
            <GridItem xs={8} extraClass={classes.groupText}>
              <p>{grupoDoisTexto2}</p>
            </GridItem>
          </GridItem>
        </GridItem>
        <GridItem container extraClass={classes.demo}>
          <GridItem xs={12} sm={7}>
            <img
              src={imageSolucoes4}
              alt="imagem temporaria"
              className={classes.image}
            />
          </GridItem>
          <GridItem xs={12} sm={5}>
            <GridItem xs={12}>
              <h4 className={classes.demoText}>
                Seja um dos primeiros a saber sobre nossos lançamentos
              </h4>
            </GridItem>
            <GridItem xs={12}>
              <NewsletterFormDialog color="primary" />
            </GridItem>
          </GridItem>
        </GridItem>
        <div ref={targetRef}>
          <hr />
        </div>
        <FAQ />
        <StartComponent />
      </GridContainer>
      <Footer />
    </div>
  );
}
