import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetSignUp } from "store/actions/signUpForm";
import { useHistory } from "react-router-dom";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import ArrowBack from "@material-ui/icons/ArrowBack";
// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// StepWizard forms
import StepWizard from "react-step-wizard";
import InitialForm from "./components/InitialForm";
import AddressForm from "./components/AddressForm";
import MeetForm from "./components/MeetForm";
import PlanForm from "./components/PlanForm";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import { primaryColor } from "assets/jss/material-dashboard-react";
import FillForm from "assets/img/sign-in/bannerSignIn.png";

const useStyles = makeStyles({
  ...styles,
  textCenter: {
    textAlign: "center",
  },
  hidden: {
    overflow: "hidden",
  },
  heightMin: {
    minHeight: "calc(100vh - 10px - 60px - 96px - 10px)",
    margin: "0px !important",
  },
  formGrid: {
    padding: "0px !important",
  },
  loginLink: {
    textAlign: "center",
    fontWeight: "bolder",
    fontSize: "16px",
    marginBottom: "24px",
    cursor: "pointer",
  },
  progressColor: {
    color: primaryColor[0],
  },
});

const useStylesImage = makeStyles((theme) => ({
  imageForm: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const useModalStyles = makeStyles(modalStyle);

export default function Dashboard() {
  const history = useHistory();
  const classes = useStyles();
  const classesImage = useStylesImage();
  const modaClasses = useModalStyles();
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((state) => state.signUpForm);

  function handleOk() {
    dispatch(resetSignUp());

    if (!error) {
      history.push("/auth/home/");
    }
  }

  return (
    <React.Fragment>
      <GridItem container>
        <GridItem xs={12} sm={6}>
          <Button
            color="primary"
            simple
            size="lg"
            onClick={() => history.push(`/auth/home`)}
          >
            <ArrowBack />
            Voltar ao inicio
          </Button>
        </GridItem>
      </GridItem>
      <GridItem xs={12} />
      <Card>
        <GridItem extraClass={classes.formGrid} container xs={12}>
          <GridItem xs={12} sm={12} md={8} lg={8}>
            <GridItem xs={12} md={12} sm={12}>
              <CardHeader color="primary">
                <h1
                  className={`${classes.cardTitleWhite} ${classes.textCenter}`}
                >
                  Cadastro
                </h1>
              </CardHeader>
            </GridItem>

            <CardBody className={`${classes.hidden} ${classes.heightMin}`}>
              <StepWizard>
                <InitialForm />
                <AddressForm />
                <MeetForm />
                <PlanForm />
              </StepWizard>
            </CardBody>
          </GridItem>

          <GridItem container xs={12} md={4}>
            <img
              className={classesImage.imageForm}
              src={FillForm}
              style={{
                width: `100%`,
                minWidth: `400px`,
                height: `auto`,
              }}
              alt="Imagem de um formulário"
            />
          </GridItem>
        </GridItem>
        <GridItem xs={12} extraClass={classes.loginLink}>
          <a
            onClick={() => {
              history.push(`/auth/em-breve`);
              // history.push(`/auth/login`);
            }}
          >
            Já é cadastrado? Faça o login!
          </a>
        </GridItem>
      </Card>

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={loading || success || error}
        onClose={() => {}}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        {success ? (
          <DialogTitle
            id="modal-slide-title"
            disableTypography
            className={modaClasses.modalHeader}
          >
            <h4 className={modaClasses.modalTitle}>Cadastro realizado</h4>
          </DialogTitle>
        ) : null}

        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          {!success ? (
            !error ? (
              <CircularProgress className={classes.progressColor} />
            ) : (
              <React.Fragment>
                <p>{error.error}</p>
                <p>
                  Verifique se todas as informações foram fornecidas
                  corretamente.
                </p>
              </React.Fragment>
            )
          ) : (
            <p>
              Quase lá. Enviamos um link para seu e-mail para que você confirme
              seu cadastro. Lembre-se de conferir o spam!
            </p>
          )}
        </DialogContent>

        {success || error ? (
          <DialogActions
            className={
              modaClasses.modalFooter + " " + modaClasses.modalFooterCenter
            }
          >
            <Button onClick={handleOk} color="success">
              Ok
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    </React.Fragment>
  );
}
