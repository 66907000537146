import routes from "routes/routes.jsx";

const getActiveRoute = (routes) => {
  let activeRoute = "";

  for (const route of routes) {
    if (route.collapse) {
      let collapseActiveRoute = getActiveRoute(route.views);

      if (collapseActiveRoute !== activeRoute) {
        return collapseActiveRoute;
      }
    } else {
      const pos = window.location.href.indexOf(route.layout + route.path);
      if (
        pos !== -1 &&
        pos ===
          window.location.href.length -
            (route.layout.length + route.path.length)
      ) {
        return route.name;
      }
    }
  }

  return activeRoute;
};

export const useRouteName = () => {
  let name = getActiveRoute(routes);

  return name;
};
