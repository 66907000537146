import {
  footerColor,
  primaryColor,
} from "assets/jss/material-dashboard-react.js";

const homepageStyle = {
  red: { backgroundColor: "red" },
  green: { backgroundColor: "green" },
  blue: { backgroundColor: "blue" },
  pink: { backgroundColor: "pink" },
  center: { textAlign: "center" },
  whiteText: { color: "white" },
  justifyCenter: { justifyContent: "center" },
  image: { maxWidth: "100%" },
  transperentButton: {
    border: "2px solid",
    backgroundColor: "transparent",
  },
};

const headerStyle = {
  drawerPaper: {
    backgroundColor: footerColor,
    color: "white",
  },
  drawerHeader: {
    justifyContent: "space-between",
    margin: "8px 0px",
  },
  drawerItem: {
    marginBottom: "8px",
    marginLeft: "32px",
  },
  drawerIcon: {
    color: primaryColor[0],
    fontSize: "32px",
  },
  drawerButton: {
    margin: "16px 0px",
  },
  container: {
    alignItems: "center",
    padding: "8px 4px",
  },
  logo: {
    width: "120px",
    height: "40px",
  },
  tabs: {
    justifyContent: "center",
    fontWeight: "bolder",
    textAlign: "right",
  },
  tabText: {
    marginRight: "32px",
    cursor: "pointer",
    color: "black",
  },
  tabSelect: {
    marginRight: "32px",
    cursor: "pointer",
    color: primaryColor[0],
  },
  tabIndicator: {
    backgroundColor: primaryColor[0],
    height: "5px",
    borderRadius: "8px",
  },
  tabEmpty: {
    backgroundColor: "transparent",
    height: "5px",
    borderRadius: "8px",
  },
  right: {
    textAlign: "right",
  },
};

const footerStyle = {
  foot: {
    backgroundColor: footerColor,
    color: "white",
    paddingTop: "24px",
  },
  divider: {
    backgroundColor: "white",
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "120px",
    padding: "16px",
  },
  infos: {
    marginBottom: "32px",
  },
  title: {
    fontWeight: "bolder",
    margin: "16px 0px 8px 0px",
  },
  socialMediaIcon: {
    color: "white",
  },
};

const homeStyle = (theme, image) => ({
  topBanner: {
    margin: "40px 0px 88px 0px",
    alignItems: "center",
    height: "700px",
    color: "white",
    backgroundColor: primaryColor[5],
    // image
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
  },
  topBannerOverlay: {
    height: "700px",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.15)",
    padding: "0px 24px",
  },
  topBannerContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    justifyContent: "space-between",
  },
  topBannerButton: {
    marginBottom: "40px",
  },
  topBannerText: {
    fontSize: "24px",
    lineHeight: "32px",
  },
  midBanner: {
    backgroundColor: primaryColor[4],
    color: "white",
    fontSize: "24px",
    marginBottom: "56px",
    minHeight: "250px",
    direction: "rtl",
    alignItems: "center",
  },
  midImage: {
    marginBottom: "-96px",
    position: "relative",
  },
  infoContainer: {
    margin: "16px 0px",
  },
  infoItem: {
    backgroundColor: primaryColor[0],
    color: "white",
    borderRadius: "16px",
    padding: "12px",
  },
  infoTitle: {
    fontWeight: "bolder",
  },
  infoText: {
    margin: "8px 64px 8px 0px",
  },
  infoLink: {
    textDecoration: "underline",
    color: "black",
    fontWeight: "bolder",
  },
});

const notFoundStyle = {
  content: {
    padding: "80px 0px 100px 0px",
  },
  image: {
    maxWidth: "100%",
  },
  title: {
    fontWeight: "bolder",
    fontSize: "40px",
    marginTop: "64px",
    marginBottom: "0px",
  },
  subtitle: {
    fontSize: "20px",
    marginBottom: "40px",
    paddingRight: "32px",
    paddingLeft: "32px",
  },
  center: {
    textAlign: "center",
    paddingTop: "128px",
  },
};

const solutionStyle = {
  banner: {
    backgroundColor: footerColor,
    margin: "48px 0px",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  bannerImage: {
    maxWidth: "100%",
    margin: "64px 0px",
    borderRadius: "12px",
  },
  bannerSubtitle: {
    fontSize: "24px",
    margin: "32px 0px",
  },
  bannerButton: {
    margin: "32px 0px",
  },
  group: {
    marginTop: "64px",
  },
  rtl: {
    marginTop: "64px",
    direction: "rtl",
  },
  groupTitle: {
    color: primaryColor[0],
  },
  groupText: {
    marginTop: "32px",
  },
  demo: {
    marginTop: "88px",
    marginBottom: "64px",
    alignItems: "center",
  },
  demoText: {
    color: primaryColor[0],
    fontWeight: "bolder",
  },
};

const startComponentSyle = {
  container: {
    textAlign: "center",
    justifyContent: "center",
    margin: "32px 0px",
  },
  title: {
    fontWeight: "bolder",
    marginBottom: "8px",
  },
  text: {
    marginBottom: "32px",
  },
};

const contatcStyle = {
  banner: {
    backgroundColor: footerColor,
    alignItems: "center",
    color: "white",
    marginTop: "32px",
  },
  title: {
    padding: "56px 0px 56px 24px",
  },
  buttons: {
    marginTop: "-24px",
    marginBottom: "16px",
  },
  form: {
    marginTop: "64px",
  },
};

const benefitsTableStyle = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  tableUpgradeWrapper: {
    display: "block",
    width: "100%",
    overflowX: "auto",
    WebkitOverflowScrolling: "touch",
    MsOverflowStyle: "-ms-autohiding-scrollbar",
  },
  table: {
    width: "100%",
    maxWidth: "100%",
    marginBottom: "1rem",
    backgroundColor: "transparent",
    borderCollapse: "collapse",
    display: "table",
    borderSpacing: "2px",
    borderColor: "grey",
    "& thdead tr th": {
      fontSize: "1.063rem",
      padding: "12px 8px",
      verticalAlign: "middle",
      fontWeight: "300",
      borderTopWidth: "0",
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
      textAlign: "inherit",
    },
    "& tbody tr td": {
      padding: "12px 8px",
      verticalAlign: "middle",
      borderTop: "1px solid rgba(0, 0, 0, 0.06)",
    },
    "& td, & th": {
      display: "table-cell",
    },
  },
};

const planStyle = (theme, image) => ({
  banner: {
    backgroundColor: primaryColor[1],
    marginTop: "48px",
    minHeight: "320px",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    // image
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "left",
      backgroundSize: "140% 100%",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "460px",
      backgroundPosition: "right",
      backgroundSize: "180% 100%",
    },
  },
  bannerTitle: {
    fontWeight: "bolder",
  },
  bannerSubtitle: {
    fontSize: "24px",
    marginTop: "16px",
    marginBottom: "32px",
  },
  bannerButton: {
    marginBottom: "64px",
  },
  cardInicial: {
    backgroundColor: "white",
    border: "solid 2px",
    borderColor: primaryColor[0],
    borderRadius: "8px",
    maxWidth: "310px",
    height: "410px",
    marginTop: "-36px",
    marginBottom: "88px",
    textAlign: "center",
  },
  cardPro: {
    backgroundColor: primaryColor[0],
    border: "solid 2px",
    borderColor: "white",
    borderRadius: "8px",
    maxWidth: "360px",
    height: "460px",
    margin: "-60px 0px 32px -8px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-100px",
    },
    textAlign: "center",
    color: "white",
  },
  inicialTitle: {
    fontSize: "32px",
    marginBottom: "0px",
    color: primaryColor[0],
    fontWeight: "bolder",
  },
  inicialPro: {
    fontSize: "32px",
    marginTop: "0px",
    marginBottom: "-16px",
    fontWeight: "bolder",
  },
  proButton: {
    color: primaryColor[0],
    padding: "14px 56px",
  },
});

export {
  homepageStyle,
  homeStyle,
  headerStyle,
  footerStyle,
  notFoundStyle,
  solutionStyle,
  startComponentSyle,
  benefitsTableStyle,
  contatcStyle,
  planStyle,
};
