import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
// @material-ui/icon
import Instagram from "@material-ui/icons/Instagram";
import Email from "@material-ui/icons/MailOutline";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Homepage/Header.js";
import Footer from "components/Homepage/Footer.js";
import Suporte from "components/Contact/Form.js";

import {
  homepageStyle,
  contatcStyle,
} from "assets/jss/material-dashboard-react/components/homepageStyle";
const useStyles = makeStyles({
  ...homepageStyle,
  ...contatcStyle,
});

import { contatoTitulo, contatoSubtitulo } from "assets/jss/Texts/siteTexts.js";
import { instagramLink, emailLink } from "assets/jss/Texts/siteLinks.js";

export default function Contato() {
  const classes = useStyles();

  return (
    <div>
      <Header page="contato" />
      <GridContainer>
        <GridItem container extraClass={classes.banner}>
          <GridItem xs={12} md={5}>
            <h3 className={classes.title}>{contatoTitulo}</h3>
          </GridItem>
          <GridItem xs={1} />
          <GridItem xs={12} md={5} container>
            <GridItem>
              <h4>{contatoSubtitulo}</h4>
            </GridItem>
            <GridItem extraClass={classes.buttons}>
              <IconButton
                aria-label="icone instagram"
                onClick={() => window.open(instagramLink)}
              >
                <Instagram fontSize="large" style={{ color: "white" }} />
              </IconButton>
              <IconButton
                aria-label="icone email"
                onClick={() => window.open(emailLink)}
              >
                <Email fontSize="large" style={{ color: "white" }} />
              </IconButton>
            </GridItem>
          </GridItem>
        </GridItem>
        <GridItem container justify="center">
          <GridItem xs={12} md={10} extraClass={classes.form}>
            <Suporte />
          </GridItem>
        </GridItem>
      </GridContainer>
      <Footer />
    </div>
  );
}
