import validate from "validate.js";
import moment from "moment";

export const blockAccess = (cantAccess) => {
  return localStorage.getItem("@adminType") != cantAccess;
};

export const validation = (fields, rules) => {
  validate.extend(validate.validators.datetime, {
    parse: function (value, options) {
      var format = options.dateOnly ? "DD/MM/YYYY" : "DD/MM/YYYY hh:mm:ss";
      return moment.utc(value, format);
    },
    format: function (value, options) {
      var format = options.dateOnly ? "DD/MM/YYYY" : "DD/MM/YYYY hh:mm:ss";
      return moment.utc(value).format(format);
    },
  });

  const result = validate(fields, rules, { fullMessages: false });

  if (result) {
    return result;
  }

  return null;
};
