import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Tag from "components/Tags/Tag.js";
import Button from "components/CustomButtons/Button.js";

import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import { tagsColors } from "assets/jss/material-dashboard-react.js";
const useStyle = makeStyles({
  colorCircle: {
    borderRadius: "100%",
    width: "48px",
    height: "48px",
    cursor: "pointer",
    marginBottom: "16px",
  },
  tagMargin: {
    marginTop: "32px",
    marginLeft: "16px",
  },
  erroMsg: {
    color: "red",
    fontWeight: "bolder",
  },
});
const useModalStyles = makeStyles(modalStyle);

export default function NewTag({ setModal, onCreate }) {
  const classes = useStyle();
  const modaClasses = useModalStyles();

  const [title, setTitle] = useState("");
  const [color, setColor] = useState("red");
  const [error, setError] = useState(false);

  function createTag() {
    console.log(title.length);
    if (title.length == 0 || title.length > 17) {
      setError(true);
    } else {
      onCreate({ title, color });
      setModal(false);
    }
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        {error ? (
          <p className={classes.erroMsg}>
            O TÍTULO DA TAG DEVE TER ATÉ 17 CARACTERES
          </p>
        ) : null}
        <CustomInput
          error={error}
          labelText="Título"
          id="title"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            multiline: false,
            value: title,
            onChange: (e) => setTitle(e.target.value),
          }}
        />
      </GridItem>
      <GridItem container>
        <h4>Cores:</h4>
        <GridItem container>
          <GridItem xs={3} sm={2}>
            <div
              className={classes.colorCircle}
              style={{ backgroundColor: tagsColors[0] }}
              onClick={() => setColor("red")}
            />
          </GridItem>
          <GridItem xs={3} sm={2}>
            <div
              className={classes.colorCircle}
              style={{ backgroundColor: tagsColors[1] }}
              onClick={() => setColor("blue")}
            />
          </GridItem>
          <GridItem xs={3} sm={2}>
            <div
              className={classes.colorCircle}
              style={{ backgroundColor: tagsColors[2] }}
              onClick={() => setColor("green")}
            />
          </GridItem>
          <GridItem xs={3} sm={2}>
            <div
              className={classes.colorCircle}
              style={{ backgroundColor: tagsColors[3] }}
              onClick={() => setColor("yellow")}
            />
          </GridItem>
          <GridItem xs={3} sm={2}>
            <div
              className={classes.colorCircle}
              style={{ backgroundColor: tagsColors[4] }}
              onClick={() => setColor("purple")}
            />
          </GridItem>
          <GridItem xs={3} sm={2}>
            <div
              className={classes.colorCircle}
              style={{ backgroundColor: tagsColors[5] }}
              onClick={() => setColor("orange")}
            />
          </GridItem>
        </GridItem>
      </GridItem>
      <GridItem container>
        <h4>Tag:</h4>
        <div className={classes.tagMargin}>
          <Tag title={title} color={color} />
        </div>
      </GridItem>

      <GridItem xs={12}>
        <DialogActions className={modaClasses.modalFooter}>
          <Button onClick={() => createTag()} color="success">
            Criar
          </Button>
          <Button onClick={() => setModal(false)} color="danger">
            Descartar
          </Button>
        </DialogActions>
      </GridItem>
    </GridContainer>
  );
}

NewTag.propTypes = {
  setModal: PropTypes.func,
  onCreate: PropTypes.func,
};
