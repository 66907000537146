/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import api from "services/api";
import PropTypes from "prop-types";
// @material-ui components
import { makeStyles } from "@material-ui/core/";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import { Visibility, VisibilityOff } from "@material-ui/icons";
// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// style components
const useStyles = makeStyles({
  title: {
    fontWeight: "bolder",
  },
  button: {
    margin: "32px 0px",
  },
});

export default function PasswordForm({
  nextStep,
  password,
  setPassword,
  submit,
}) {
  const classes = useStyles();
  const [password2, setPassword2] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [password2Show, setPassword2Show] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  function validatePassword() {
    if (password.length < 6) {
      setError("A senha deve ter mais de seis digitos");
      return false;
    } else {
      setError("");
      return true;
    }
  }

  function handleNextStep() {
    if (validatePassword()) {
      if (password !== password2) {
        setError("As senhas não combinam");
      } else {
        setError("");
        submit();
        setLoading(true);
        setTimeout(function () {
          nextStep();
          setLoading(false);
        }, 3000);
      }
    }
  }

  return (
    <GridItem container justify="center">
      <GridItem>
        <h3 className={classes.title}>Criar nova senha</h3>
      </GridItem>
      <GridItem xs={12} md={10}>
        <CustomInput
          error={error !== ""}
          errorText={error}
          formControlProps={{
            fullWidth: true,
          }}
          id="password"
          inputProps={{
            type: passwordShow ? "text" : "password",
            onChange: (e) => setPassword(e.target.value),
            startAdornment: (
              <InputAdornment position="start">Senha:</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position={error === "" ? "end" : "start"}>
                <IconButton onClick={() => setPasswordShow(!passwordShow)}>
                  {passwordShow ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      <GridItem xs={12} md={10}>
        <CustomInput
          formControlProps={{
            fullWidth: true,
          }}
          id="password"
          inputProps={{
            type: password2Show ? "text" : "password",
            onChange: (e) => setPassword2(e.target.value),
            startAdornment: (
              <InputAdornment position="start">Repetir senha:</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setPassword2Show(!password2Show)}>
                  {password2Show ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      <GridItem extraClass={classes.button}>
        <Button disabled={loading} color="primary" onClick={handleNextStep}>
          Resetar senha
        </Button>
      </GridItem>
    </GridItem>
  );
}

PasswordForm.propTypes = {
  nextStep: PropTypes.func,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  submit: PropTypes.func,
};
