import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// style components
const useStyles = makeStyles({
  container: {
    width: "130%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "12px",
    marginBottom: "32px",
  },
  header: {
    borderRadius: "11px 11px 0px 0px",
    borderBottomStyle: "solid",
    borderWidth: "1px",
  },
  title: {
    margin: "0px",
    padding: "12px 32px",
  },
  content: {
    borderRadius: "0px 0px 0px 11px",
    display: "flex",
    padding: "16px",
    margin: "8px 2px 4px 4px",
    overflowX: "scroll",
  },
});

export default function KanbanContainer(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header} style={{ backgroundColor: props.color }}>
        <h3
          className={classes.title}
          style={{ color: props.title === "Rascunho" ? "black" : "white" }}
        >
          {props.title}
        </h3>
      </div>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
}

KanbanContainer.propTypes = {
  title: PropTypes.string,
  color: PropTypes.any,
  children: PropTypes.node,
  // to add drag and drop
  // provided: PropTypes.object,
};
