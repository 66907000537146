/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { signUp } from "store/actions/signUpForm";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import { cardTitle } from "assets/jss/material-dashboard-react.js";
import {
  primaryColor,
  grayColor,
  infoColor,
  defaultFont,
  successColor,
} from "assets/jss/material-dashboard-react";

const useStyles = makeStyles({
  cardSelectable: {
    transition: "border .5s",
    "&:hover": {
      cursor: "pointer",
      border: `2px solid ${primaryColor[0]}`,
    },
    border: `2px solid #00000000`,
  },
  cardSelected: {
    transition: "border .2s",
    border: `2px solid ${primaryColor[1]}`,
    "&:hover": {
      border: `2px solid ${primaryColor[1]}`,
    },
  },
  cardTitle: {
    ...cardTitle,
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
  value: {
    fontWeight: "bold",
    fontSize: "1.6rem",
    color: infoColor[0],
  },
  sup: {
    padding: 0,
    margin: 0,
    marginRight: -4,
    lineHeight: 0,
    fontSize: "0.9rem",
  },
  valueText: {
    fontWeight: "lighter",
    fontSize: ".6rem",
    color: grayColor[1],
  },
  textCenter: {
    textAlign: "center",
  },
  description: {
    textAlign: "justify",
    fontSize: "0.8rem",
    ...defaultFont,
  },
  acceptText: {
    textAlign: "center",
    color: grayColor[1],
  },
  externalLink: {
    color: primaryColor[0],
    cursor: "pointer",
    "&:hover": {
      fontWeight: "bolder",
    },
  },
});

import { planoGratuito } from "assets/jss/Texts/siteTexts.js";
const plansDatas = [
  {
    id: 0,
    title: "Plano Gratuito",
    value: "00,00",
    description: planoGratuito,
  },
];

export default function PlanForm({ previousStep }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const signUpForm = useSelector((state) => state.signUpForm);
  const [planId, setPlanId] = useState(signUpForm.planId || 0);

  function handleSelect(event, planId) {
    event.preventDefault();
    setPlanId(planId);
  }

  function handleFinish() {
    dispatch(
      signUp({
        ...signUpForm,
        planId,
      })
    );
  }

  function planSubscription(planId) {
    switch (planId) {
      case "1":
        return "/pagamento semestral";
      case "2":
        return "/pagamento anual";
      default:
        return "";
    }
  }

  return (
    <React.Fragment>
      <GridContainer justify="center">
        {plansDatas.map((plan) => (
          <GridItem key={plan.id} xs={12} sm={8} md={4}>
            <a href="" onClick={(e) => handleSelect(e, plan.id)}>
              <Card
                className={`${classes.textCenter} ${classes.cardSelectable} ${
                  plan.id === planId ? classes.cardSelected : ""
                }`}
              >
                <CardBody>
                  <h2 className={classes.cardTitle}>{plan.title}</h2>

                  <p className={classes.valueText}>
                    <span className={classes.value}>
                      <sup className={classes.sup}>R$</sup> {plan.value}
                    </span>
                    &nbsp;{planSubscription(plan.id)}
                  </p>

                  <hr
                    style={{
                      borderTop: `0.5px solid ${grayColor[0]}`,
                      borderBottom: "none",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  />

                  <p className={classes.description}>{plan.description}</p>
                </CardBody>
              </Card>
            </a>
          </GridItem>
        ))}
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <h4 className={classes.acceptText}>
            Ao finalizar seu cadastro você aceita nossos{" "}
            <a
              href="/blog/terms"
              target="_blank"
              rel="noreferrer"
              className={classes.externalLink}
            >
              Termos de uso
            </a>{" "}
            e concorda com nossa{" "}
            <a
              href="/blog/policy"
              target="_blank"
              rel="noreferrer"
              className={classes.externalLink}
            >
              Política de privacidade
            </a>{" "}
          </h4>
        </GridItem>

        <GridItem container xs={6} justify="center">
          <Button round color="primary" onClick={previousStep}>
            Voltar
          </Button>
        </GridItem>

        <GridItem container xs={6} justify="center">
          <Button
            disabled={planId === ""}
            round
            color="primary"
            onClick={handleFinish}
          >
            Finalizar
          </Button>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}

PlanForm.propTypes = {
  previousStep: PropTypes.func,
};
