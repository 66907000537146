/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import api from "services/api";
import { useSelector } from "react-redux";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton, ListItemSecondaryAction } from "@material-ui/core";
// @material-ui/icons
import QueueIcon from "@material-ui/icons/Queue";
import EditIcon from "@material-ui/icons/Edit";
// core components
import GridItem from "components/Grid/GridItem.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Button from "components/CustomButtons/Button.js";
import EditorViewer from "components/EditorViewer";

import { primaryColor } from "assets/jss/material-dashboard-react";
import { hasPremiumPermission } from "utils/premium";

const useStyles = makeStyles({
  docContainer: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  previewBg: {
    border: "1px solid",
    borderColor: "#CCC",
    backgroundColor: "#EEE",
    height: "500px",
    overflowY: "scroll",
    zIndex: "0",
  },
  previewPaper: {
    border: "1px hsl( 0,0%,82.7% ) solid",
    backgroundColor: "white",
    margin: "4px 10px",
  },
});

function Item({ document }) {
  const history = useHistory();
  const classes = useStyles();
  const [openPanel, setOpenPanel] = useState(false);
  const { isAdmin, userProfile } = useSelector((state) => state.user);

  const premiumPermission = hasPremiumPermission(userProfile, isAdmin);

  // new document
  const [newDocId, setNewDocId] = useState("");
  const [notific, setNotific] = useState(false);

  function docPreview() {
    if (document.tipo === "PUBLIC" || premiumPermission) {
      setOpenPanel(!openPanel);
    } else {
      history.push(`/admin/perfil/subscription`);
    }
  }

  function editDocument() {
    history.push(`/admin/documentos/${document.id}/edit`);
  }

  async function saveDocument() {
    try {
      const response = await api.post("/docs", {
        titulo: document.titulo + " - cópia",
        base: false,
        nr: "",
        empresa: "",
        tipo: undefined,
        status: "rascunho",
        prazo: moment(new Date()).format("YYYY-MM-DD"),
        id_categoria: undefined,
        id_usuario: localStorage.getItem("@userId"),
        data: moment(new Date()).format("YYYY-MM-DD"),
        texto: document.texto,
      });
      setNewDocId(response.data.doc);
    } catch (error) {
      console.log(error);
    } finally {
      setNotific(true);
      setTimeout(function () {
        setNotific(false);
      }, 8000);
    }
  }

  function notificationContent() {
    return (
      <GridItem>
        <GridItem container>
          &quot;{document.titulo + " - cópia "}&quot; foi salvo em sua
          biblioteca. Continue explorando nossos documentos ou clique aqui para
          edita-lo.
        </GridItem>
        <GridItem container justify="center">
          <Button
            size="sm"
            color="primary"
            onClick={() => history.push(`/admin/documentos/${newDocId}/edit`)}
          >
            Editar documento
          </Button>
        </GridItem>
      </GridItem>
    );
  }

  return (
    <React.Fragment>
      <GridItem container justify="center">
        <ListItem key={document.id}>
          <GridItem
            container
            extraClass={classes.docContainer}
            onClick={() => docPreview()}
          >
            <GridItem>
              <ListItemText
                primary={document.titulo}
                secondary={
                  document.tipo === "PUBLIC"
                    ? "Disponível no plano FREE"
                    : "Disponível no plano PREMIUM"
                }
              />
            </GridItem>
          </GridItem>

          <GridItem>
            <ListItemSecondaryAction>
              {isAdmin ? (
                <IconButton onClick={editDocument} edge="end" aria-label="edit">
                  <EditIcon style={{ color: primaryColor[0] }} />
                </IconButton>
              ) : (
                <IconButton
                  disabled={document.tipo !== "PUBLIC" && !premiumPermission}
                  onClick={saveDocument}
                  edge="end"
                  aria-label="save"
                >
                  <QueueIcon
                    style={{
                      color:
                        document.tipo !== "PUBLIC" && !premiumPermission
                          ? `${primaryColor[0]}60`
                          : primaryColor[0],
                    }}
                  />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </GridItem>
        </ListItem>
        <GridItem xs={12}>
          {openPanel ? (
            <div className={classes.previewBg}>
              <div className={classes.previewPaper}>
                <EditorViewer initialContent={document.texto} />
              </div>
            </div>
          ) : (
            ""
          )}
        </GridItem>
      </GridItem>

      <Snackbar
        place="br"
        color="primary"
        message={notificationContent()}
        open={notific}
        close
        closeNotification={() => setNotific(false)}
      />
    </React.Fragment>
  );
}

export default Item;
