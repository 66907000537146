import React from "react";
import { useHistory } from "react-router-dom";
// @material-ui components
import { makeStyles } from "@material-ui/core/";
// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// style components
const useStyles = makeStyles({
  button: {
    marginTop: "32px",
    marginBottom: "16px",
  },
});

export default function SuccessForm() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <GridItem container justify="center">
      <GridItem xs={12} md={10}>
        <h4>
          Senha alterada com sucesso! Agora basta retornar a tela de login e
          realizar um novo acesso
        </h4>
      </GridItem>
      <GridItem extraClass={classes.button}>
        <Button color="primary" onClick={() => history.push("/auth/login")}>
          Fazer login
        </Button>
      </GridItem>
    </GridItem>
  );
}
