import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import moment from "moment";

import { blackColor, grayColor } from "assets/jss/material-dashboard-react";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    borderLeft: `0.2px solid ${grayColor[9]}`,
    paddingLeft: 6,
    marginBottom: 18,
  },
  user: {
    marginRight: 12,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  userInfo: {
    color: blackColor,
    margin: 0,
    padding: 0,
    fontSize: 14,
    marginTop: 4,
    lineHeight: 0.9,
    "& > strong": {
      fontSize: 10,
    },
  },
  textComment: {
    color: grayColor[8],
    flex: 1,
    margin: 0,
  },
});

function Comment({ commentary }) {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  function showDate() {
    if (commentary.tipo === "usuário") {
      return moment(commentary.created_at).calendar();
    } else {
      return commentary.tipo;
    }
  }

  return (
    <div className={classes.container}>
      <aside className={classes.user}>
        <p className={classes.userInfo}>{commentary.nome}</p>
        <p className={classes.userInfo}>
          <strong>{showDate()}</strong>
        </p>
      </aside>

      <p className={classes.textComment}>{commentary.comentario}</p>
    </div>
  );
}

Comment.propTypes = {
  commentary: PropTypes.objectOf({
    id: PropTypes.string.isRequired,
    comentario: PropTypes.string.isRequired,
    user: PropTypes.objectOf({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default memo(Comment);
