/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import api from "services/api";
import { useParams } from "react-router-dom";
import moment from "moment";
// @material-ui core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import Comments from "./components/Comments";
import OtherPosts from "./components/OtherPosts";

import "suneditor/dist/css/suneditor.min.css";
import { blackColor } from "assets/jss/material-dashboard-react";

const useStyles = makeStyles((theme) => ({
  pink: { backgroundColor: "pink" },
  coverContainer: {
    textAlign: "center",
    margin: "24px 0px",
    padding: "8px 0px",
  },
  postCover: {
    margin: "12px 0px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "60%",
      paddingLeft: "80px",
    },
  },
  date: {
    fontSize: "18px",
  },
  title: {
    color: blackColor,
    fontWeight: "700",
    margin: "0px",
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: "40px",
    },
  },
}));

function Post() {
  const { id: postId } = useParams();
  const classes = useStyles();
  const [content, setContent] = useState(null);
  const [cover, setCover] = useState(null);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 1);

    async function getDatas() {
      try {
        setContent(null);
        const postFromServer = (await api.get(`/blog/posts/${postId}`)).data;
        setTitle(postFromServer.titulo);
        setContent(postFromServer.texto);
        setDate(postFromServer.createdAt);

        if (postFromServer.id_capa) {
          setCover(postFromServer.id_capa);
          console.log(postFromServer.id_capa);
        }
      } catch (error) {
        console.log(error);
      }
    }

    getDatas();
  }, [postId]);

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem
          container
          justify="center"
          extraClass={classes.coverContainer}
        >
          <GridItem>
            <img
              className={classes.postCover}
              src={
                cover !== null
                  ? `${api.defaults.baseURL}/blog/images/${cover}`
                  : "https://media.istockphoto.com/photos/bloggingblog-concepts-ideas-with-worktable-picture-id922745190?s=612x612"
              }
              alt="capa da postagem"
            />
          </GridItem>
        </GridItem>
        <GridItem container justify="space-between">
          <GridItem>
            <h2 className={classes.title}>{title}</h2>
          </GridItem>
          <GridItem>
            <p className={classes.date}>
              Publicado em {moment(date).format("DD/MM/yy")}
            </p>
          </GridItem>
        </GridItem>
        <GridItem container>
          <GridItem xs={12} extraClass={classes.content}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </GridItem>
        </GridItem>
      </GridContainer>

      <Comments />
      <OtherPosts />
    </React.Fragment>
  );
}

export default Post;
