import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/layouts/authStyle";

import { authRoutes } from "routes/routes.jsx";

const switchRoutes = (
  <Switch>
    {authRoutes.map((prop, key) => {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    })}
    <Redirect from="/auth" to="/auth/home" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function AuthLayout() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.container}>{switchRoutes}</div>
      </div>
    </div>
  );
}
