import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import api from "services/api";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
// core components
import Tag from "components/Tags/Tag.js";
// style components
import { hexToRgb, blackColor } from "assets/jss/material-dashboard-react";
import ActionCard from "components/ActionCard/ActionCard";
const useStyles = makeStyles({
  card: {
    backgroundColor: "white",
    margin: "0px 16px",
    width: "200px",
    height: "140px",
    borderRadius: "12px",
    padding: "0px 8px",
    boxShadow:
      "0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.14), 0 7px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ",.4)",
    cursor: "pointer",
  },
  header: {
    paddingTop: "8px",
  },
  tags: {
    display: "flex",
    alignItems: "center",
  },
  tagsText: {
    fontWeight: "bold",
    margin: "0px 8px",
    color: "red",
  },
  title: {
    fontSize: "20px",
    paddingLeft: "8px",
  },
});

export default function SimpleCard(props) {
  const classes = useStyles();
  const charLimit = 32;
  const [tags, setTags] = useState([]);
  const [openCard, setOpenCard] = useState(false);

  useEffect(() => {
    async function load() {
      try {
        const response = await api.get(`actions/${props.id}/tags`);

        let selectedTags = [];
        response.data.forEach((element) => {
          if (element.id_acao == props.id) selectedTags.splice(0, 0, element);
        });
        setTags(selectedTags);
      } catch (error) {
        console.log(error);
      }
    }

    load();
  }, []);

  function breakTitle() {
    if (props.title === "" || props.title == null) return "Erro";
    let result = props.title.slice(0, charLimit);
    if (props.title.length > charLimit) {
      return result + "... ";
    } else {
      return result;
    }
  }

  function showTags() {
    let amount = tags.length;
    if (amount < 3) {
      return (
        <div className={classes.tags}>
          {tags.map((tag) => (
            <Tag key={tag.id} title={tag.titulo} color={tag.cor} />
          ))}
        </div>
      );
    } else {
      return (
        <div className={classes.tags}>
          <Tag key={tags[0].id} title={tags[0].titulo} color={tags[0].cor} />
          <Tag key={tags[1].id} title={tags[1].titulo} color={tags[1].cor} />
          <p className={classes.tagsText}>+{amount - 2} tag</p>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <div className={classes.card} onClick={() => setOpenCard(true)}>
        <div className={classes.header}>{showTags()}</div>
        <div>
          <p className={classes.title}>{breakTitle()}</p>
        </div>
      </div>

      <Dialog
        open={openCard}
        onClose={() => setOpenCard(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <ActionCard
          id={props.id}
          title={props.title}
          document={props.document}
          date={moment(props.date).format("DD/MM/YYYY")}
          status={props.status}
          responsible={props.responsible}
        />
      </Dialog>
    </React.Fragment>
  );
}

SimpleCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  document: PropTypes.number,
  date: PropTypes.string,
  status: PropTypes.string,
  responsible: PropTypes.string,
  // to add drag and drop
  // provided: PropTypes.object,
};
