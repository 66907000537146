import React from "react";
import { useSelector } from "react-redux";

import UserProfile from "./Perfil";
import AdminProfile from "./AdminProfile";

function Perfil() {
  const { isAdmin } = useSelector((state) => state.user);

  return !isAdmin ? <UserProfile /> : <AdminProfile />;
}

export default Perfil;
