import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import NewsletterFormDialog from "components/Newsletter/NewsletterFormDialog";

import { startComponentSyle } from "assets/jss/material-dashboard-react/components/homepageStyle.js";
const useStyles = makeStyles(startComponentSyle);

const text1 = "Estamos chegando para ser";
const text2 =
  "o único software que um consultor em Segurança do Trabalho precisa";

export default function StartComponent() {
  const classes = useStyles();

  return (
    <GridItem container xs={12} extraClass={classes.container}>
      <GridItem xs={12}>
        <h2 className={classes.title}> {text1} </h2>
      </GridItem>
      <GridItem xs={12} md={6}>
        <p className={classes.text}>{text2}</p>
      </GridItem>
      <GridItem xs={12}>
        <NewsletterFormDialog color="primary" />
      </GridItem>
    </GridItem>
  );
}
