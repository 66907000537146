import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import api from "services/api";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// eslint-disable-next-line no-unused-vars
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Add from "@material-ui/icons/Add";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import NewTag from "components/Tags/NewTag.js";
import Tag from "components/Tags/Tag.js";
import PermissionsGate from "components/PermissionsGate";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import stylesSelect from "assets/jss/material-dashboard-react/selectStyle.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";

const useStyles = makeStyles({
  ...styles,
  ...stylesSelect,
  errorMsg: {
    textAlign: "center",
    color: "red",
  },
  displayContainer: {
    display: "flex",
    flexDirection: "row",
  },
  fieldFix: {
    width: "100%",
    marginTop: "12px",
  },
  textFix: {
    marginRight: "16px",
  },
  dateFix: {
    marginLeft: "16px",
    marginTop: "-16px",
  },
  buttons: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  newTagButton: {
    marginTop: "24px",
  },
});
const useModalStyles = makeStyles(modalStyle);

function Edit() {
  const { id: actionId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const modaClasses = useModalStyles();

  const [description, setDescription] = useState("");
  const [responsible, setResponsible] = useState("");
  const [client, setClient] = useState("");
  const [document, setDocument] = useState("");
  const [documents, setDocuments] = useState([]);
  const [status, setStatus] = useState("Rascunho");
  const [reminder, setReminder] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [tags, setTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [modal, setModal] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [descError, setDescError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadDocuments() {
      try {
        let userId = localStorage.getItem("@userId");
        const response = await api.get(
          `users/${userId}/docs?page=1&size=10000`
        );
        setDocuments(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }

    async function loadAction() {
      setLoading(true);
      try {
        const response = await api.get(`/actions/${actionId}`);

        setDescription(response.data.titulo);
        setDocument(response.data.id_documento);
        setResponsible(response.data.responsavel);
        setClient(response.data.cliente);
        setStatus(response.data.status);
        setReminder(moment(response.data.lembrete).format("YYYY-MM-DD"));
        setDate(moment(response.data.data).format("YYYY-MM-DD"));

        const responseTags = await api.get(`actions/${actionId}/tags`);
        let selectecTags = [];
        responseTags.data.forEach((element) => {
          if (element.id_acao == actionId) selectecTags.splice(0, 0, element);
        });
        setTags(selectecTags);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadDocuments();
    loadAction();
  }, []);

  function checkField(checked, func) {
    if (checked === "") {
      func(true);
      return false;
    } else {
      func(false);
      return true;
    }
  }

  async function saveAction() {
    if (
      checkField(description, setDescError) &&
      checkField(date, setDateError)
    ) {
      setErrorMsg("");
      setLoading(true);

      try {
        await api.put(`/actions/${actionId}`, {
          titulo: description,
          status: status,
          lembrete: moment(reminder),
          data: moment(date),
          id_documento: document,
          responsavel: responsible,
          cliente: client,
        });

        for (const tag of newTags) {
          await api.post(`actions/${actionId}/tags`, {
            titulo: tag.title,
            cor: tag.color,
          });
        }

        history.push("/admin/acoes");
      } catch (error) {
        console.log(error);
        setErrorMsg("Ocorreu um erro enquanto a ação era criada");
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMsg(
        "Alguns campos não foram preenchidos. Preencha-os para atualizar a ação"
      );
    }
  }

  function descartForm() {
    setDescError(false);
    setDateError(false);
    setErrorMsg("");
    history.push("/admin/acoes");
  }

  async function deleteTag(tag) {
    try {
      await api.delete(`actions/${actionId}/tags/${tag.id}`);

      const responseTags = await api.get(`actions/${actionId}/tags`);
      let selectecTags = [];
      responseTags.data.forEach((element) => {
        if (element.id_acao == actionId) selectecTags.splice(0, 0, element);
      });
      setTags(selectecTags);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Nova ação</h4>
        <p className={classes.cardCategoryWhite}>
          Insira os dados de uma nova ação
        </p>
      </CardHeader>

      <CardBody>
        <h4 className={classes.errorMsg}>{errorMsg}</h4>
        <GridContainer>
          <GridItem container>
            <GridItem xs={12}>
              <CustomInput
                error={descError}
                labelText="Descrição"
                id="description"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  rows: 3,
                  value: description,
                  onChange: (e) => setDescription(e.target.value),
                }}
              />
            </GridItem>
          </GridItem>

          <GridItem container>
            <GridItem xs={12} md={5}>
              <div className={classes.displayContainer}>
                <h5 className={classes.textFix}>Documento:</h5>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel htmlFor="status" className={classes.selectLabel}>
                    Selecione
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={document}
                    onChange={(e) => setDocument(e.target.value)}
                    inputProps={{
                      name: "client",
                      id: "client",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Selecionar documento
                    </MenuItem>
                    {documents.map((doc) => (
                      <MenuItem
                        key={doc.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={doc.id}
                      >
                        {doc.titulo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </GridItem>
            <GridItem xs={12} md={4}>
              <div className={classes.displayContainer}>
                <h5 className={classes.textFix}>Status:</h5>
                <div className={classes.fieldFix}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      inputProps={{
                        name: "status",
                        id: "status",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Selecione o status da ação
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="Rascunho"
                      >
                        Rascunho
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="Não iniciada"
                      >
                        Não iniciada
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="Em execução"
                      >
                        Em execução
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="Concluída"
                      >
                        Concluída
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </GridItem>
          </GridItem>

          <GridItem container>
            <GridItem xs={12} md={4}>
              <div className={classes.displayContainer}>
                <h5>Responsável:</h5>
                <div className={classes.dateFix}>
                  <CustomInput
                    id="text"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: responsible,
                      onChange: (e) => setResponsible(e.target.value),
                    }}
                  />
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} md={4}>
              <div className={classes.displayContainer}>
                <h5>Cliente:</h5>
                <div className={classes.dateFix}>
                  <CustomInput
                    id="text"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: client,
                      onChange: (e) => setClient(e.target.value),
                    }}
                  />
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} md={4}>
              <div className={classes.displayContainer}>
                <h5>Prazo:</h5>
                <div className={classes.dateFix}>
                  <CustomInput
                    error={dateError}
                    id="date"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "date",
                      value: date,
                      onChange: (e) => setDate(e.target.value),
                    }}
                  />
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} md={4}>
              <div className={classes.displayContainer}>
                <h5 className={classes.textFix}>Lembrete:</h5>
                <div className={classes.dateFix}>
                  <CustomInput
                    error={dateError}
                    id="date"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "date",
                      value: reminder,
                      onChange: (e) => setReminder(e.target.value),
                    }}
                  />
                </div>
              </div>
            </GridItem>
          </GridItem>

          <GridItem container xs={12}>
            <GridItem>
              <div className={classes.displayContainer}>
                <h5>Adiconar tags:</h5>
                <Button
                  justIcon
                  color="transparent"
                  onClick={() => setModal(true)}
                  className={classes.newTagButton}
                >
                  <Add />
                </Button>
              </div>
            </GridItem>
          </GridItem>

          <GridItem container xs={12}>
            {tags.map((tag, key) => (
              <div key={key} style={{ display: "flex" }}>
                <Tag title={tag.titulo} color={tag.cor} />
                <Tooltip title="Apagar tag" placement="top" arrow>
                  <div
                    style={{
                      backgroundColor: "lightgrey",
                      color: "white",
                      fontWeight: "bold",
                      padding: "0px 10px",
                      borderRadius: "12px",
                      height: "max-content",
                      margin: "2px 0px",
                      cursor: "pointer",
                    }}
                    onClick={() => deleteTag(tag)}
                  >
                    <p style={{ margin: "0px" }}>-</p>
                  </div>
                </Tooltip>
              </div>
            ))}
            {newTags.map((tag, key) => (
              <Tag key={key} title={tag.title} color={tag.color} />
            ))}
          </GridItem>
        </GridContainer>
      </CardBody>
      <CardFooter>
        <GridContainer extraClass={classes.buttons}>
          <GridItem>
            <Button
              withProgress
              loading={loading}
              disabled={loading}
              color="success"
              round
              onClick={() => saveAction()}
            >
              Atualizar Ação
            </Button>
          </GridItem>
          <GridItem>
            <Button color="danger" round onClick={() => descartForm()}>
              Descartar Alterações
            </Button>
          </GridItem>
        </GridContainer>
      </CardFooter>

      <Dialog
        classes={{
          root: modaClasses.center,
          paper: modaClasses.modal,
        }}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent
          id="modal-slide-description"
          className={modaClasses.modalBody}
        >
          <NewTag
            setModal={setModal}
            onCreate={(tag) => setNewTags([...newTags, tag])}
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
}

export default function EditAction() {
  return (
    <PermissionsGate>
      <Edit />
    </PermissionsGate>
  );
}
