import moment from "moment";
import api from "services/api";

import { loadAdmin, loadUser, setUser } from "./user";

export const SET_SIGNED = "AUTH_SET_SIGNED";
export const SET_ERROR = "AUTH_SET_ERROR";
export const SET_LOADING = "AUTH_SET_LOADING";
export const TOGGLE_MODAL = "AUTH_TOGGLE_MODAL";

export function toggleModal() {
  return {
    type: TOGGLE_MODAL,
  };
}

export function isLoged() {
  return (dispatch) => {
    const token = localStorage.getItem("@token");
    const userId = localStorage.getItem("@userId");

    dispatch(setSigned(!!token && !!userId));

    if (!!token && !!userId) {
      if (!localStorage.getItem("@isAdmin")) {
        dispatch(loadUser());
      } else {
        dispatch(loadAdmin());
      }
    }
  };
}

export function logout() {
  return (dispatch) => {
    localStorage.removeItem("@token");
    localStorage.removeItem("@userId");
    localStorage.removeItem("@isAdmin");
    localStorage.removeItem("@adminType");
    dispatch(setUser({}));
    dispatch(setSigned(false));
  };
}

export function setSigned(signed) {
  return {
    type: SET_SIGNED,
    signed,
  };
}

export function setError(error) {
  return {
    type: SET_ERROR,
    error,
  };
}

export function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    isLoading,
  };
}

export function login({ username, password }) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const credentials = btoa(username + ":" + password);
      const basicAuth = "Basic " + credentials;

      const loginResult = await api.get("/users/login", {
        headers: { Authorization: basicAuth },
      });

      const { token, user } = loginResult.data;
      console.log(user);

      if (token && user.id) {
        localStorage.setItem("@token", token);
        localStorage.setItem("@userId", user.id);
        dispatch(setSigned(true));
      }

      if (user) {
        dispatch(
          setUser({
            id: user.id,
            name: user.nome,
            email: user.email,
            personType: user.tipo,
            personIdentifier: user.cpf || user.cnpj,
            planId: user.plano,
            isBlocked: user.bloqueado,
            createdAt: moment(user.createdAt),
            updatedAt: moment(user.updatedAt),
          })
        );
      }
    } catch (error) {
      console.log(error);
      console.log(error.response.data);

      dispatch(setError(error.response.data.error));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function adminLogin({ username, password }) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const credentials = btoa(username + ":" + password);
      const basicAuth = "Basic " + credentials;

      const loginResult = await api.get("/admins/login", {
        headers: { Authorization: basicAuth },
      });

      const { token, user } = loginResult.data;
      console.log(user);

      if (token && user.id) {
        localStorage.setItem("@token", token);
        localStorage.setItem("@userId", user.id);
        localStorage.setItem("@isAdmin", true);
        localStorage.setItem("@adminType", user.tipo);
        dispatch(setSigned(true));
      }

      if (user) {
        dispatch(
          setUser({
            id: user.id,
            name: user.nome,
            email: user.email,
            adminType: user.tipo,
            createdAt: moment(user.createdAt),
            updatedAt: moment(user.updatedAt),
          })
        );
      }
    } catch (error) {
      console.log(error);
      console.log(error.response.data);

      dispatch(setError(error.response.data.error));
    } finally {
      dispatch(setLoading(false));
    }
  };
}
