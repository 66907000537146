import React from "react";
import PropTypes from "prop-types";

import { tagsColors } from "assets/jss/material-dashboard-react.js";
export default function Tag({ title, color }) {
  function tagColor() {
    switch (color) {
      case "red":
        return tagsColors[0];
      case "blue":
        return tagsColors[1];
      case "green":
        return tagsColors[2];
      case "yellow":
        return tagsColors[3];
      case "purple":
        return tagsColors[4];
      case "orange":
        return tagsColors[5];
      default:
        return tagsColors[0];
    }
  }

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          backgroundColor: tagColor(),
          color: "white",
          fontWeight: "bolder",
          fontSize: "13px",
          padding: "0px 12px",
          borderRadius: "10px",
          width: "max-content",
          height: "max-content",
          margin: "2px 6px",
        }}
      >
        <p style={{ margin: "0px" }}>{title}</p>
      </div>
    </div>
  );
}

Tag.propTypes = {
  title: PropTypes.string,
  color: PropTypes.oneOf([
    "red",
    "green",
    "blue",
    "yellow",
    "purple",
    "orange",
  ]),
};
